<template>
  <div id="wrap">
    <Header ref="Header" />
    <router-view v-show="is_show" :key="$route.fullPath" />
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// @ is an alias to /src
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';

export default {
  el: '#app',
  name: 'app',
  props: {},
  data() {
    return {
      // 앱 스토어 > 관리 하기 > 첫 접속 시 만료일 체크 후 화면 노출 (  /layout/Header.vue 파일에서 변경 )
      is_show: false,
      to_name: null,
      loading_page_list: [
        'MallPc',
        'MallMobile',
        'StatsRecommend',
        'StatsUser',
        'StatsChoice',
        'Addition',
        'Payment',
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.to_name = to.name;
      if (this.loading_page_list.indexOf(this.to_name) > -1) {
        this.is_show = false;
        this.$refs.Header.headerLoadingShow();
      }
      this.$refs.Header.headerCall();
    },
    is_show(v) {
      if (v == true) {
        if (this.loading_page_list.indexOf(this.to_name) > -1) {
          this.$refs.Header.headerLoadingHide();
        }
      }
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>
