<template>
  <MallMobile />
</template>

<script>
// @ is an alias to /src
import MallMobile from '@/components/mall/MallMobile.vue';

export default {
  components: {
    MallMobile,
  },
};
</script>
