import { render } from '@vue/runtime-dom';

export default {
  name: 'common',
  data: () => ({
    loader: null,
    all_checked: false,
  }),
  mounted() {
    //console.log('mixin!');
  },
  methods: {
    showLoading() {
      this.loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
    },
    hideLoading() {
      if (this.loader !== null) {
        this.loader.hide();
      }
    },
    numberFormat(price) {
      if (typeof price !== 'number') {
        return price;
      }

      // 달러로 인한 추가
      let _price = String(price);
      let _price_point = '';
      if (_price.indexOf('.') > -1) {
        // 소수점 기준으로 자른다
        let _price_array = _price.split('.');
        price = Number(_price_array[0]);
        _price_point = _price_array[1];
      }
      let result = price
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
      if (_price_point) {
        result = result + '.' + _price_point;
      }

      return result;
    },
    isLink(url) {
      const regex =
        // eslint-disable-next-line no-useless-escape
        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      return regex.test(url);
    },
    showScrollbar() {
      document.documentElement.style.overflow = 'auto';
    },
    hideScrollbar() {
      document.documentElement.style.overflow = 'hidden';
    },
    uploadImage: async function (file) {
      if (file === undefined || file.name === undefined) {
        return '';
      }

      let is_image = true;
      let _imgs = ['gif', 'jpg', 'jpeg', 'png'];
      let _file_name_array = file.name.split('.');
      let _ext = _file_name_array[_file_name_array.length - 1].toLowerCase();

      const file_maxinum_size = 5 * (1000 * 1000);

      if (_imgs.indexOf(_ext) < 0) {
        alert('이미지를 확인해주세요.');
        is_image = false;
      } else if (file.size >= file_maxinum_size) {
        alert('첨부 가능한 최대 용량은 5MB 입니다.');
        is_image = false;
      } else if (!file || !file.type.match('image.*')) {
        alert('이미지를 확인해주세요.');
        is_image = false;
      }

      if (is_image !== true) {
        return '';
      }

      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort();
          reject(new DOMException('Problem parsing input file.'));
        };

        reader.onload = () => {
          if (is_image === true) {
            resolve(reader.result);
          } else {
            resolve('');
          }
        };

        reader.readAsDataURL(file);
      });
    },
    getBackgroundColor: function (color = '') {
      if (color === undefined || color === '' || color === '#') {
        return 'display: inline-block; border:1px solid #ccc; background-color: #ffffff';
      }

      return (
        'display: inline-block; border:1px solid #ccc; background-color: ' +
        color
      );
    },
    replaceHtml: function (str) {
      if (str === '' || str === undefined) {
        return '';
      }

      return str.replace(/(<([^>]+)>)/gi, '');
    },
  },
};
