<template>
  <header id="header" class="notranslate">
    <!--button type="button" class="btn_menu"><span class="material-icons md-white">menu</span></button-->
    <button v-on:click="toggleMenu" class="btn_menu">
      <span class="material-icons md-white">menu</span>
    </button>
    <h1>맞춤 질문 추천 서비스</h1>

    <!-- layer : 샵추가 -->
    <div class="layer_group">
      <!-- a href="#layer_addshop" class="btn btn_small btn_primary btn_shop open_layer">+샵 추가</a -->
      <button
        @click="isOpenShopLayer = !isOpenShopLayer"
        class="btn btn_small btn_primary btn_shop open_layer"
      >
        <span>+샵 추가</span>
      </button>
      <div v-if="isOpenShopLayer" id="layer_addshop" class="layer_popup">
        <h2>멀티샵 추가 사용 설정</h2>
        <p>멀티샵을 선택하여 사용이 가능합니다.</p>
        <ul class="check">
          <li v-for="(item, key) of this.mall_list" :key="key">
            <input
              type="checkbox"
              name=""
              v-model="item.checked"
              :id="`shop_id_${key}`"
              :disabled="item.disabled"
            /><label :for="`shop_id_${key}`">{{ item.shop_name }} </label>
          </li>
        </ul>
        <div class="btns">
          <ul>
            <li>
              <button
                @click="isOpenShopLayer = false"
                class="btn btn_small btn_secondary"
              >
                <span>닫기</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn btn_small btn_primary"
                @click="setSurveyMall"
              >
                <span>사용하기</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- //layer : 샵추가 -->

    <div class="util">
      <router-link
        v-show="is_expire_days != null"
        :to="{ path: '/survey/payment' }"
      >
        <a v-if="is_expire_days === true" href="javascript:void(0);" class="uid"
          >서비스 사용중 (D-day {{ expire.days }})</a
        >
        <a href="javascript:;" class="uid" v-else>사용 기간 만료되었습니다.</a>
      </router-link>

      <ul class="btns">
        <li>
          <a
            href="https://shoplustore.notion.site/shoplustore/0b54d2d8c0644bc381b1b14af524b16e"
            class="btn btn_small btn_guide"
            target="_blank"
            >사용 가이드</a
          >
        </li>
        <li>
          <a
            href="https://www.google.com/intl/ko/chrome/"
            class="btn btn_small btn_chrome"
            target="_blank"
            @mouseover="chromeTip(1)"
            @mouseleave="chromeTip(0)"
            >크롬 다운로드</a
          >
          <div ref="chrome_tip" class="tip">
            맞춤 질문 추천 서비스는<br />
            안전한 크롬 브라우저에 최적화되어 있습니다.<br />
            크롬 브라우저로 사용하시길 권장합니다.
          </div>
        </li>
      </ul>

      <!--a href="#layer_apps" class="btn_apps open_toggle"><span class="material-icons md-white">apps</span></a-->
      <button
        class="btn_apps open_toggle"
        @click="isOpenAppLayer = !isOpenAppLayer"
      >
        <span class="material-icons md-white">apps</span>
      </button>
      <!-- layer : 앱 안내 -->
      <div v-if="isOpenAppLayer" id="layer_apps" class="layer_popup layer_apps">
        <ul>
          <!--
          <li class="point">
            <a href="https://store.cafe24.com/kr/apps/8510" target="_blank"
              ><strong>포인트 판매 서비스</strong>포인트를 판매하고 적립금을
              자동 지급하는 서비스.<br />회원들은 적립금 혜택으로 만족하고 남은
              적립금<br />재방문/재구매를 이끌어줍니다.<span
                class="material-icons"
                >chevron_right</span
              ></a
            >
          </li>
          -->
          <li class="member">
            <a href="https://store.cafe24.com/kr/apps/6711" target="_blank"
              ><strong>유료 멤버십 서비스</strong>쇼핑몰 회원들에게 제공되는
              차별화된 서비스.<br />쇼핑몰 구독시대에 필요한 특별한 서비스로<br />고객
              이탈을 막고 충성고객을 확보하세요.<span class="material-icons"
                >chevron_right</span
              ></a
            >
          </li>
          <li class="auth">
            <a href="https://store.cafe24.com/kr/apps/4238" target="_blank"
              ><strong>정품 인증 서비스 </strong>회원가입 및 브랜드 가치를
              높여주는 서비스.<br />정품 인증 회원에게 다양한 프로모션을
              제공하고<br />충성 고객으로 만들어보세요!<span
                class="material-icons"
                >chevron_right</span
              ></a
            >
          </li>
        </ul>
      </div>

      <!-- //layer : 앱 안내 -->
    </div>
    <nav :class="is_toggle_menu === false ? 'menu_close' : ''" id="nav">
      <select
        name=""
        @change="selectMall($event.target.value)"
        v-model="select_shop_no"
        ref="selectMall"
      >
        <option
          v-for="(item, index) in this.survey_mall_list"
          :value="item.shop_no"
          :selected="item.selected"
          :key="index"
        >
          {{ item.mall_shop.shop_name }}
        </option>
      </select>
      <ul>
        <li v-for="(router_item, index) in this.router_list" :key="index">
          <router-link :to="router_item.to"
            ><span
              class="material-icons md-dark"
              :class="[
                this.router_name.indexOf(router_item.to) !== -1 ? 'nav_on' : '',
              ]"
              :style="router_item.style"
              >{{ router_item.icon }}</span
            ><i
              :style="router_item.style"
              :class="[
                this.router_name.indexOf(router_item.to) !== -1 ? 'nav_on' : '',
              ]"
              >{{ router_item.title }}</i
            ></router-link
          >
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';
export default {
  mixins: [common],
  data() {
    return {
      isOpenAppLayer: false,
      isOpenShopLayer: false,
      is_toggle_menu: true,
      shop_name: '',
      is_use_disabled: true,
      router_name: '',
      router_list: [],
      router_title_style: '',
      select_shop_no: null,
      is_expire_days: null,
    };
  },
  created: async function () {
    // App.vue에서 headerCall 호출한다
  },
  computed: {
    ...mapState('common', [
      'shop_no',
      'store',
      'mall_list',
      'survey_mall_list',
    ]),
    ...mapState('payment', ['expire']),
    ...mapState('qna_config', ['qna_config']),
  },
  watch: {
    select_shop_no(new_value, old_value) {
      if (old_value && new_value != old_value) {
        if (this.survey_mall_list.length == 1) {
          this.selectMall(new_value);
        }
      }
    },
  },

  methods: {
    headerLoadingShow: function () {
      // this.showLoading();
    },
    headerLoadingHide: function () {
      setTimeout(() => {
        // this.hideLoading();
      }, 300);
    },
    headerCall: async function () {
      // token 설정
      this.setToken();

      if (!sessionStorage.getItem('access_token')) {
        alert('비정상적인 접근입니다.');
        location.replace('https://eclogin.cafe24.com/Shop/');
      }

      // 처음 접속 했을때 또는 새로고침 했을때 실행
      if (this.$route.query.access_token || this.router_list.length == 0) {
        // mall_id 설정
        this.setMallId();

        // 쇼핑몰 이름 갱신
        await this.getStoreInfo();

        // mall list
        await this.getMallList();

        // 추가된 mall list
        await this.getSurveyMallList();

        //만료일 조회
        await this.getExpire();

        // 이용 가능 여부 체크
        this.isUseService();

        this.setRouterList();

        // survey mall이 없다면..
        // 디폴트값을 생성.
        if (
          this.survey_mall_list === null ||
          this.survey_mall_list === undefined ||
          this.survey_mall_list.length === 0
        ) {
          this.mall_list[0].checked = true;

          const mall_list = [this.mall_list[0]];
          this.setSurveyMallList(mall_list);
        }
      }

      await this.init();
      let _is_show = typeof this.$parent.is_show;
      if (_is_show != 'undefined') {
        this.$parent.is_show = true;
      }

      if (this.$route.query.access_token) {
        // scriptTag가 없으면 생성 용도
        await this.$store.dispatch('qna_config/getConfig');
        this.$store.commit(
          'common/setSurveyConfigId',
          this.$store.state.qna_config.qna_config.id,
        );
      }

      this.router_name = this.$route.path;
    },
    init: async function () {
      this.select_shop_no = sessionStorage.getItem('survey_shop_no');

      if (
        this.survey_mall_list === null ||
        this.survey_mall_list === undefined ||
        this.survey_mall_list.length === 0
      ) {
        return false;
      }

      let is_select = false;
      for (const [j, survey_mall] of Object.entries(this.survey_mall_list)) {
        this.survey_mall_list[j].selected = false;

        if (survey_mall.shop_no === sessionStorage.getItem('survey_shop_no')) {
          this.survey_mall_list[j].selected = true;
          this.shop_name = survey_mall.mall_shop.shop_name;
          is_select = true;
        }
      }

      if (
        is_select === false &&
        this.survey_mall_list &&
        this.survey_mall_list.length > 0
      ) {
        this.survey_mall_list[0].selected = true;
        this.shop_name = this.survey_mall_list[0].mall_shop.shop_name;
        this.select_shop_no = this.survey_mall_list[0].shop_no;
        this.$store.commit(
          'common/setShopNo',
          this.survey_mall_list[0].shop_no,
        );
      }

      for (const [i, mall] of Object.entries(this.mall_list)) {
        this.mall_list[i].checked = false;

        if (
          this.survey_mall_list.findIndex(
            obj => obj.shop_no === mall.shop_no,
          ) !== -1
        ) {
          this.mall_list[i].checked = true;
        }
      }
    },
    setToken: function () {
      if (this.$route.query.access_token) {
        const set_token_params = {
          access_token: this.$route.query.access_token,
          refresh_token: null,
        };
        this.$store.commit('common/setToken', set_token_params);
      }
    },
    setMallId: function () {
      if (this.$route.query.mall_id) {
        this.$store.commit('common/setMallId', this.$route.query.mall_id);
      }
    },
    getStoreInfo: async function () {
      await this.$store.dispatch('common/getStoreInfo');
    },
    getMallList: async function () {
      await this.$store.dispatch('common/getMallList');
    },
    getSurveyMallList: async function () {
      await this.$store.dispatch('common/getSurveyMallList');
    },
    setSurveyMall: async function () {
      if (this.mall_list === null || this.mall_list === undefined) return;

      let params = [];
      for (const [i, mall] of Object.entries(this.mall_list)) {
        if (mall.checked === true) {
          params.push({
            mall_id: mall.mall_id,
            shop_no: mall.shop_no,
          });
        }
      }

      if (Array.isArray(params) === false || params.length === 0) {
        alert('추가 하실 멀티몰을 선택해주세요.');
        return false;
      }

      const result = await this.setSurveyMallList(this.mall_list);
      if (result === true) {
        this.isOpenShopLayer = false;
        alert(
          '선택하신 멀티샵 사용이 설정되었습니다. \n좌측메뉴에서 멀티샵을 선택하여 각각 설정이 가능합니다.',
        );
        this.$router.push('/mall/pc');
        return true;
      } else {
        return false;
      }
    },
    setSurveyMallList: async function (mall_list) {
      if (mall_list === null || mall_list === undefined) return;

      const survey_mall_count = this.survey_mall_list.length;
      await this.$store.dispatch('common/setSurveyMallList', mall_list);
      const survey_mall_count2 = this.survey_mall_list.length;

      this.init();
      return true;
    },
    selectMall: async function (shop_no) {
      if (shop_no === null || shop_no === undefined) return;
      this.$store.commit('common/setShopNo', shop_no);
      for (const mall of this.survey_mall_list) {
        if (Number(mall.shop_no) == Number(shop_no)) {
          this.shop_name = mall.mall_shop.shop_name;
        }
      }

      await this.$store.dispatch('qna_config/getConfig');

      if (this.qna_config === null || this.qna_config === undefined) {
        this.$store.commit('common/setSurveyConfigId', 0);
      } else {
        this.$store.commit('common/setSurveyConfigId', this.qna_config.id);
      }

      this.$router.push({ name: 'MallPc', query: { tpye: Date.now() } });
    },
    getExpire: async function () {
      await this.$store.dispatch('payment/getExpire');
      if (this.expire.days > 0) {
        this.is_expire_days = true;
      } else {
        this.is_expire_days = false;
      }
    },
    toggleMenu: function () {
      $('#wrap').toggleClass('menu_close');
    },
    chromeTip: function (type) {
      if (type === 1) {
        this.$refs.chrome_tip.style.display = 'block';
      } else {
        this.$refs.chrome_tip.style.display = 'none';
      }
    },
    isUseService: function () {
      if (this.expire.is_expired) {
        this.router_title_style = 'color: #888;';

        if (this.$route.name != 'Payment' && this.$route.name != 'Addition') {
          alert('서비스 이용기간이 만료되었습니다.\n결제 후 이용해주세요.');
          this.$router.push({
            path: '/survey/payment',
          });
          this.loader.hide();
        }

        return false;
      } else {
        this.router_title_style = '';
        return true;
      }
    },
    setRouterList: function () {
      if (this.expire.is_expired) {
        this.router_list = [
          {
            to: '/survey/payment',
            title: '쇼핑몰 설정',
            icon: 'dvr',
            style: this.router_title_style,
          },
          {
            to: '/survey/payment',
            title: '맞춤 질문 설정',
            icon: 'manage_accounts',
            style: this.router_title_style,
          },
          {
            to: '/survey/payment',
            title: '통계 관리',
            icon: 'align_vertical_bottom',
            style: this.router_title_style,
          },
          {
            to: '/addition',
            title: '부가서비스',
            icon: 'post_add',
            style: '',
          },
          {
            to: '/survey/payment',
            title: '결제 관리',
            icon: 'credit_card',
            style: '',
          },
        ];
      } else {
        this.router_list = [
          {
            to: '/mall',
            title: '쇼핑몰 설정',
            icon: 'dvr',
            style: this.router_title_style,
          },
          {
            to: '/qna',
            title: '맞춤 질문 설정',
            icon: 'manage_accounts',
            style: this.router_title_style,
          },
          {
            to: '/stats',
            title: '통계 관리',
            icon: 'align_vertical_bottom',
            style: this.router_title_style,
          },
          {
            to: '/addition',
            title: '부가서비스',
            icon: 'post_add',
            style: '',
          },
          {
            to: '/survey/payment',
            title: '결제 관리',
            icon: 'credit_card',
            style: '',
          },
        ];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: #2980b9;
  z-index: 11;
}
#header:after {
  content: '';
  display: block;
  clear: both;
}
#header h1 {
  float: left;
  margin-right: 20px;
  color: #fff;
  font-size: 17px;
  vertical-align: middle;
  line-height: 50px;
}
#header .btn_menu {
  float: left;
  width: 50px;
  height: 50px;
  background-color: transparent;
}
#header .layer_group {
  padding-top: 12px;
}
#header .util {
  float: right;
}
#header .util .uid {
  float: left;
  margin-top: 14px;
  color: #fff;
  font-size: 16px;
  vertical-align: middle;
}
#header .util .uid:hover {
  text-decoration: underline;
}
#header .util .btns {
  float: left;
  margin-left: 15px;
  padding-top: 12px;
}
#header .util .btns li {
  float: left;
}
#header .util .btns li:first-child {
  margin-right: 10px;
}
#header .util .btns .btn_guide {
  background-color: #fff;
}
#header .util .btns .btn_chrome {
  background-color: #f1c40f;
}
#header .util .tip {
  display: none;
  position: absolute;
  top: 60px;
  right: 13px;
  padding: 14px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
}
#header .btn_apps {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 47px;
}
#header .layer_apps {
  padding: 0;
}
#header .layer_apps ul {
  padding: 10px 0;
}
#header .layer_apps li a {
  display: block;
  position: relative;
  padding: 10px 0 10px 105px;
  background-position: 15px center;
  background-repeat: no-repeat;
  color: #888;
  line-height: 1.3em;
}
#header .layer_apps li a strong {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #222;
}
#header .layer_apps li a:hover {
  background-color: #f5f5f5;
  text-decoration: underline;
}
#header .layer_apps li.auth a {
  background-image: url('https://cert-product.shoplus.store/authservice/etc/service_images/auth-code-service-icon.jpg');
}
#header .layer_apps li.member a {
  background-image: url('https://cert-product.shoplus.store/authservice/etc/service_images/membership.jpg');
}
#header .layer_apps li.point a {
  background-image: url('https://cert-product.shoplus.store/authservice/etc/service_images/point_sales_service.jpg');
}
#header .layer_apps li .material-icons {
  position: absolute;
  top: 7px;
  right: 10px;
}

#nav {
  position: fixed;
  top: 50px;
  width: 250px;
  padding: 20px 15px;
  height: 100%;
  max-height: calc(100% - 50px);
  border-right: 1px #ccc solid;
  transform: translateX(0%);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

#nav.off {
  position: fixed;
  top: 50px;
  width: 0px;
  padding: 20px 15px;
  height: 100%;
  max-height: calc(100% - 50px);
  border-right: 1px #ccc solid;
  transform: translateX(0%);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.menu_close #nav {
  transform: translateX(-100%);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.menu_close {
  transform: translateX(-100%) !important;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
}
#nav select {
  width: 100%;
}
#nav ul {
  margin-top: 20px;
}
#nav ul li {
  margin-top: 15px;
}
#nav ul li:first-child {
  margin-top: 0;
}
#nav ul li span {
  vertical-align: middle;
}
#nav ul li i {
  display: inline-block;
  margin: 0 0 0 10px;
  font-size: 16px;
  font-style: normal;
  vertical-align: middle;
}

#layer_addshop {
  top: 60px;
  left: 0;
  width: 300px;
}
#layer_grade {
  top: 35px;
  left: 0;
  width: 300px;
}
#layer_apps {
  top: 60px;
  right: 10px;
  width: 450px;
}

.nav_on {
  color: #2980b9;
}
</style>
