import { createApp } from 'vue';
import App from './App.vue';
import Router from './router';
import Store from './store';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueFinalModal from 'vue-final-modal';

const app = createApp(App);
app.use(Router);
app.use(Store);
app.use(VueLoading);
app.use(VueFinalModal);

app.config.globalProperties.$emailValidation = () => {
  let regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  if (regExp.test(data)) {
    //통과
    return true;
  } else {
    //올바르지 않음
    return false;
  }
};

app.mount('#app');
