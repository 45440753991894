<template>
  <!-- container -->
  <section v-show="is_show" id="container">
    <div class="content">
      <h2 class="title">통계 관리</h2>
      <p class="dsc">
        사용자가 선택한 내역에 대한 통계 내역을 확인할 수 있습니다.<br />해당
        내용을 바탕으로 쇼핑몰 운영에 참고하여 사용하시길 권장합니다.
      </p>

      <!-- tab -->
      <ul class="tab">
        <li><router-link to="/stats/user">사용자 통계</router-link></li>
        <li><router-link to="/stats/recommend">추천 상품 통계</router-link></li>
        <li class="on">
          <router-link to="/stats/choice">사용자 선택 내역</router-link>
        </li>
      </ul>
      <!-- //tab -->

      <div class="conbox">
        <h3 class="tit">사용자 선택 내역 - 엑셀 다운로드</h3>
        <p>
          쇼핑몰에서 맞춤 질문 추천 서비스를 사용한 고객들의 전체 선택 내역을
          확인합니다.<br />
          월별 내역을 선택하여 다운로드 받으실 수 있습니다.<br />
          데이터가 많은 경우 다소 시간이 걸릴 수 있으니 참고해주세요.
        </p>

        <div class="mt20">
          <select v-model="year" class="mr10">
            <template v-for="(_year, i) in this.year_list" :key="i">
              <option :value="_year">{{ _year }}년</option>
            </template>
          </select>
          <select v-model="month" class="mr10">
            <option value="0" disabled>선택</option>
            <option
              v-for="(month, key) in months"
              :key="key"
              :value="month < 10 ? `0${month}` : month"
            >
              {{ month }}월
            </option>
          </select>

          <table style="display: none" id="stats_result">
            <tr>
              <td>번호</td>
              <td>참여일시</td>
              <template v-for="(survey, i) in this.data.header" :key="i">
                <td>{{ survey.parent_subject }}</td>
              </template>
              <template v-for="i in this.data.goods_length" :key="i">
                <td>추천상품 {{ i }}</td>
              </template>
            </tr>
            <tr v-for="(stat, j) in this.stats" :key="j">
              <td>{{ this.stats.length - j }}</td>
              <td>{{ stat.survey_date }}</td>
              <template v-for="(survey, i) in this.data.body[j]" :key="i">
                <td>
                  {{ survey.id !== undefined ? survey.subject : '' }}
                </td>
              </template>
              <template v-for="(goods, i) in this.data.goods[j]" :key="i">
                <td>
                  {{ goods.product_no !== undefined ? goods.product_name : '' }}
                </td>
              </template>
            </tr>
          </table>

          <button
            v-if="this.is_loaiding == false"
            type="button"
            class="btn btn_large btn_green"
            @click="exportExcel"
          >
            <span>엑셀 다운로드</span>
          </button>
          <button v-else type="button" class="btn btn_large btn_green">
            잠시 기다려주세요
            <img :src="loading_image" class="loadingImage" />
          </button>
        </div>
      </div>
    </div>
    <!-- //content -->
  </section>
  <!-- //container -->
</template>
<script>
import Dayjs from 'dayjs';
import Xlsx from 'xlsx';
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';
import loading_image from '@/assets/loading.gif';

export default {
  mixins: [common],
  components: {},
  data: () => ({
    is_show: false,
    mall_id: '',
    year: 0,
    year_list: [],
    month: 0,
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    today: 0,
    data: {
      header: [],
      body: [],
      goods: [],
      goods_length: 0,
    },
    loading_image,
    is_loaiding: false,
  }),
  computed: {
    ...mapState('stats_choice', ['stats']),
    ...mapState('qna_setting', ['setting_list']),
  },
  created: async function () {
    this.init();
  },
  mounted() {},
  methods: {
    init: async function () {
      this.mall_id = this.$store.state.common.mall_id;
      this.survey_config_id = sessionStorage.getItem('survey_survey_config_id');
      this.is_show = true;
      this.today = Dayjs().format('YYYYMMDD');
      this.year = Number(Dayjs().format('YYYY'));
      this.month = Number(Dayjs().month() + 1);
      if (this.month < 10) {
        this.month = '0' + this.month;
      }

      for (let i = 2021; i < this.year + 5; i++) {
        this.year_list.push(i);
      }

      await this.$store.dispatch('qna_setting/getSettingList');
      // 현재 질문 순서가 맨 앞열에 나오게 작업
      for (const setting of this.setting_list) {
        await this.setDefaultExcelHeader(setting.children);
      }
    },
    setDefaultExcelHeader: async function (params) {
      if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
          if (params[i].hierarchy_type == 'question') {
            this.data.header.push({
              parent_id: params[i].hierarchy_id,
              parent_subject: params[i].subject,
            });
          }
          if (params[i].children.length > 0) {
            await this.setDefaultExcelHeader(params[i].children);
          } else {
            // return;
          }
        }
      }
    },
    setData: async function () {
      const sdate = Dayjs(this.year + '-' + this.month + '-01').format(
        'YYYY-MM-DD',
      );
      const edate = Dayjs(sdate)
        .add(1, 'month')
        .add(-1, 'day')
        .format('YYYY-MM-DD');

      const params = {
        from: sdate,
        to: edate,
      };

      await this.$store.dispatch('stats_choice/getStatistics', params);
      for (const [i, item] of Object.entries(this.stats)) {
        for (const [j, hierarchy] of Object.entries(item.hierarchy_list)) {
          if (
            this.data.header.findIndex(
              obj => obj.parent_id === hierarchy.parent_id,
            ) === -1
          ) {
            this.data.header.push({
              parent_id: hierarchy.parent_id,
              parent_subject: hierarchy.parent_subject,
            });
          }
        }
      }

      // 2차원 선언.
      this.data.body = Array.from(Array(this.stats.length), () => Array());
      this.data.goods = Array.from(Array(this.stats.length), () => Array());

      for (const [i, item] of Object.entries(this.stats)) {
        const hierarchy_list = item.hierarchy_list;
        const survey_result = item.survey_result;
        const survey_result_goods = item.survey_result_goods;

        for (const [j, header] of Object.entries(this.data.header)) {
          const index = hierarchy_list.findIndex(
            obj => obj.parent_id === header.parent_id,
          );

          this.data.body[i][j] = {};

          if (index !== -1) {
            this.data.body[i][j] = hierarchy_list[index];

            if (typeof survey_result === 'object') {
              for (const [k, result] of Object.entries(survey_result)) {
                const json = JSON.parse(result);

                if (json.parent_id === hierarchy_list[index].parent_id) {
                  if (json.hierarchy_id === hierarchy_list[index].id) {
                    if (json.input_text !== '') {
                      this.data.body[i][j].subject = json.input_text;
                    }
                    if (json.input_file !== '') {
                      this.data.body[i][j].subject = json.input_file;
                    }
                    continue;
                  }

                  if (json.input_text === '') {
                    const hierarchy_index = hierarchy_list.findIndex(
                      obj => obj.id === json.hierarchy_id,
                    );

                    if (hierarchy_index !== -1) {
                      json.input_text = hierarchy_list[hierarchy_index].subject;
                    }
                  }

                  if (this.data.body[i][j].subject === '') {
                    this.data.body[i][j].subject = json.input_text;
                  } else {
                    this.data.body[i][j].subject += ', ' + json.input_text;
                  }
                }
                /*if (
                  json.hierarchy_id === hierarchy_list[index].id &&
                  json.input_text !== ''
                ) {
                  this.data.body[i][j].subject = json.input_text;
                }*/
              }
              // for result object
            }
          }
        }
        // for header
        /*
        if (survey_result_goods !== '') {
          const json = JSON.parse(survey_result_goods);
          for (const [j, goods] of Object.entries(json)) {
            if (goods.type === 'recommend_goods') {
              for (const [k, goods_info] of Object.entries(
                goods.info.recommend_goods[0].list,
              )) {
                const goods_index = this.data.goods[i].findIndex(
                  obj => obj.product_no === goods_info.product_no,
                );
                if (goods_index === -1) {
                  this.data.goods[i].push({
                    product_no: goods_info.product_no,
                    product_name: goods_info.product_name,
                  });
                }

                if (this.data.goods_length < this.data.goods[i].length) {
                  this.data.goods_length = this.data.goods[i].length;
                }
              }
            }
          }
        }
        */

        if (survey_result_goods !== '') {
          const json = JSON.parse(survey_result_goods);
          for (const [j, goods] of Object.entries(json)) {
            if (goods.type === 'recommend_goods') {
              for (const [l, recommend] of Object.entries(
                goods.info.recommend_goods,
              )) {
                for (const [_j, param] of Object.entries(recommend.list)) {
                  this.data.goods[i].push({
                    product_no: param.product_no,
                    product_name: param.product_name,
                  });

                  if (this.data.goods_length < this.data.goods[i].length) {
                    this.data.goods_length = this.data.goods[i].length;
                  }
                }
              }
            }
          }
        }
      }
    },
    exportExcel: async function () {
      if (this.month === 0) {
        alert('다운로드 받으실 기간(월)을 선택해주세요.');
        return false;
      }
      this.is_loaiding = true;
      await this.setData();

      if (this.data.body.length === 0) {
        alert('해당 기간에는 사용자 결과 데이터가 없습니다.');
        this.is_loaiding = false;
        return false;
      }

      const workBook = Xlsx.utils.book_new();
      //const workSheet = Xlsx.utils.json_to_sheet(this.stats);
      const workSheet = Xlsx.utils.table_to_sheet(
        document.getElementById('stats_result'),
        { dateNF: 'YYYY-MM-DD HH:mm', cellDates: true, raw: true },
      );

      Xlsx.utils.book_append_sheet(workBook, workSheet, 'worksheet');
      Xlsx.writeFile(workBook, `S${this.today}_${this.mall_id}.xlsx`);
      this.is_loaiding = false;
    },
  },
};
</script>

<style scoped>
table {
}

table td {
  border: 1px solid #ccc;
}

.loadingImage {
  width: 20px;
  margin-left: 10px;
}
</style>
