import M from '@/libs/mapping.js';

const actions = {
  async getConfig({ commit }, params = []) {
    try {
      const result = await M.api(M.entry('/get/survey/config'), params);
      if (result) {
        commit('setDefaultData', result);
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async postData(commit, params) {
    try {
      const result = await M.api(M.entry('/post/survey/config'), params);
      if (result) return true;
      else return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async postImage(commit, params) {
    const result = await M.api(M.entry('/post/survey/upload/image'), params);
    return result.image;
  },
};

export default actions;
