import common from './common';
import qna_config from './qna/config';
import qna_setting from './qna/setting';
import qna_product from './qna/product';
import payment from './payment';
import stats_recommend from './stats/recommend';
import stats_user from './stats/user';
import stats_choice from './stats/choice';

export default {
  common,
  qna_config,
  qna_setting,
  qna_product,
  payment,
  stats_recommend,
  stats_user,
  stats_choice,
};
