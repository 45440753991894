import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import mutations from './mutations';
import actions from './actions';
import entries from '@/datas/entries';
import modules from './modules';

export default createStore({
  state: {
    api_url: 'https://survey-api.cafe24.shoplus.store',
    // api_url: 'http://localhost:13800',
    app_code: 'survey',
    entries,
  },
  mutations,
  actions,
  modules: {
    common: modules.common,
    qna_config: modules.qna_config,
    qna_setting: modules.qna_setting,
    qna_product: modules.qna_product,
    payment: modules.payment,
    stats_recommend: modules.stats_recommend,
    stats_user: modules.stats_user,
    stats_choice: modules.stats_choice,
  },
  plugins: [
    createPersistedState({
      paths: ['common', 'payment'],
    }),
  ],
});
