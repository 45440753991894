<template>
  <div>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 앱 스토어 > 관리 하기 > 첫 접속 시 만료일 체크 후 화면 노출 (  /layout/Header.vue 파일에서 변경 )
      is_show: false,
    };
  },
  components: {},
};
</script>
