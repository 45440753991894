<template>
  <StatsChoice />
</template>

<script>
// @ is an alias to /src
import StatsChoice from '@/components/stats/StatsChoice.vue';

export default {
  components: {
    StatsChoice,
  },
};
</script>
