<template>
  <div>
    <Addition />
  </div>
</template>

<script>
import Addition from '@/components/addition/Addition.vue';

export default {
  data() {
    return {};
  },
  components: {
    Addition,
  },
};
</script>
