<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <section class="layer_manager_info_dialog modalin">
      <h2 class="mb20 manager_info_title text-start">
        <p>쇼핑몰 담당자 정보를</p>
        <p>입력해주세요.</p>
      </h2>

      <div class="manager_info_text_area">
        <p class="text-start manager_info_text">
          앱 사용 기간 만료등 안내에 필요합니다.
        </p>
        <p class="text-start manager_info_text">
          연락 가능한 정보를 입력하시면 편리하게
        </p>
        <p class="text-start manager_info_text">사용이 가능합니다.😊😀</p>
      </div>
      <div class="manager_info_input_area">
        <input
          type="text"
          v-model="mall_name"
          class="manager_info_input"
          placeholder="쇼핑몰명"
        />
      </div>
      <div class="manager_info_input_area">
        <input
          type="text"
          v-model="mall_manager"
          class="manager_info_input"
          placeholder="담당자명"
        />
      </div>
      <div class="manager_info_input_area">
        <input
          type="text"
          v-model="mall_phone"
          class="manager_info_input"
          placeholder="핸드폰번호"
          @keydown="checkNumber($event)"
          @keypress="replaceNumber($event, 12, false)"
          @blur="replaceNumber($event, 12, false)"
        />
      </div>
      <div class="manager_info_input_area">
        <input
          type="text"
          v-model="mall_email"
          class="manager_info_input"
          placeholder="이메일주소"
        />
      </div>

      <div class="btns manager_info_button_area">
        <button
          type="button"
          @click="close"
          class="btn btn_large btn_secondary manager_info_button"
          style="margin-right: 8px"
        >
          <span>닫기</span>
        </button>
        <button
          type="button"
          @click="mallInfoSave()"
          class="btn btn_large btn_primary manager_info_button"
        >
          <span>저장</span>
        </button>
      </div>
    </section>
    <!-- //layer : 추천 상품 결과 노출 - 정보 불러오기 -->
  </vue-final-modal>
</template>

<script>
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    show_modal: false,
    mall_name: null,
    mall_manager: null,
    mall_phone: null,
    mall_email: null,
  }),
  created: async function () {
    this.init();
  },
  methods: {
    init: function () {},
    showModal: function (params) {
      if (params.mall_name) {
        this.mall_name = params.mall_name;
      }
      if (params.mall_manager) {
        this.mall_manager = params.mall_manager;
      }
      if (params.mall_phone) {
        this.mall_phone = params.mall_phone;
      }
      if (params.mall_email) {
        this.mall_email = params.mall_email;
      }

      this.show_modal = true;
    },
    mallInfoSave: async function () {
      if (!this.mall_name) {
        alert('쇼핑몰명을 입력해 주세요.');
        return false;
      }
      if (!this.mall_manager) {
        alert('담당자명을 입력해 주세요.');
        return false;
      }
      if (!this.mall_phone) {
        alert('핸드폰 번호를 입력해주세요.');
        return false;
      }
      if (!this.mall_email) {
        alert('이메일 주소를 입력해주세요. ');
        return false;
      }

      let phone_number_check_result = this.isPhoneNumber(this.mall_phone);
      if (!phone_number_check_result || this.mall_phone.length >= 12) {
        alert('핸드폰 번호가 올바르지 않습니다.');
        return false;
      }

      let mail_check_result = this.isMail(this.mall_email);
      if (!mail_check_result) {
        alert('이메일 주소가 올바르지 않습니다.');
        return false;
      }

      await this.postOrderLatest();
    },
    postOrderLatest: async function () {
      let set_param = {
        mall_name: this.mall_name,
        mall_manager: this.mall_manager,
        mall_phone: this.mall_phone,
        mall_email: this.mall_email,
      };

      let result = await this.$store.dispatch(
        'payment/postOrderLatest',
        set_param,
      );
      if (result) {
        alert('저장되었습니다.');
        this.show_modal = false;
      } else {
        alert('저장 실패 했습니다.');
      }
    },
    isPhoneNumber: function (phone_number) {
      let regExp = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
      let result = regExp.test(phone_number);
      return result;
    },
    isMail: function (mail) {
      let regExp =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
      let result = regExp.test(mail);
      return result;
    },
    checkNumber: function (e) {
      if (
        e.keyCode == 69 ||
        e.keyCode == 190 ||
        e.keyCode == 109 ||
        e.keyCode == 189
      ) {
        e.preventDefault();
        return false;
      }
    },
    replaceNumber: function (e, max, chkNumber = false) {
      let val = e.target.value;
      let replaceVal = val.replace(/[^0-9]/g, '').substr(0, max - 1);
      e.target.value = chkNumber === true ? Number(replaceVal) : replaceVal;
      if (chkNumber === true && e.target.value == 0) {
        e.target.value = '';
      }
      var event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      e.target.dispatchEvent(event);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn.btn_light {
  background-color: #4b5563;
  color: #ffff;
}

.text-start {
  text-align: start !important;
}

.layer_manager_info_dialog {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 300px;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  z-index: 1001;
  box-shadow: inset 1px 1px 3px 0 rgb(0 0 0 / 10%);
  padding-bottom: 0px;
}
.manager_info_title {
  font-size: 1.25rem;
  font-weight: 600;
}

.manager_info_text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 400;
}

.manager_info_input {
  width: 100%;
}
.manager_info_input_area {
  padding-top: 12px;
}
.manager_info_button_area {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}
.manager_info_button {
  width: 50%;
}
.manager_info_text_area {
  margin-bottom: 8px;
}
</style>
