<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    v-model="show_modal"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <!-- layer : 추천 상품 설정 -->
    <section id="modalRecomGoods" class="layer_dialog">
      <h2>추천 상품 설정</h2>
      <p class="txt">
        [Step2. 추천 상품 설정]에서 추가한 상품을 기반으로 설정이 가능합니다.
        <br />
        <em v-if="this.survey_config.question_type === 'choice'" class="txt_org"
          >* 추천 상품 노출 설정 : 질문/답변 선택형 - 상품을 설정하여 많은
          선택이 된 상품을 추천합니다.</em
        >
        <em v-else class="txt_org"
          >* 추천 상품 노출 설정 : 질문/답변 점수형 - 상품의 점수를 설정하여
          많은 점수가 나온 상품을 추천합니다.</em
        >
      </p>

      <div class="tit_recom">
        [{{ this.params.subject_type[this.params.question.subject_type] }}]
        {{ this.params.question.subject }}
      </div>

      <div class="recom_answer">
        {{ this.params.answer.subject ? this.params.answer.subject : '파일' }}
        <button
          v-if="this.survey_config.question_type === 'choice'"
          @click="allClick('unselect')"
          class="btn btn_small btn_light ml5 fl_r"
        >
          <span>전체해제</span>
        </button>
        <button
          v-if="this.survey_config.question_type === 'choice'"
          @click="allClick('select')"
          class="btn btn_small btn_light ml5 fl_r"
        >
          <span>전체선택</span>
        </button>
      </div>
      <!-- 선택형 일때 노출 -->
      <ul class="goods_list">
        <template v-for="(product, key) in this.product_list" :key="key">
          <li v-if="this.survey_config.question_type === 'choice'">
            <input
              type="checkbox"
              v-model="product_list[key].checked"
              :checked="product.checked"
              :id="`goodsTit_${key}`"
              value="1"
              @change="checkboxChange(key)"
            />
            <label :for="`goodsTit_${key}`">{{ product.product_name }}</label>
          </li>
          <li v-else>
            <div class="input_type">
              {{ product.product_name }}
              <input
                type="number"
                name=""
                v-model="this.product_list[key].score"
                placeholder="0"
                min="1"
                max="9999"
                style="text-align: left"
                @input="onlyNumber($event, key)"
              />
            </div>
          </li>
        </template>
      </ul>

      <div class="btns">
        <ul>
          <li>
            <button @click="close" class="btn btn_large btn_secondary">
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="submit"
              class="btn btn_large btn_primary"
            >
              저장
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 추천 상품 설정 추가 -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  inheritAttrs: false,
  data: () => ({
    offset: 0,
    limit: 50,
    show_modal: true,
    hierarchy_id: 0,
    hierarchy_product_list: [],
    product_list: [],
    score: 0,
  }),
  props: ['params', 'survey_config'],
  created: async function () {
    this.hierarchy_id = this.params.answer.hierarchy_id;
    this.hierarchy_product_list = this.params.answer.product_list;
    // 총 상품수(total_count)를 알기 위한 조회
    await this.getProductList(0, 1);
    await this.getProductList(this.offset, this.products.total_count);
  },
  computed: {
    ...mapState('qna_product', ['products']),
    ...mapState('qna_setting'),
  },
  methods: {
    getProductList: async function (offset = 0, limit = 20) {
      await this.$store.dispatch('qna_product/getProducts', {
        offset: offset,
        limit: limit,
      });

      if (this.survey_config.question_type === 'choice') {
        this.score = 1;
      }

      this.product_list = this.products.list;

      if (
        Array.isArray(this.hierarchy_product_list) &&
        this.hierarchy_product_list.length > 0
      ) {
        for (const [i, product] of Object.entries(this.product_list)) {
          this.product_list[i].score = this.score;

          for (const [j, hierarchy_product] of Object.entries(
            this.hierarchy_product_list,
          )) {
            if (product.id === hierarchy_product.product_id) {
              this.product_list[i].checked = true;

              // 선택형은 무조건 1점.
              if (this.survey_config.question_type === 'choice')
                this.product_list[i].score = 1;
              else this.product_list[i].score = hierarchy_product.score;

              break;
            }
          }
        }
      }
    },
    onlyNumber: function (event, key) {
      let value = event.target.value.replace(/[^0-9.]/g, '');
      if (value === '') {
        value = 0;
      }

      value = parseInt(value);

      this.product_list[key].score = value;
      event.target.value = value;
    },
    submit: async function () {
      let params = {
        hierarchy_id: this.hierarchy_id,
        parameters: [],
      };

      for (const [key, item] of Object.entries(this.product_list)) {
        if (this.survey_config.question_type === 'choice') {
          if (item.checked !== true) {
            continue;
          }
          item.score = 1;
        } else if (this.survey_config.question_type === 'score') {
          if (
            item.score === null ||
            typeof item.score == 'undefined' ||
            item.score < 0
          ) {
            continue;
          }
        }
        params.parameters.push({
          hierarchy_id: this.hierarchy_id,
          product_id: item.id,
          score: Number(item.score),
        });
      }

      const result = await this.$store.dispatch(
        'qna_setting/postHierarchyProducts',
        params,
      );
      if (result === true) {
        alert('추천 상품이 설정 되었습니다.');
        this.show_modal = false;
        return false;
      } else {
        alert('추천 상품 설정을 실패하였습니다.');
        return false;
      }
    },
    checkboxChange: function (index) {
      if (this.product_list[index].checked == true) {
        this.product_list[index].score = 1;
      } else {
        this.product_list[index].score = 0;
      }
    },
    allClick: function (type) {
      let is_check = false;
      if (type == 'select') {
        is_check = true;
      }

      for (const [i, product] of Object.entries(this.product_list)) {
        this.product_list[i].checked = is_check;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.goods_list label {
  padding-right: 60px;
}
</style>
