import M from '@/libs/mapping.js';

const actions = {
  // 토큰재발급
  async getRefreshToken({ commit }) {
    try {
      const result = await M.api(M.entry('/get/auth/refresh'));
      commit('setToken', result);
    } catch (e) {
      // 토큰 재발급 실패시 처리 할 내용
    }
  },
  // 쇼핑몰 상세 정보
  async getStoreInfo({ commit }) {
    try {
      const result = await M.api(M.entry('/get/mall/store'));
      commit('setStore', result);
    } catch (e) {
      // 토큰 재발급 실패시 처리 할 내용
    }
  },
  // 쇼핑몰 리스트
  async getMallList({ commit }) {
    try {
      const result = await M.api(M.entry('/get/mall/shops'));
      commit('setMallList', result);
    } catch (e) {
      // 토큰 재발급 실패시 처리 할 내용
    }
  },
  async getSurveyMallList({ commit }) {
    try {
      const result = await M.api(M.entry('/get/survey/shops'));
      commit('setSurveyMallList', result);
    } catch (e) {
      //
    }
  },
  async setSurveyMallList({ commit }, params) {
    try {
      const result = await M.api(M.entry('/post/survey/shops'), params);
      commit('setSurveyMallList', result);
    } catch (e) {
      //
    }
  },
  async getSurveyGroups({ commit }) {
    try {
      const result = await M.api(M.entry('/get/survey/groups'));
      commit('setMembershipGroups', result);
    } catch (e) {
      //
    }
  },
  // 이미지 등록
  async postImage(commit, params) {
    const result = await M.api(M.entry('/post/survey/upload/image'), params);
    return result.image;
  },
  // 스킨 (Desing) 조회
  async getSkinCode({ commit }, params) {
    const result = await M.api(M.entry('/get/survey/skincode'), params);
    commit('setSkinCode', result);
  },
};

export default actions;
