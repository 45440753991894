<template>
  <div>
    <!-- fullscreen : 결과화면 노출항목 정보 등록 -->
    <section
      id="fullRecomReg"
      class="fullscreen"
      :class="{ upscreen: this.is_open }"
    >
      <div class="full_header">
        <h2>결과화면 노출항목 정보 등록</h2>
        <button type="button" class="btn_close" @click="hidePopup()">
          <span class="material-icons md-white">close</span>
        </button>
        <button type="button" class="btn_close2" @click="hidePopup">
          <span>닫기</span>
        </button>
      </div>

      <div class="full_content">
        <h3 class="tit">결과화면 노출항목 정보 등록</h3>
        <p class="mb20">
          아래의 추천 상품이 결과 화면에서 추천될때 '결과화면 노출항목'에 등록한
          정보 노출이 가능합니다.<br />
          Step4. 결과 화면 설정 페이지에서 등록한 정보를 선택하여 노출할 수 있어
          다양한 방법으로 원하시는 결과 화면을 만드실 수 있습니다.
        </p>

        <div class="conbox">
          <table class="tbl_write">
            <caption>
              결과화면 노출항목 정보 등록
            </caption>
            <colgroup>
              <col style="width: 20%" />
              <col style="width: 80%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">상품명</th>
                <td>{{ product.product_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="conbox" v-for="(item, key) in this.result_items" :key="key">
          <div v-if="item.type === 'image'">
            <h4 class="stit">[이미지] {{ item.title }}</h4>
            <!-- file upload -->
            <div class="upload full">
              <span class="material-icons md-light ico_file">attach_file</span>
              <input
                type="text"
                :id="`addFile_` + key"
                class="input_read"
                readonly="readonly"
                v-model="this.product_result_items[key].image_path"
                placeholder="5M 이하 / gif, png, jpg(jpeg)"
              />
              <span
                class="input_wrap mt10"
                v-if="this.product_result_items[key].image_path === ''"
              >
                <input
                  type="button"
                  class="btn btn_small btn_light pointer"
                  value="업로드"
                  title="업로드"
                  @click="fileClick($event)"
                /><input
                  type="file"
                  class="input_hidden"
                  @change="changeImage(key, $event)"
                />
              </span>

              <!-- 업로드 후 -->
              <p
                v-if="this.product_result_items[key].image_path"
                class="upload_view ml5 mt10"
              >
                <button
                  type="button"
                  @click="showImage(key)"
                  class="btn btn_small btn_secondary mr10"
                >
                  <span>이미지 보기</span>
                </button>
                <button type="button" @click="clearImage(key)">
                  <span class="material-icons md-light">cancel</span>
                </button>
              </p>
              <!-- //file upload -->
            </div>
          </div>
          <div v-else>
            <div class="clfix">
              <h4 class="stit fl_l mb0 mt10">[텍스트] {{ item.title }}</h4>
            </div>

            <div class="textarea">
              <QuillEditor
                :id="`textarea_` + key"
                :ref="`textarea_` + key"
                theme="snow"
                :toolbar="[
                  {
                    header: [1, 2, 3, 4, 5, 6, false],
                  },
                  'bold',
                  'italic',
                  'underline',
                  { color: [] },
                ]"
                v-model:content="this.product_result_items[key].text"
                contentType="html"
                placeholder="내용을 입력해주세요."
                class="customEdiorClass"
                @editorChange="editorChangeCall($event, key)"
              />
            </div>
          </div>
        </div>

        <div
          v-if="
            Array.isArray(this.result_items) !== true ||
            this.result_items.length <= 0
          "
          class="conbox"
        >
          <!-- 설정된 노출항목이 없는 경우 -->
          <div class="nodata">
            설정된 노출항목이 없습니다.<br />
            하단의 버튼을 클릭하여 설정하신 후 내용을 등록해주세요.
          </div>
          <!-- //설정된 노출항목이 없는 경우 -->
        </div>

        <div class="btn_wrap right">
          <button
            type="button"
            class="btn btn_large btn_secondary btn_wide"
            @click="showPopup"
          >
            <span>결과화면 노출항목 설정/관리</span>
          </button>
          <button
            type="button"
            class="btn btn_large btn_primary btn_wide ml5"
            @click="add"
          >
            <span>저장하기</span>
          </button>
        </div>
      </div>
    </section>
    <!-- //fullscreen : 결과화면 노출항목 정보 등록 -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';
import ModalImagePreview from '@/components/qna/modal/ModalImagePreview.vue';

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  mixins: [common],
  inheritAttrs: false,
  // components: [ModalsContainer, ModalImagePreview, QuillEditor],
  components: {
    QuillEditor,
  },
  data: () => ({
    is_open: false,
    is_product_option_popup: false,
    result_items: [],
    product_result_items: [],
    params: [],
  }),
  props: ['survey_config_info', 'product'],
  created: async function () {
    this.init();
    this.hideScrollbar();
  },
  computed: {
    ...mapState('qna_product', ['product_list']),
  },
  mounted() {
    let timer = setTimeout(() => {
      if (this.is_open === true) {
        clearTimeout(timer);
      }

      this.is_open = true;
    }, 100);

    this.$nextTick(function () {
      this.initMinicolors();
    });
  },
  unmounted() {
    if (this.is_open == true) {
      this.hidePopup();
    }
  },
  methods: {
    setColor: function (e) {
      const key = e.target.id;
      const hex = e.target.value;

      this.product_result_items[key].color = hex;
    },
    init: async function (type = '') {
      if (
        this.survey_config_info.result_item === null ||
        this.survey_config_info.result_item === undefined ||
        this.survey_config_info.result_item === ''
      ) {
        return false;
      }

      if (type !== 'option') {
        this.product_result_items = [];
      }

      this.result_items = JSON.parse(this.survey_config_info.result_item);
      const product_result_item = JSON.parse(this.product.product_result_item);
      for (const [key, item] of Object.entries(this.result_items)) {
        this.product_result_items.push({
          id: item.id,
          type: item.type,
          title: item.title,
          image: '',
          image_path: '',
          font_size: '16px',
          font_bold: 'normal',
          color: '#000000',
          text: '',
        });
      }

      if (
        Array.isArray(product_result_item) &&
        product_result_item.length > 0
      ) {
        for (const [key, item] of Object.entries(product_result_item)) {
          if (this.product_result_items[key] === undefined) {
            continue;
          }

          if (item.type != this.product_result_items[key].type) {
            item.type = this.product_result_items[key].type;
          }

          this.product_result_items[key].type = item.type;
          this.product_result_items[key].title =
            item.title !== undefined
              ? item.title
              : this.product_result_items[key].title;
          this.product_result_items[key].image = item.image;
          this.product_result_items[key].image_path = item.image_path;
          this.product_result_items[key].font_size = item.font_size;
          this.product_result_items[key].font_bold = item.font_bold || 'normal';
          this.product_result_items[key].color = item.color;
          this.product_result_items[key].text = item.text;
        }
      }
    },
    add: async function () {
      if (!this.product_result_items || this.product_result_items.length == 0) {
        alert('저장할 내역이 없습니다.\n노출항목을 추가하여 등록해주세요.');
        return false;
      }

      for (const [key, item] of Object.entries(this.product_result_items)) {
        if (
          item.type === 'image' &&
          item.image !== '' &&
          item.image.indexOf('http') == -1
        ) {
          let image = {
            image: item.image,
          };

          const image_url = await this.$store.dispatch(
            'common/postImage',
            image,
          );

          this.product_result_items[key].image = image_url;
          this.product_result_items[key].image_path = image_url;
        }
      }

      let _index = 0;
      for (const item of this.product_result_items) {
        if (item.type == 'text' && item.text) {
          let ref = 'textarea_' + _index;
          item.text = this.$refs[ref].getHTML();
        }
        _index++;
      }

      const result_option = JSON.stringify(this.product_result_items);
      const params = {
        survey_config_id: sessionStorage.getItem('survey_survey_config_id'),
        product_id: this.product.product_no,
        product_no: this.product.product_no,
        product_name: this.product.product_name,
        product_result_item: result_option,
      };

      const result = await this.$store.dispatch(
        'qna_product/putProduct',
        params,
      );

      if (result === true) {
        if (
          confirm('저장되었습니다.\n추천 상품 설정 페이지로 이동하시겠습니까?')
        ) {
          this.hidePopup();
        }
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
    showPopup(item = null) {
      this.$emit('showPopup', 'option');
    },
    hidePopup() {
      this.is_open = false;
      this.showScrollbar();
      this.$emit('hidePopup', 'result_option');
    },
    initMinicolors: function () {
      const self = this;
      if ($('.minicolors').length > 0) {
        if ($('.minicolors-theme-default').length == 0) {
          $('.minicolors').minicolors({
            animationEasing: 'swing',
            keywords: '#',

            change(hex, opacity) {
              const key = $(this).prop('id');
              self.product_result_items[key].color = hex;
            },
          });
        }

        if (
          Array.isArray(self.product_result_items) &&
          self.product_result_items.length > 0
        ) {
          for (const [key, item] of Object.entries(self.product_result_items)) {
            $('#' + key).minicolors('value', item.color);
          }
        }
      }
    },
    changeImage: async function (index, event) {
      this.product_result_items[index].image_path = event.target.value;
      const result = await this.uploadImage(event.target.files[0]);
      if (!result) {
        this.product_result_items[index].image_path = '';
      }
      this.product_result_items[index].image = result;
    },
    clearImage(index) {
      this.product_result_items[index].image = '';
      this.product_result_items[index].image_path = '';
    },
    showImage(index) {
      const preview_image = this.product_result_items[index].image;

      this.$vfm.show({
        component: ModalImagePreview,
        bind: {
          image: preview_image,
        },
      });
    },
    changeColor(e) {
      //console.log(e);
    },
    setFontBold: function (index) {
      const element = document.getElementById('textarea_' + index);

      if (this.result_items[index].font_bold !== 'bold') {
        this.result_items[index].font_bold = 'bold';
      } else {
        this.result_items[index].font_bold = 'normal';
      }

      this.product_result_items[index].font_bold =
        this.result_items[index].font_bold;

      element.style.fontWeight = this.result_items[index].font_bold;
    },
    fileClick: function (e) {
      $(e.target).next('input').click();
    },
    editorChangeCall: function (e, key) {
      let ref = 'textarea_' + key;
      let text = this.$refs[ref].getText();
      if (text.length <= 1) {
        this.$refs[ref].setHTML('');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upscreen {
  transform: none;
}

textarea {
  resize: none;
}

.btn_close2 {
  position: absolute;
  top: 13px;
  right: 20px;
  color: #fff;
}

.input_hidden {
  width: 63px;
  z-index: -1;
}

.upload_view {
  width: 132px;
}
</style>

<style>
/*
placeholder 폰트 기울임으로 인하여 추가
*/
.fullscreen .ql-editor.ql-blank::before {
  font-style: normal !important;
}
</style>
