<template>
  <div v-show="is_show">
    <!-- container -->
    <section id="container" ref="container">
      <div class="content">
        <h2 class="title">맞춤 질문 설정</h2>
        <p class="dsc">
          쇼핑몰 고객들에게 제공할 맞춤 질문 설정 및 관리를 합니다.<br />
          수집한 데이터를 기반으로 해당 고객에게 적합한 상품 추천이
          가능합니다.<br />
          아래 1~4 스텝별 메뉴를 클릭하여 순서대로 설정해주세요.
        </p>

        <div class="step">
          <ul>
            <li>
              <template
                v-if="
                  typeof this.qna_config === 'object' &&
                  this.qna_config !== null
                "
              >
                <em><a v-bind:href="`/qna/product`">1</a></em>
                <router-link to="/qna/product">추천 상품 설정</router-link>
              </template>
              <template v-else>
                <em>1</em>
                <a
                  style="color: #888"
                  href="javascript:alert('기본 정보 설정을 먼저 등록해주세요.');"
                  >추천 상품 설정</a
                >
              </template>
            </li>

            <li>
              <template
                v-if="
                  typeof this.qna_config === 'object' &&
                  this.qna_config !== null
                "
              >
                <em><a v-bind:href="`/qna/setting`">2</a></em>
                <router-link to="/qna/setting">질문 설정</router-link>
              </template>
              <template v-else>
                <em>2</em>
                <a
                  style="color: #888"
                  href="javascript:alert('기본 정보 설정을 먼저 등록해주세요.');"
                  >질문 설정</a
                >
              </template>
            </li>

            <li class="on">
              <em>3</em>
              화면 관련 설정
            </li>

            <li>
              <template
                v-if="
                  typeof this.qna_config === 'object' &&
                  this.qna_config !== null
                "
              >
                <em><a v-bind:href="`/qna/result`">4</a></em>
                <router-link to="/qna/result">결과 화면 설정</router-link>
              </template>
              <template v-else>
                <em>4</em>
                <a
                  style="color: #888"
                  href="javascript:alert('기본 정보 설정을 먼저 등록해주세요.');"
                  >결과 화면 설정</a
                >
              </template>
            </li>
          </ul>
        </div>

        <form name="form" @submit.prevent="checkForm" autocomplete="off">
          <div class="conbox">
            <h3 class="tit">맞춤 질문 사용 설정</h3>
            <table class="tbl_write">
              <caption>
                사용자 접근 권한
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 80%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">사용자 접근 권한</th>
                  <td>
                    <select
                      name=""
                      style="width: 210px"
                      class="mr10"
                      v-model="params.permission"
                    >
                      <option value="anonymous">비회원 + 회원 모두 사용</option>
                      <option value="member">회원만 사용</option>
                    </select>
                    <div
                      v-if="params.permission === 'member'"
                      class="layer_group"
                    >
                      <button
                        type="button"
                        @click="isOpenMemberLayer = !isOpenMemberLayer"
                        class="btn btn_small btn_secondary open_layer"
                      >
                        <span>사용할 회원등급설정</span>
                      </button>
                      <!-- layer : 샵추가 -->
                      <div
                        v-if="isOpenMemberLayer"
                        id="layer_grade"
                        class="layer_popup"
                      >
                        <h2>특정회원등급 사용 설정</h2>
                        <ul class="check">
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              value=""
                              v-model="all_checked"
                              @click="allChecked($event.target.checked)"
                              id="grade_1"
                            /><label for="grade_1">전체 선택</label>
                          </li>
                          <li
                            v-for="(item, group_index) in this.membership_list"
                            :key="group_index"
                          >
                            <input
                              type="checkbox"
                              name=""
                              :id="item.group_no"
                              :value="item.group_no"
                              v-model="item.checked"
                              @change="syncGroup"
                            /><label :for="item.group_no">{{
                              item.group_name
                            }}</label>
                          </li>
                        </ul>
                        <div class="btns">
                          <ul>
                            <li>
                              <button
                                @click="isOpenMemberLayer = false"
                                class="btn btn_small btn_secondary close_layer"
                              >
                                <span>닫기</span>
                              </button>
                            </li>
                            <li>
                              <button
                                class="btn btn_small btn_primary"
                                @click="setMembershipGroup()"
                              >
                                <span>저장하기</span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!-- //layer : 샵추가 -->
                    </div>
                    <p
                      v-if="params.permission === 'member'"
                      class="txt_org mt10 fs14"
                    >
                      <span
                        v-for="(item, selected_index) in this.params
                          .permitted_group_text"
                        :key="selected_index"
                        v-text="
                          item.group_name +
                          (selected_index <
                          this.params.permitted_group_text.length - 1
                            ? ', '
                            : '')
                        "
                      >
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="conbox">
            <h3 class="tit">인트로 화면 설정</h3>
            <table class="tbl_write">
              <caption>
                사용자 접근 권한
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 80%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">인트로 화면 사용</th>
                  <td>
                    <ul class="input_list">
                      <li>
                        <input
                          type="radio"
                          name="intro"
                          v-model="params.intro_status"
                          value="enabled"
                          id="intro_1"
                        /><label for="intro_1">사용함</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="intro"
                          v-model="params.intro_status"
                          value="disabled"
                          id="intro_2"
                        /><label for="intro_2">사용안함</label>
                      </li>
                    </ul>

                    <div
                      v-if="params.intro_status === 'enabled'"
                      class="option"
                    >
                      <dl>
                        <dt>배경이미지</dt>
                        <dd>
                          <dl>
                            <dt class="narrow">PC</dt>
                            <dd>
                              <!-- file upload -->
                              <div class="upload fl_l">
                                <span
                                  class="
                                    material-icons
                                    notranslate
                                    md-light
                                    ico_file
                                  "
                                  >attach_file</span
                                >
                                <input
                                  type="text"
                                  id="addFile_2"
                                  class="input_read"
                                  readonly="readonly"
                                  v-model="this.params.intro_desktop_bg_path"
                                  placeholder="940px * 680px 권장 / 5M 이하 / gif, png, jpg(jpeg)"
                                />
                                <span
                                  class="input_wrap"
                                  v-if="
                                    this.params.intro_desktop_bg_path === ''
                                  "
                                >
                                  <input
                                    type="button"
                                    class="btn btn_small btn_light pointer"
                                    value="업로드"
                                    title="업로드"
                                    @click="fileClick($event)"
                                  /><input
                                    type="file"
                                    class="input_hidden"
                                    @change="changeIntroDesktopImage"
                                  />
                                </span>
                              </div>
                              <!-- 업로드 후 -->
                              <span
                                v-if="this.params.intro_desktop_bg_path"
                                class="upload_view"
                              >
                                <button
                                  type="button"
                                  @click="showImagePreview('intro_desktop')"
                                  class="btn btn_small btn_secondary mr10"
                                >
                                  <span>이미지 보기</span>
                                </button>
                                <button
                                  type="button"
                                  @click="clearImage('intro_desktop')"
                                >
                                  <span
                                    class="material-icons notranslate md-light"
                                    >cancel</span
                                  >
                                </button>
                              </span>
                              <!-- //file upload -->
                            </dd>
                          </dl>

                          <dl>
                            <dt class="narrow">모바일</dt>
                            <dd>
                              <!-- file upload -->
                              <div class="upload fl_l">
                                <span
                                  class="
                                    material-icons
                                    notranslate
                                    md-light
                                    ico_file
                                  "
                                  >attach_file</span
                                >
                                <input
                                  type="text"
                                  id="addFile_3"
                                  class="input_read"
                                  readonly="readonly"
                                  v-model="this.params.intro_mobile_bg_path"
                                  placeholder="360px * 640px 권장 / 5M 이하 / gif, png, jpg(jpeg)"
                                />
                                <span
                                  class="input_wrap"
                                  v-if="this.params.intro_mobile_bg_path === ''"
                                >
                                  <input
                                    type="button"
                                    class="btn btn_small btn_light pointer"
                                    value="업로드"
                                    title="업로드"
                                    @click="fileClick($event)"
                                  /><input
                                    type="file"
                                    class="input_hidden"
                                    @change="changeIntroMobileImage"
                                  />
                                </span>
                              </div>
                              <!-- 업로드 후 -->
                              <span
                                v-if="this.params.intro_mobile_bg_path"
                                class="upload_view"
                              >
                                <button
                                  type="button"
                                  @click="showImagePreview('intro_mobile')"
                                  class="btn btn_small btn_secondary mr10"
                                >
                                  <span>이미지 보기</span>
                                </button>
                                <button
                                  type="button"
                                  @click="clearImage('intro_mobile')"
                                >
                                  <span
                                    class="material-icons notranslate md-light"
                                    >cancel</span
                                  >
                                </button>
                              </span>
                              <!-- //file upload -->
                            </dd>
                          </dl>
                        </dd>
                      </dl>
                      <dl>
                        <dt>총 사용자 수 노출</dt>
                        <dd>
                          <ul id="user_count_status" class="input_list row">
                            <li>
                              <input
                                type="radio"
                                name="user"
                                v-model="params.intro_count_display_status"
                                value="disabled"
                                id="user_1"
                                checked
                              /><label for="user_1">사용안함</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="user"
                                v-model="params.intro_count_display_status"
                                value="enabled_type1"
                                id="user_2"
                              /><input
                                id="intro_type1_text1"
                                type="text"
                                v-model="params.intro_type1_text1"
                                @keyup="inputText($event)"
                              /><span class="ml10 mr10">$총 사용자 수$</span
                              ><input
                                id="intro_type1_text2"
                                type="text"
                                v-model="params.intro_type1_text2"
                                @keyup="inputText($event)"
                                style="width: 290px"
                              />
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="user"
                                v-model="params.intro_count_display_status"
                                value="enabled_type2"
                                id="user_3"
                              /><span class="mr10">$총 사용자 수$</span
                              ><input
                                id="intro_type2_text1"
                                type="text"
                                v-model="params.intro_type2_text1"
                                @keyup="inputText($event)"
                                style="width: 290px"
                              />
                            </li>
                          </ul>
                        </dd>
                      </dl>
                      <dl>
                        <dt>총 사용자 수 색상</dt>
                        <dd>
                          <div class="color">
                            <label for="totalUserColor"
                              ><input
                                type="text"
                                class="minicolors"
                                ref="intro_count_color"
                                v-model="params.intro_count_color"
                                :maxlength="7"
                                id="totalUserColor"
                                @input="
                                  onlyHtmlColor($event, 'intro_count_color')
                                "
                              /><span class="material-icons notranslate"
                                >color_lens</span
                              ></label
                            >
                            <span
                              class="color_preview_box"
                              :style="
                                getBackgroundColor(params.intro_count_color)
                              "
                            ></span>
                          </div>
                        </dd>
                      </dl>
                      <dl>
                        <dt>시작하기 버튼명</dt>
                        <dd class="maxWidth240 minWidth170">
                          <input
                            id="intro_start_text"
                            type="text"
                            name=""
                            ref="intro_start_text"
                            v-model="params.intro_start_text"
                            @keyup="inputText($event)"
                          />
                        </dd>
                        <dt>시작하기 버튼명 색상</dt>
                        <dd class="maxWidth240 minWidth170">
                          <div class="color">
                            <label for="introStartTextColor"
                              ><input
                                type="text"
                                class="minicolors"
                                ref="intro_start_text_color"
                                v-model="params.intro_start_text_color"
                                :maxlength="7"
                                id="introStartTextColor"
                                @input="
                                  onlyHtmlColor(
                                    $event,
                                    'intro_start_text_color',
                                  )
                                "
                              /><span class="material-icons notranslate"
                                >color_lens</span
                              ></label
                            >
                            <span
                              class="color_preview_box"
                              :style="
                                getBackgroundColor(
                                  params.intro_start_text_color,
                                )
                              "
                            ></span>
                          </div>
                        </dd>
                      </dl>
                      <dl>
                        <dt>시작하기 버튼 색상</dt>
                        <dd class="maxWidth240 minWidth170">
                          <div class="color">
                            <label for="startBtnColor"
                              ><input
                                type="text"
                                class="minicolors"
                                ref="intro_start_color"
                                v-model="params.intro_start_color"
                                :maxlength="7"
                                id="startBtnColor"
                                @input="
                                  onlyHtmlColor($event, 'intro_start_color')
                                "
                              /><span class="material-icons notranslate"
                                >color_lens</span
                              ></label
                            >
                            <span
                              class="color_preview_box"
                              :style="
                                getBackgroundColor(params.intro_start_color)
                              "
                            ></span>
                          </div>
                        </dd>

                        <dt>시작하기 버튼 테두리 색상</dt>
                        <dd class="maxWidth240 minWidth170">
                          <div class="color">
                            <label for="introStartBorderColor"
                              ><input
                                type="text"
                                class="minicolors"
                                ref="intro_start_border_color"
                                v-model="params.intro_start_border_color"
                                :maxlength="7"
                                id="introStartBorderColor"
                                @input="
                                  onlyHtmlColor(
                                    $event,
                                    'intro_start_border_color',
                                  )
                                "
                              /><span class="material-icons notranslate"
                                >color_lens</span
                              ></label
                            >
                            <span
                              class="color_preview_box"
                              :style="
                                getBackgroundColor(
                                  params.intro_start_border_color,
                                )
                              "
                            ></span>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="conbox">
            <h3 class="tit">기본 디자인 설정</h3>
            <table class="tbl_write">
              <caption>
                기본 디자인 설정
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 80%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">화면 기본 정렬</th>
                  <td>
                    <ul class="input_list">
                      <li>
                        <label for="align_1"
                          ><input
                            type="radio"
                            id="align_1"
                            name="align"
                            value="left"
                            v-model="params.survey_align"
                          />좌측정렬</label
                        >
                      </li>
                      <li>
                        <label for="align_2"
                          ><input
                            type="radio"
                            id="align_2"
                            name="align"
                            value="center"
                            v-model="params.survey_align"
                          />중앙정렬</label
                        >
                      </li>
                      <li>
                        <label for="align_3"
                          ><input
                            type="radio"
                            id="align_3"
                            name="align"
                            value="right"
                            v-model="params.survey_align"
                          />우측정렬</label
                        >
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th scope="row">화면 배경</th>
                  <td>
                    <!-- 배경 색상일 경우 -->
                    <div class="clfix">
                      <select
                        name=""
                        v-model="params.question_bg_type"
                        v-on:change="changeBackgroundType(rowId, $event)"
                        class="fl_l mr10"
                        style="width: 210px"
                      >
                        <option value="color">배경 색상 입력</option>
                        <option value="image">이미지 파일 등록</option>
                      </select>

                      <div
                        :style="
                          params.question_bg_type === 'color'
                            ? ''
                            : 'display:none;'
                        "
                        class="color fl_l"
                      >
                        <label for="bgColor"
                          ><input
                            type="text"
                            v-model="params.survey_bg"
                            class="minicolors"
                            autocomplete="off"
                            ref="survey_bg"
                            id="bgColor"
                            maxlength="7"
                            @input="onlyHtmlColor($event, 'survey_bg')"
                            @blur="colorHexCheck(params.survey_bg)"
                          /><span class="material-icons notranslate"
                            >color_lens</span
                          ></label
                        >
                        <span
                          class="color_preview_box"
                          :style="getBackgroundColor(params.survey_bg)"
                        ></span>
                      </div>

                      <!-- file upload -->
                      <div
                        v-if="params.question_bg_type === 'image'"
                        class="upload fl_l"
                      >
                        <span
                          class="material-icons notranslate md-light ico_file"
                          >attach_file</span
                        >
                        <input
                          type="text"
                          id="addFile_1"
                          class="input_read"
                          readonly="readonly"
                          v-model="this.params.question_bg_image_path"
                          placeholder="2560px * 1300px 권장/ 5M 이하 / gif, png, jpg(jpeg)"
                        />
                        <span
                          class="input_wrap"
                          v-if="this.params.question_bg_image_path === ''"
                        >
                          <input
                            type="button"
                            class="btn btn_small btn_light pointer"
                            value="업로드"
                            title="업로드"
                            @click="fileClick($event)"
                          /><input
                            type="file"
                            class="input_hidden"
                            @change="changeBackgroundImage"
                          />
                        </span>

                        <!-- 업로드 후 -->
                        <span
                          v-if="this.params.question_bg_image_path"
                          class="upload_view"
                        >
                          <button
                            type="button"
                            @click="showImagePreview('background')"
                            class="btn btn_small btn_secondary mr10"
                          >
                            <span>이미지 보기</span>
                          </button>
                          <button
                            type="button"
                            @click="clearImage('background')"
                          >
                            <span class="material-icons notranslate md-light"
                              >cancel</span
                            >
                          </button>
                        </span>
                      </div>
                      <!-- //file upload -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">질문 박스 배경 색상</th>
                  <td>
                    <div class="color">
                      <label for="boxColor"
                        ><input
                          type="text"
                          v-model="params.question_bg_color"
                          :maxlength="7"
                          class="minicolors"
                          ref="question_bg_color"
                          id="boxColor"
                          @input="onlyHtmlColor($event, 'question_bg_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.question_bg_color)"
                      ></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">화면 닫기 버튼<br />(상단 우측)</th>
                  <td>
                    <ul class="input_list">
                      <li>
                        <input
                          type="radio"
                          name="dpClose"
                          id="dpClose_1"
                          v-model="params.close_button"
                          value="first"
                        /><label for="dpClose_1"
                          ><span class="material-icons notranslate md-large"
                            >close</span
                          ></label
                        >
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="dpClose"
                          id="dpClose_2"
                          v-model="params.close_button"
                          value="second"
                        /><label for="dpClose_2"
                          ><span class="material-icons notranslate md-large"
                            >cancel</span
                          ></label
                        >
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="dpClose"
                          id="dpClose_3"
                          v-model="params.close_button"
                          value="third"
                        /><label for="dpClose_3"
                          ><span class="material-icons notranslate md-large"
                            >highlight_off</span
                          ></label
                        >
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="dpClose"
                          id="dpClose_4"
                          v-model="params.close_button"
                          value="none"
                        /><label for="dpClose_4">사용안함</label>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th scope="row">뒤로가기 버튼<br />(상단 좌측)</th>
                  <td>
                    <ul class="input_list">
                      <li>
                        <input
                          type="radio"
                          name="backPage"
                          id="backPage_1"
                          v-model="params.back_button"
                          value="first"
                        /><label for="backPage_1"
                          ><span class="material-icons notranslate md-large"
                            >arrow_back</span
                          ></label
                        >
                      </li>

                      <li>
                        <input
                          type="radio"
                          name="backPage"
                          id="backPage_2"
                          v-model="params.back_button"
                          value="second"
                        /><label for="backPage_2"
                          ><span class="material-icons notranslate md-large"
                            >arrow_circle_left</span
                          ></label
                        >
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="backPage"
                          id="backPage_3"
                          v-model="params.back_button"
                          value="third"
                        /><label for="backPage_3"
                          ><span
                            class="
                              material-icons
                              notranslate
                              -outlined
                              md-large
                            "
                            >arrow_circle_left</span
                          ></label
                        >
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="backPage"
                          id="backPage_4"
                          v-model="params.back_button"
                          value="none"
                        /><label for="backPage_4">사용안함</label>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <th scope="row">전체 버튼 모양</th>
                  <td>
                    <ul class="input_list">
                      <li>
                        <input
                          type="radio"
                          name="buttonShape"
                          id="button_1"
                          v-model="params.button_shape"
                          value="first"
                        /><label for="button_1">
                          <button
                            type="button"
                            class="btn btn_large btn_secondary buttonShape1"
                          >
                            <span>버튼명</span>
                          </button>
                        </label>
                      </li>

                      <li>
                        <input
                          type="radio"
                          name="buttonShape"
                          id="button_2"
                          v-model="params.button_shape"
                          value="second"
                        /><label for="button_2">
                          <button
                            type="button"
                            class="btn btn_large btn_secondary buttonShape2"
                          >
                            <span>버튼명</span>
                          </button>
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="buttonShape"
                          id="button_3"
                          v-model="params.button_shape"
                          value="third"
                        /><label for="button_3">
                          <button
                            type="button"
                            class="btn btn_large btn_secondary buttonShape3"
                          >
                            <span>버튼명</span>
                          </button>
                        </label>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <th scope="row">닫기 및 뒤로가기<br />버튼 색상</th>
                  <td>
                    <div class="color">
                      <label for="closeColor"
                        ><input
                          type="text"
                          v-model="params.close_button_color"
                          :maxlength="7"
                          class="minicolors"
                          ref="close_button_color"
                          id="closeColor"
                          @input="onlyHtmlColor($event, 'close_button_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.close_button_color)"
                      ></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">질문 진행 프로그레스<br />색상</th>
                  <td>
                    <div class="color">
                      <label for="qColor"
                        ><input
                          type="text"
                          v-model="params.progress_bar_color"
                          :maxlength="7"
                          class="minicolors"
                          ref="progress_bar_color"
                          id="qColor"
                          @input="onlyHtmlColor($event, 'progress_bar_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.progress_bar_color)"
                      ></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">질문 단계명<br />텍스트 색상</th>
                  <td>
                    <div class="color">
                      <label for="qStepColor"
                        ><input
                          type="text"
                          v-model="params.question_step_color"
                          :maxlength="7"
                          class="minicolors"
                          ref="question_step_color"
                          id="qStepColor"
                          @input="onlyHtmlColor($event, 'question_step_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.question_step_color)"
                      ></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">질문 내역<br />텍스트 색상</th>
                  <td>
                    <div class="color">
                      <label for="qTitleColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="question_text_color"
                          v-model="params.question_text_color"
                          :maxlength="7"
                          id="qTitleColor"
                          @input="onlyHtmlColor($event, 'question_text_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.question_text_color)"
                      ></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">답변 설정<br />(텍스트필드, 드롭다운)</th>
                  <td>
                    <div class="color mr10">
                      <label for="inputFieldColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="answer_color"
                          v-model="params.answer_color"
                          :maxlength="7"
                          id="inputFieldColor"
                          @input="onlyHtmlColor($event, 'answer_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.answer_color)"
                      ></span>
                      <span class="txt_comt ml20 mt10">
                        ※ 텍스트필드, 드롭다운 선택시 노출되는 테두리 색상
                      </span>
                    </div>
                    <div class="color mt10">
                      <span class="item mr10">텍스트필드 가로사이즈</span>
                      <label for="">
                        <input
                          type="number"
                          min="0"
                          max="740"
                          style="width: 85px"
                          v-model="params.answer_text_width"
                          @blur="onlyNumber($event, 'answer_text')"
                        /><span class="item ml10">px</span>
                        <span class="txt_comt ml20"
                          >※ PC화면만 적용, 100~740 입력가능</span
                        >
                      </label>
                    </div>
                    <div class="color mt10">
                      <span class="item mr10">드롭다운 가로사이즈</span>
                      <label class="dropDownWidthMargin" for="">
                        <input
                          type="number"
                          min="0"
                          max="740"
                          style="width: 85px"
                          v-model="params.answer_dropdown_width"
                          @blur="onlyNumber($event, 'answer_dropdown')"
                        /><span class="item ml10">px</span>
                        <span class="txt_comt ml20"
                          >※ PC화면만 적용, 100~740 입력가능</span
                        >
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">답변 설정<br />(체크박스, 라디오버튼)</th>
                  <td>
                    <div class="color">
                      <label for="inputCheckColor"
                        ><input
                          type="text"
                          v-model="params.answer_checkbox_color"
                          :maxlength="7"
                          class="minicolors"
                          ref="answer_checkbox_color"
                          id="inputCheckColor"
                          @input="
                            onlyHtmlColor($event, 'answer_checkbox_color')
                          "
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="
                          getBackgroundColor(params.answer_checkbox_color)
                        "
                      ></span>
                      <span class="txt_comt ml20"
                        >※ 체크박스, 라디오버튼 선택시 노출되는 색상</span
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">답변 설정<br />(버튼, 파일첨부)</th>
                  <td>
                    <div class="color">
                      <span class="item mr10">기본 버튼색상</span>
                      <label for="btnBgColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="answer_button_color"
                          v-model="params.answer_button_color"
                          :maxlength="7"
                          id="btnBgColor"
                          @input="onlyHtmlColor($event, 'answer_button_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.answer_button_color)"
                      ></span>
                      <!-- -->
                      <span class="item ml20 mr10">기본 버튼 테두리 색상</span>
                      <label for="answerButtonBorderColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="answer_button_border_color"
                          v-model="params.answer_button_border_color"
                          :maxlength="7"
                          @input="
                            onlyHtmlColor($event, 'answer_button_border_color')
                          "
                          id="answerButtonBorderColor"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="
                          getBackgroundColor(params.answer_button_border_color)
                        "
                      ></span>
                      <!-- -->

                      <span class="item ml20 mr10">기본 텍스트색상</span>
                      <label for="btnTextColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="answer_text_color"
                          v-model="params.answer_text_color"
                          :maxlength="7"
                          id="btnTextColor"
                          @input="onlyHtmlColor($event, 'answer_text_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.answer_text_color)"
                      ></span>
                    </div>
                    <div class="color mt10">
                      <span class="item mr10">선택 버튼색상</span>
                      <label for="btnSelectBgColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="answer_select_button_color"
                          v-model="params.answer_select_button_color"
                          :maxlength="7"
                          id="btnSelectBgColor"
                          @input="
                            onlyHtmlColor($event, 'answer_select_button_color')
                          "
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="
                          getBackgroundColor(params.answer_select_button_color)
                        "
                      ></span>

                      <!-- -->
                      <span class="item ml20 mr10">선택 버튼 테두리 색상</span>
                      <label for="answerSelectButtonBorderColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="answer_select_button_border_color"
                          v-model="params.answer_select_button_border_color"
                          :maxlength="7"
                          id="answerSelectButtonBorderColor"
                          @input="
                            onlyHtmlColor(
                              $event,
                              'answer_select_button_border_color',
                            )
                          "
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="
                          getBackgroundColor(
                            params.answer_select_button_border_color,
                          )
                        "
                      ></span>
                      <!-- -->

                      <span class="item ml20 mr10">선택 텍스트색상</span>
                      <label for="btnSelectTextColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="answer_select_text_color"
                          v-model="params.answer_select_text_color"
                          :maxlength="7"
                          id="btnSelectTextColor"
                          @input="
                            onlyHtmlColor($event, 'answer_select_text_color')
                          "
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="
                          getBackgroundColor(params.answer_select_text_color)
                        "
                      ></span>
                    </div>

                    <div class="color mt10">
                      <span class="item mr10">1줄 1개 버튼 가로사이즈</span>
                      <label for="">
                        <input
                          type="number"
                          min="0"
                          max="740"
                          style="width: 85px"
                          v-model="params.answer_button_width"
                          @blur="onlyNumber($event, 'answer')"
                        /><span class="item ml10">px</span>
                        <span class="txt_comt ml20"
                          >※ PC화면만 적용, 100~740 입력가능</span
                        >
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">하단 버튼<br />(다음단계 클릭)</th>
                  <td>
                    <div class="color">
                      <span class="item mr10">버튼색상</span>
                      <label for="btnNextBgColor"
                        ><input
                          type="text"
                          class="minicolors"
                          style="width: 85px"
                          ref="next_button_color"
                          v-model="params.next_button_color"
                          :maxlength="7"
                          id="btnNextBgColor"
                          @input="onlyHtmlColor($event, 'next_button_color')"
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(params.next_button_color)"
                      ></span>

                      <!-- -->
                      <span class="item ml20 mr10">버튼 테두리 색상</span>
                      <label for="nextButtonBorderColor"
                        ><input
                          type="text"
                          class="minicolors"
                          ref="next_button_border_color"
                          v-model="params.next_button_border_color"
                          :maxlength="7"
                          id="nextButtonBorderColor"
                          @input="
                            onlyHtmlColor($event, 'next_button_border_color')
                          "
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="
                          getBackgroundColor(params.next_button_border_color)
                        "
                      ></span>
                      <!-- -->

                      <span class="item ml20 mr10">텍스트색상</span>
                      <label for="btnNextTextColor"
                        ><input
                          type="text"
                          class="minicolors"
                          style="width: 85px"
                          ref="next_button_text_color"
                          v-model="params.next_button_text_color"
                          :maxlength="7"
                          id="btnNextTextColor"
                          @input="
                            onlyHtmlColor($event, 'next_button_text_color')
                          "
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="
                          getBackgroundColor(params.next_button_text_color)
                        "
                      ></span>
                    </div>
                    <div class="color mt10">
                      <span class="item mr10">가로사이즈</span>
                      <label for="">
                        <input
                          type="number"
                          min="0"
                          max="740"
                          style="width: 85px"
                          v-model="params.next_button_width"
                          @blur="onlyNumber($event, 'next')"
                        /><span class="item ml10">px</span>
                        <span class="txt_comt ml20"
                          >※ PC화면만 적용, 100~740 입력가능</span
                        >
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_wrap right pc_result_btn">
            <button
              type="button"
              class="btn btn_large btn_secondary btn_wide"
              @mouseover="previewTip(1, 'pc')"
              @mouseleave="previewTip(0, 'pc')"
              @click="previewOpen()"
            >
              <span>미리보기</span>
            </button>
            <div ref="preview_tip" class="tip3">
              [앱 어드민 > 쇼핑몰 설정] 메뉴의 가이드에 따라<br />
              html 파일을 설정해주셔야 합니다.<br />
              <br />
              버튼을 클릭하시면 미리보기 화면으로 연결됩니다.
            </div>
            <button
              type="submit"
              class="btn btn_large btn_primary btn_wide ml5"
              :disabled="isButtonDisabled"
            >
              <span>저장하기</span>
            </button>
            <!-- button class="btn btn_large btn_primary btn_wide" v-on:click="checkForm" :disabled="btn_disabled"><span>저장하기</span></button -->
          </div>
        </form>
      </div>
      <!-- //content -->
    </section>
    <!-- //container -->

    <ModalsContainer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ModalsContainer } from 'vue-final-modal';

import common from '@/components/mixin/index.js';
import ModalImagePreview from '@/components/qna/modal/ModalImagePreview.vue';
import ModalQnaProductAdd from '@/components/qna/modal/ModalQnaProductAdd.vue';
import { BaseTransition } from '@vue/runtime-core';

export default {
  mixins: [common],
  components: {
    ModalsContainer,
    // eslint-disable-next-line vue/no-unused-components
    ModalImagePreview,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    image: '',
  },
  data: () => ({
    isOpenMemberLayer: false,
    isButtonDisabled: false,
    isLoading: false,
    fullPage: true,
    is_show: false,
    all_checked: false,
    params: {
      survey_config_id: Number,
      permission: String,
      permitted_group_no: String,
      survey_align: String,
      survey_bg: String,
      question_bg_type: 'color',
      question_bg_color: String,
      question_bg_image: '',
      question_bg_image_path: '',
      close_button: String,
      back_button: String,
      progress_bar_color: String,
      question_step_color: String,
      question_text_color: String,
      answer_color: String,
      answer_checkbox_color: String,
      answer_button_color: String,
      answer_button_border_color: String,
      answer_text_color: String,
      answer_button_width: Number,
      answer_select_button_color: String,
      answer_select_button_border_color: String,
      answer_select_text_color: String,
      next_button_color: String,
      next_button_border_color: String,
      next_button_text_color: String,
      next_button_width: Number,
      intro_status: String,
      intro_desktop_bg: '',
      intro_desktop_bg_path: '',
      intro_mobile_bg: '',
      intro_mobile_bg_path: '',
      intro_count_display_status: String,
      intro_count_display: String,
      intro_count_color: String,
      intro_type1_text1: String,
      intro_type1_text2: String,
      intro_type2_text1: String,
      intro_start_text: String,
      intro_start_color: String,
      intro_start_text_color: String,
      intro_start_border_color: String,
      result_item: String,
      result_loading_text: String,
      result_progress_bar_color: String,
      result_desktop_config: String,
      result_mobile_config: String,
      result_question_type: String,
      // eslint-disable-next-line no-dupe-keys
      permitted_group_no: '',
      permitted_group_text: [],
      button_shape: String,
    },
  }),
  created: async function () {
    this.initDefaultInfo();
    await this.$store.dispatch('common/getSurveyGroups');
    await this.getConfigInfo();

    this.is_show = true;
  },
  mounted() {
    this.$nextTick(function () {
      this.init();
    });
  },
  computed: {
    ...mapState('common', ['survey_config_id', 'membership_list']),
    ...mapState('qna_config', ['qna_config']),
  },
  methods: {
    init: async function () {
      const self = this;

      $('.minicolors').minicolors({
        animationEasing: 'swing',
        keywords: '#',
        change(hex, opacity) {
          const target = $('.minicolors').find('input');

          for (const item of target) {
            if ($(item).val() === hex) {
              const id = $(item).prop('id');
              if (id === 'bgColor') self.params.survey_bg = hex;
              if (id === 'boxColor') self.params.question_bg_color = hex;
              if (id === 'closeColor') self.params.close_button_color = hex;
              if (id === 'qColor') self.params.progress_bar_color = hex;
              if (id === 'qStepColor') self.params.question_step_color = hex;
              if (id === 'qTitleColor') self.params.question_text_color = hex;
              if (id === 'inputFieldColor') self.params.answer_color = hex;
              if (id === 'inputCheckColor')
                self.params.answer_checkbox_color = hex;
              if (id === 'btnBgColor') self.params.answer_button_color = hex;
              if (id === 'btnTextColor') self.params.answer_text_color = hex;
              if (id === 'btnSelectBgColor')
                self.params.answer_select_button_color = hex;
              if (id === 'btnSelectTextColor')
                self.params.answer_select_text_color = hex;
              if (id === 'btnNextBgColor') self.params.next_button_color = hex;
              if (id === 'btnNextTextColor')
                self.params.next_button_text_color = hex;
              if (id === 'totalUserColor') self.params.intro_count_color = hex;
              if (id === 'startBtnColor') self.params.intro_start_color = hex;
              if (id === 'introStartTextColor')
                self.params.intro_start_text_color = hex;
              if (id === 'introStartBorderColor')
                self.params.intro_start_border_color = hex;
              if (id === 'answerButtonBorderColor')
                self.params.answer_button_border_color = hex;
              if (id === 'answerSelectButtonBorderColor')
                self.params.answer_select_button_border_color = hex;
              if (id === 'nextButtonBorderColor')
                self.params.next_button_border_color = hex;

              break;
            }
          }
        },
      });
    },
    initColorValue() {
      if (this.params.question_bg_type !== 'image') {
        $('#bgColor').minicolors('value', this.params.survey_bg);
      }

      $('#boxColor').minicolors('value', this.params.question_bg_color);
      $('#closeColor').minicolors('value', this.params.close_button_color);
      $('#qColor').minicolors('value', this.params.progress_bar_color);
      $('#qStepColor').minicolors('value', this.params.question_step_color);
      $('#qTitleColor').minicolors('value', this.params.question_text_color);
      $('#inputFieldColor').minicolors('value', this.params.answer_color);
      $('#inputCheckColor').minicolors(
        'value',
        this.params.answer_checkbox_color,
      );
      $('#btnBgColor').minicolors('value', this.params.answer_button_color);
      $('#btnTextColor').minicolors('value', this.params.answer_text_color);
      $('#btnSelectBgColor').minicolors(
        'value',
        this.params.answer_select_button_color,
      );
      $('#btnSelectTextColor').minicolors(
        'value',
        this.params.answer_select_text_color,
      );
      $('#btnNextBgColor').minicolors('value', this.params.next_button_color);
      $('#btnNextTextColor').minicolors(
        'value',
        this.params.next_button_text_color,
      );
      $('#totalUserColor').minicolors('value', this.params.intro_count_color);
      $('#startBtnColor').minicolors('value', this.params.intro_start_color);

      $('#introStartTextColor').minicolors(
        'value',
        this.params.intro_start_text_color,
      );
      $('#introStartBorderColor').minicolors(
        'value',
        this.params.intro_start_border_color,
      );
      $('#answerButtonBorderColor').minicolors(
        'value',
        this.params.answer_button_border_color,
      );
      $('#answerSelectButtonBorderColor').minicolors(
        'value',
        this.params.answer_select_button_border_color,
      );
      $('#nextButtonBorderColor').minicolors(
        'value',
        this.params.next_button_border_color,
      );
    },
    initDefaultInfo() {
      this.params.survey_config_id = sessionStorage.getItem(
        'survey_survey_config_id',
      );
      this.params.permission = 'anonymous';
      this.params.permitted_group_no = '';
      this.params.survey_align = 'left';
      this.params.question_bg_color = '';
      this.params.close_button = 'none';
      this.params.question_bg_type = 'color';
      this.params.survey_bg = '';
      this.params.back_button = 'none';
      this.params.button_shape = 'first';
      this.params.close_button_color = '';
      this.params.progress_bar_color = '';
      this.params.question_step_color = '';
      this.params.question_text_color = '';
      this.params.answer_color = '';
      this.params.answer_checkbox_color = '';
      this.params.answer_button_color = '';
      this.params.answer_text_color = '';
      this.params.answer_button_width = 100;
      this.params.answer_select_button_color = '';
      this.params.answer_select_text_color = '';
      this.params.answer_button_border_color = '';
      this.params.answer_select_button_border_color = '';
      this.params.next_button_color = '';
      this.params.next_button_text_color = '';
      this.params.next_button_width = 100;
      this.params.next_button_border_color = 100;
      this.params.intro_status = 'enabled';
      this.params.intro_desktop_bg = '';
      this.params.intro_mobile_bg = '';
      this.params.intro_count_display = '';
      this.params.intro_count_display_status = 'disabled';
      this.params.intro_count_color = '';
      this.params.intro_start_text_color = '';
      this.params.intro_start_border_color = '';
      (this.params.intro_type1_text1 = '이미'),
        (this.params.intro_type1_text2 = '명이 추천받았습니다.'),
        (this.params.intro_type2_text1 = '명이 추천받았습니다.'),
        (this.params.intro_start_text = '시작하기');
      this.params.intro_start_color = '';
      this.params.question_type = 'none';
      this.params.result_item = '';
      this.params.result_loading_text = '';
      this.params.result_progress_bar_color = '';
      this.params.result_desktop_config = '';
      this.params.result_mobile_config = '';
      this.params.answer_text_width = 740;
      this.params.answer_dropdown_width = 740;
    },

    getDefaultSetting: async function () {
      // 사용자 접근 권한
      this.params.permission = 'anonymous';
      // 화면 배경 SELECT
      this.params.question_bg_type = 'color';
      // 화면 배경 > 색상
      this.params.survey_bg = '#fafafa';
      // 질문 박스 배경 색상
      this.params.question_bg_color = '#ffffff';
      // 화면 닫기 버튼
      this.params.close_button = 'first';
      // 화면 닫기 버튼
      this.params.back_button = 'first';
      // 전체 버튼 모양
      this.params.button_shape = 'first';
      // 닫기 및 뒤로가기 버튼 색상
      this.params.close_button_color = '#343434';
      // 질문 진행 프로그레스 색상
      this.params.progress_bar_color = '#ff5c35';
      // 질문 단계명 텍스트 색상
      this.params.question_step_color = '#f0bf4d';
      // 질문 내역 텍스트 색상
      this.params.question_text_color = '#333333';
      // 답변 색상 (텍스트필드, 드롭다운)
      this.params.answer_color = '#d7d7d7';
      // 답변 색상 (텍스트필드, 드롭다운) 가로사이즈
      this.params.answer_button_width = 740;
      // 답변 색상 (체크박스, 라디오버튼)
      this.params.answer_checkbox_color = '#333333';
      // 답변 색상 (버튼, 파일첨부) > 기본 버튼색상
      this.params.answer_button_color = '#d7d7d7';
      // 답변 색상 (버튼, 파일첨부) > 기본 텍스트색상
      this.params.answer_text_color = '#333333';
      // 답변 색상 (버튼, 파일첨부) > 선택 버튼색상
      this.params.answer_select_button_color = '#ff5c35';
      // 답변 색상 (버튼, 파일첨부) > 선택 텍스트색상
      this.params.answer_select_text_color = '#ffffff';
      // 하단 버튼 색상 (다음단계 클릭) > 버튼색상
      this.params.next_button_color = '#333333';
      // 하단 버튼 색상 (다음단계 클릭) > 텍스트색상
      this.params.next_button_text_color = '#ffffff';
      // 하단 버튼 색상 (다음단계 클릭) > 가로사이즈
      this.params.next_button_width = 740;
      // 인트로 화면 사용 > 배경이미지 > PC
      this.params.intro_desktop_bg_path =
        location.origin + '/image/pc_intro_image.png';

      let pc_intro_image_files = await this.urlImageFile(
        this.params.intro_desktop_bg_path,
      );
      const pc_intro_image_result = await this.uploadImage(
        pc_intro_image_files,
      );
      this.params.intro_desktop_bg = pc_intro_image_result;

      // 인트로 화면 사용 > 배경이미지 > 모바일
      this.params.intro_mobile_bg_path =
        location.origin + '/image/mobile_intro_image.png';
      let mobile_intro_image_files = await this.urlImageFile(
        this.params.intro_mobile_bg_path,
      );
      const mobile_intro_image_result = await this.uploadImage(
        mobile_intro_image_files,
      );
      this.params.intro_mobile_bg = mobile_intro_image_result;
      // 총 사용자 수 노출 > 3번째 선택
      this.params.intro_count_display_status = 'enabled_type2';
      // 총 사용자 수 노출 > 3번째 텍스트
      this.params.intro_type2_text1 = '명이 참여하였습니다.';

      // 총 사용자 수 노출 > 2번째 텍스트 빈값 세팅
      this.params.intro_type1_text1 = null;
      this.params.intro_type1_text2 = null;

      // 인트로 화면 사용 > 총 사용자 수 색상
      this.params.intro_count_color = '#f0bf4d';
      // 인트로 화면 사용 > 시작하기 버튼 색상
      this.params.intro_start_color = '#333333';

      // 인트로 화면 사용 > 시작하기 버튼명 색상
      this.params.intro_start_text_color = '#ffffff';
      // 인트로 화면 사용 > 시작하기 버튼 테두리 색상
      this.params.intro_start_border_color = '#333333';
      // 답변 설정 > 기본 버튼 테두리 색상
      this.params.answer_button_border_color = '#d7d7d7';
      // 답변 설정 > 선택 버튼 테두리 색상
      this.params.answer_select_button_border_color = '#ff5c35';
      // 하단 버튼 > 버튼 테두리 색상
      this.params.next_button_border_color = '#333333';
    },

    urlImageFile: async function (url) {
      const response = await fetch(url);
      const data = await response.blob();
      const ext = url.split('.').pop();
      const filename = url.split('/').pop();
      const metadata = { type: `image/${ext}` };
      return new File([data], filename, metadata);
    },

    getConfigInfo: async function () {
      await this.$store.dispatch('qna_config/getConfig');

      if (this.qna_config == null || this.qna_config == undefined) {
        return;
      }

      if (
        (this.qna_config.survey_bg == '#' ||
          this.qna_config.survey_bg == null) &&
        (this.qna_config.question_bg_color == '#' ||
          this.qna_config.question_bg_color == null)
      ) {
        this.getDefaultSetting();
        this.initColorValue();
        return;
      }

      let display_data = JSON.parse(this.qna_config.intro_count_display);
      this.qna_config.permitted_group_text = JSON.parse(
        this.qna_config.permitted_group_no,
      );

      for (const [key, item] of Object.entries(this.membership_list)) {
        if (
          this.qna_config.permitted_group_text &&
          this.qna_config.permitted_group_text.findIndex(
            obj => obj.group_no === item.group_no,
          ) !== -1
        ) {
          this.membership_list[key].checked = true;
        }
      }
      if (display_data && display_data.status) {
        this.qna_config.intro_count_display_status = display_data.status;
      }

      if (
        display_data &&
        display_data.status &&
        display_data.status == 'enabled_type1'
      ) {
        this.qna_config.intro_type1_text1 = display_data.text1;
        this.qna_config.intro_type1_text2 = display_data.text2;
      } else if (
        display_data &&
        display_data.status &&
        display_data.status == 'enabled_type2'
      ) {
        this.qna_config.intro_type2_text1 = display_data.text1;
      }

      this.qna_config.question_bg_type = 'color';
      if (this.isLink(this.qna_config.survey_bg) === true) {
        this.qna_config.question_bg_type = 'image';
      }

      this.params = this.qna_config;
      this.initColorValue();

      this.params.question_bg_image_path = '';

      if (this.params.question_bg_type === 'image') {
        this.params.question_bg_image = this.params.survey_bg;
        this.params.question_bg_image_path = this.params.survey_bg;
      }

      this.params.intro_desktop_bg_path = this.params.intro_desktop_bg;
      this.params.intro_mobile_bg_path = this.params.intro_mobile_bg;

      this.$store.commit('common/setSurveyConfigId', this.params.id);

      if (
        !this.params.answer_button_width ||
        this.params.answer_button_width < 100
      ) {
        this.params.answer_button_width = 100;
      }
      if (
        !this.params.next_button_width ||
        this.params.next_button_width < 100
      ) {
        this.params.next_button_width = 100;
      }

      if (!this.params.button_shape) {
        this.params.button_shape = 'first';
      }
    },
    checkForm: async function () {
      if (this.params.question_bg_type === 'image') {
        this.params.survey_bg = this.params.question_bg_image_path;
      }

      this.params.intro_count_display = {
        status: this.params.intro_count_display_status,
        text1: '',
        text2: '',
      };

      if (
        (this.params.question_bg_type === 'color' &&
          (this.params.survey_bg === '' || this.params.survey_bg === '#')) ||
        this.params.question_bg_color === '' ||
        this.params.question_bg_color === '#' ||
        this.params.close_button_color === '' ||
        this.params.close_button_color === '#' ||
        this.params.progress_bar_color === '' ||
        this.params.progress_bar_color === '#' ||
        this.params.question_step_color === '' ||
        this.params.question_step_color === '#' ||
        this.params.question_text_color === '' ||
        this.params.question_text_color === '#' ||
        this.params.answer_color === '' ||
        this.params.answer_color === '#' ||
        this.params.answer_checkbox_color === '' ||
        this.params.answer_checkbox_color === '#' ||
        this.params.answer_button_color === '' ||
        this.params.answer_button_color === '#' ||
        this.params.answer_text_color === '' ||
        this.params.answer_text_color === '#' ||
        this.params.answer_select_button_color === '' ||
        this.params.answer_select_button_color === '#' ||
        this.params.answer_select_text_color === '' ||
        this.params.answer_select_text_color === '#' ||
        this.params.next_button_color === '' ||
        this.params.next_button_color === '#' ||
        this.params.next_button_text_color === '' ||
        this.params.next_button_text_color === '#' ||
        this.params.answer_button_border_color === '' ||
        this.params.answer_button_border_color === '#' ||
        this.params.answer_select_button_border_color === '' ||
        this.params.answer_select_button_border_color === '#' ||
        this.params.next_button_border_color === '' ||
        this.params.next_button_border_color === '#'
      ) {
        alert(
          '기본 디자인 설정에서 미입력된 항목이 있습니다.\n기입/선택하신 후 저장해주세요.',
        );

        if (
          this.params.question_bg_type === 'color' &&
          (this.params.survey_bg === '' || this.params.survey_bg === '#')
        )
          this.$refs.survey_bg.focus();
        else if (
          this.params.question_bg_color === '' ||
          this.params.question_bg_color === '#'
        )
          this.$refs.question_bg_color.focus();
        else if (
          this.params.close_button_color === '' ||
          this.params.close_button_color === '#'
        )
          this.$refs.close_button_color.focus();
        else if (
          this.params.progress_bar_color === '' ||
          this.params.progress_bar_color === '#'
        )
          this.$refs.progress_bar_color.focus();
        else if (
          this.params.question_step_color === '' ||
          this.params.question_step_color === '#'
        )
          this.$refs.question_step_color.focus();
        else if (
          this.params.question_text_color === '' ||
          this.params.question_text_color === '#'
        )
          this.$refs.question_text_color.focus();
        else if (
          this.params.answer_color === '' ||
          this.params.answer_color === '#'
        )
          this.$refs.answer_color.focus();
        else if (
          this.params.answer_checkbox_color === '' ||
          this.params.answer_checkbox_color === '#'
        )
          this.$refs.answer_checkbox_color.focus();
        else if (
          this.params.answer_button_color === '' ||
          this.params.answer_button_color === '#'
        )
          this.$refs.answer_button_color.focus();
        else if (
          this.params.answer_button_border_color === '' ||
          this.params.answer_button_border_color === '#'
        )
          this.$refs.answer_button_border_color.focus();
        else if (
          this.params.answer_text_color === '' ||
          this.params.answer_text_color === '#'
        )
          this.$refs.answer_text_color.focus();
        else if (
          this.params.answer_select_button_color === '' ||
          this.params.answer_select_button_color === '#'
        )
          this.$refs.answer_select_button_color.focus();
        else if (
          this.params.answer_select_button_border_color === '' ||
          this.params.answer_select_button_border_color === '#'
        )
          this.$refs.answer_select_button_border_color.focus();
        else if (
          this.params.answer_select_text_color === '' ||
          this.params.answer_select_text_color === '#'
        )
          this.$refs.answer_select_text_color.focus();
        else if (
          this.params.next_button_color === '' ||
          this.params.next_button_color === '#'
        )
          this.$refs.next_button_color.focus();
        else if (
          this.params.next_button_border_color === '' ||
          this.params.next_button_border_color === '#'
        )
          this.$refs.next_button_border_color.focus();
        else if (
          this.params.next_button_text_color === '' ||
          this.params.next_button_text_color === '#'
        )
          this.$refs.next_button_text_color.focus();

        return false;
      } else if (this.params.intro_status === 'enabled') {
        let intro_start_text_length = this.params.intro_start_text.trim();
        if (
          this.params.intro_desktop_bg === '' ||
          this.params.intro_mobile_bg === ''
        ) {
          alert('배경이미지를 등록해주세요.');
          return false;
        } else if (
          this.params.intro_start_text === '' ||
          intro_start_text_length == 0 ||
          this.params.intro_count_color === '' ||
          this.params.intro_count_color === '#' ||
          this.params.intro_start_color === '' ||
          this.params.intro_start_color === '#' ||
          this.params.intro_start_text_color === '' ||
          this.params.intro_start_text_color === '#' ||
          this.params.intro_start_border_color === '' ||
          this.params.intro_start_border_color === '#'
        ) {
          alert(
            '기본 디자인 설정에서 미입력된 항목이 있습니다.\n기입/선택하신 후 저장해주세요.',
          );

          if (
            this.params.intro_start_text === '' ||
            intro_start_text_length == 0
          ) {
            this.params.intro_start_text = '';
            this.$refs.intro_start_text.focus();
          } else if (
            this.params.intro_count_color === '' ||
            this.params.intro_count_color === '#'
          ) {
            this.$refs.intro_count_color.focus();
          } else if (
            this.params.intro_start_color === '' ||
            this.params.intro_start_color === '#'
          ) {
            this.$refs.intro_start_color.focus();
          } else if (
            this.params.intro_start_text_color === '' ||
            this.params.intro_start_text_color === '#'
          ) {
            this.$refs.intro_start_text_color.focus();
          } else if (
            this.params.intro_start_border_color === '' ||
            this.params.intro_start_border_color === '#'
          ) {
            this.$refs.intro_start_border_color.focus();
          }

          return false;
        }
      }

      if (
        (this.params.answer_button_width !== 0 &&
          this.params.answer_button_width < 100) ||
        (this.params.next_button_width !== 0 &&
          this.params.next_button_width < 100)
      ) {
        alert('버튼 가로사이즈는 100~740까지 입력 가능합니다.');
        return false;
      }

      if (this.params.intro_count_display_status == 'enabled_type1') {
        this.params.intro_count_display['text1'] =
          this.params.intro_type1_text1;
        this.params.intro_count_display['text2'] =
          this.params.intro_type1_text2;
      } else if (this.params.intro_count_display_status == 'enabled_type2') {
        this.params.intro_count_display['text1'] =
          this.params.intro_type2_text1;
      }

      this.params.intro_count_display = JSON.stringify(
        this.params.intro_count_display,
      );
      this.params.permitted_group_no = JSON.stringify(
        this.params.permitted_group_text,
      );

      let image = {};

      if (
        this.params.question_bg_type === 'image' &&
        (this.params.question_bg_image === '' ||
          this.params.question_bg_image === undefined)
      ) {
        alert('배경이미지를 등록해주세요.');
        return false;
      } else if (
        this.params.question_bg_type === 'image' &&
        this.params.question_bg_image !== '' &&
        this.params.question_bg_image.indexOf('http') == -1
      ) {
        //제품 이미지 업로드
        image = {
          image: this.params.question_bg_image,
        };

        this.params.survey_bg = await this.$store.dispatch(
          'common/postImage',
          image,
        );
        this.params.question_bg_image_path = this.params.survey_bg;
      }

      if (
        this.params.intro_desktop_bg !== '' &&
        this.params.intro_desktop_bg.indexOf('http') == -1
      ) {
        //제품 이미지 업로드
        image = {
          image: this.params.intro_desktop_bg,
        };

        this.params.intro_desktop_bg = await this.$store.dispatch(
          'common/postImage',
          image,
        );
        this.params.intro_desktop_bg_path = this.params.intro_desktop_bg;
      }

      if (
        this.params.intro_mobile_bg !== '' &&
        this.params.intro_mobile_bg.indexOf('http') == -1
      ) {
        //제품 이미지 업로드
        image = {
          image: this.params.intro_mobile_bg,
        };

        this.params.intro_mobile_bg = await this.$store.dispatch(
          'common/postImage',
          image,
        );
        this.params.intro_mobile_bg_path = this.params.intro_mobile_bg;
      }

      const result = await this.$store.dispatch(
        'qna_config/postData',
        this.params,
      );

      if (result === true) {
        alert('저장되었습니다.');
        await this.getConfigInfo();
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
    setMembershipGroup() {
      this.params.permitted_group_text = [];
      for (let i in this.membership_list) {
        if (this.membership_list[i].checked) {
          this.params.permitted_group_text.push({
            group_no: this.membership_list[i].group_no,
            group_name: this.membership_list[i].group_name,
          });
        }
      }

      this.isOpenMemberLayer = false;
    },
    allChecked(checked) {
      this.all_selected = checked;

      for (let i in this.membership_list) {
        this.membership_list[i].checked = this.all_selected;
      }
    },
    changeBackgroundImage: async function (event) {
      this.params.question_bg_image_path = event.target.value;
      const result = await this.uploadImage(event.target.files[0]);
      if (!result) {
        event.target.value = '';
        this.params.question_bg_image_path = '';
      }
      this.params.question_bg_image = result;
    },
    changeIntroDesktopImage: async function (event) {
      this.params.intro_desktop_bg_path = event.target.value;
      const result = await this.uploadImage(event.target.files[0]);
      if (!result) {
        event.target.value = '';
        this.params.intro_desktop_bg_path = '';
      }
      this.params.intro_desktop_bg = result;
    },
    changeIntroMobileImage: async function (event) {
      this.params.intro_mobile_bg_path = event.target.value;
      const result = await this.uploadImage(event.target.files[0]);
      if (!result) {
        event.target.value = '';
        this.params.intro_mobile_bg_path = '';
      }
      this.params.intro_mobile_bg = result;
    },
    clearImage(id) {
      if (id === 'background') {
        this.params.question_bg_image_path = '';
        this.params.question_bg_image = '';
      } else if (id === 'intro_desktop') {
        this.params.intro_desktop_bg_path = '';
        this.params.intro_desktop_bg = '';
      } else if (id === 'intro_mobile') {
        this.params.intro_mobile_bg_path = '';
        this.params.intro_mobile_bg = '';
      }
    },
    showImagePreview(id) {
      let preview_image = '';

      if (id === 'background') preview_image = this.params.question_bg_image;
      else if (id === 'intro_desktop')
        preview_image = this.params.intro_desktop_bg;
      else if (id === 'intro_mobile')
        preview_image = this.params.intro_mobile_bg;
      else return alert('Error');

      this.$vfm.show({
        component: ModalImagePreview,
        bind: {
          image: preview_image,
        },
      });
    },
    changeBackgroundType(row, event) {
      // 파일 링크 였던 경우에만 초기화..
      if (
        event.target.value === 'color' &&
        this.isLink(this.params.survey_bg) === true
      ) {
        this.params.survey_bg = '#';
      }
    },
    changePermission: function () {
      // console.log(this.params.permission);
    },
    initMinicolors: function () {
      const timer = setInterval(() => {
        const btn = $('#startBtnColor');
        if (btn) {
          $('#totalUserColor').minicolors({
            animationEasing: 'swing',
            keywords: '#',

            change: (hex, opacity) => {
              const target = $('.minicolors').find('input');
              for (const item of target) {
                if ($(item).val() === hex) {
                  const id = $(item).prop('id');

                  if (id === 'totalUserColor')
                    this.params.intro_count_color = hex;
                  if (id === 'startBtnColor')
                    this.params.intro_start_color = hex;
                  if (id === 'introStartBorderColor')
                    this.params.intro_start_border_color = hex;
                  if (id === 'introStartTextColor')
                    this.params.intro_start_text_color = hex;

                  break;
                }
              }
            },
          });
          $('#startBtnColor').minicolors({
            animationEasing: 'swing',
            keywords: '#',

            change: (hex, opacity) => {
              const target = $('.minicolors').find('input');
              for (const item of target) {
                if ($(item).val() === hex) {
                  const id = $(item).prop('id');

                  if (id === 'totalUserColor')
                    this.params.intro_count_color = hex;
                  if (id === 'startBtnColor')
                    this.params.intro_start_color = hex;
                  if (id === 'introStartBorderColor')
                    this.params.intro_start_border_color = hex;
                  if (id === 'introStartTextColor')
                    this.params.intro_start_text_color = hex;

                  break;
                }
              }
            },
          });
          $('#introStartBorderColor').minicolors({
            animationEasing: 'swing',
            keywords: '#',

            change: (hex, opacity) => {
              const target = $('.minicolors').find('input');
              for (const item of target) {
                if ($(item).val() === hex) {
                  const id = $(item).prop('id');

                  if (id === 'totalUserColor')
                    this.params.intro_count_color = hex;
                  if (id === 'startBtnColor')
                    this.params.intro_start_color = hex;
                  if (id === 'introStartBorderColor')
                    this.params.intro_start_border_color = hex;
                  if (id === 'introStartTextColor')
                    this.params.intro_start_text_color = hex;

                  break;
                }
              }
            },
          });
          $('#introStartTextColor').minicolors({
            animationEasing: 'swing',
            keywords: '#',

            change: (hex, opacity) => {
              const target = $('.minicolors').find('input');
              for (const item of target) {
                if ($(item).val() === hex) {
                  const id = $(item).prop('id');

                  if (id === 'totalUserColor')
                    this.params.intro_count_color = hex;
                  if (id === 'startBtnColor')
                    this.params.intro_start_color = hex;
                  if (id === 'introStartBorderColor')
                    this.params.intro_start_border_color = hex;
                  if (id === 'introStartTextColor')
                    this.params.intro_start_text_color = hex;

                  break;
                }
              }
            },
          });
          clearInterval(timer);
        }
      }, 100);
    },
    onlyNumber: function (event, key) {
      let value = event.target.value.replace(/[^0-9.]/g, '');
      if (value === '') {
        value = 0;
      }

      value = parseInt(value);

      if (value <= 100) {
        value = 100;
      } else if (value >= 740) {
        value = 740;
      }

      if (key === 'answer') this.params.answer_button_width = value;
      else if (key === 'next') this.params.next_button_width = value;
      else if (key === 'answer_text') this.params.answer_text_width = value;
      else if (key === 'answer_dropdown')
        this.params.answer_dropdown_width = value;

      event.target.value = value;
    },
    onlyHtmlColor: function (event, key) {
      const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      if (korean.test(event.target.value)) {
        alert('한글은 입력 할 수 없습니다.');
        value = '#';
      }

      let value = event.target.value.replace(/[^#0-9a-zA-Z]/g, '');
      value = '#' + value.replace(/#/g, '');

      if (value.length == 7) {
        let regex = /^#(?:[0-9a-f]{3}){1,2}$/i;
        if (!regex.test(value)) {
          alert('올바른 색상코드가 아닙니다.');
          value = '#';
        }
      }
      if (key === 'survey_bg') this.params.survey_bg = value;
      else if (key === 'question_bg_color')
        this.params.question_bg_color = value;
      else if (key === 'close_button_color')
        this.params.close_button_color = value;
      else if (key === 'progress_bar_color')
        this.params.progress_bar_color = value;
      else if (key === 'question_step_color')
        this.params.question_step_color = value;
      else if (key === 'question_text_color')
        this.params.question_text_color = value;
      else if (key === 'answer_color') this.params.answer_color = value;
      else if (key === 'answer_checkbox_color')
        this.params.answer_checkbox_color = value;
      else if (key === 'answer_button_color')
        this.params.answer_button_color = value;
      else if (key === 'answer_text_color')
        this.params.answer_text_color = value;
      else if (key === 'answer_select_button_color')
        this.params.answer_select_button_color = value;
      else if (key === 'answer_select_text_color')
        this.params.answer_select_text_color = value;
      else if (key === 'next_button_color')
        this.params.next_button_color = value;
      else if (key === 'next_button_text_color')
        this.params.next_button_text_color = value;
      else if (key === 'intro_count_color')
        this.params.intro_count_color = value;
      else if (key === 'intro_start_color')
        this.params.intro_start_color = value;
      else if (key === 'intro_start_text_color')
        this.params.intro_start_text_color = value;
      else if (key === 'intro_start_border_color')
        this.params.intro_start_border_color = value;

      event.target.value = value;
    },
    syncGroup: function () {
      let checked_count = 0;

      for (const [key, item] of Object.entries(this.membership_list)) {
        if (this.membership_list[key].checked === true) {
          checked_count++;
        }
      }

      if (this.membership_list.length === checked_count) {
        this.all_checked = true;
      } else {
        this.all_checked = false;
      }
    },

    inputText: function (e) {
      let value = this.replaceHtml(e.target.value);
      if (e.target.id == 'intro_type1_text1') {
        this.params.intro_type1_text1 = value;
      }
      if (e.target.id == 'intro_type1_text2') {
        this.params.intro_type1_text2 = value;
      }
      if (e.target.id == 'intro_type2_text1') {
        this.params.intro_type2_text1 = value;
      }
      if (e.target.id == 'intro_start_text') {
        this.params.intro_start_text = value;
      }
    },
    fileClick: function (e) {
      $(e.target).next('input').click();
    },
    previewTip: function (type, device) {
      if (device == 'pc') {
        if (type === 1) {
          this.$refs.preview_tip.style.display = 'block';
        } else {
          this.$refs.preview_tip.style.display = 'none';
        }
      }
    },
    previewOpen: async function () {
      let _baseUrl = this.getBaseUrl();

      window.open('http://' + _baseUrl + '/survey.html');
    },
    getBaseUrl: function () {
      const shop_no = sessionStorage.getItem('survey_shop_no');
      const mall_list = this.$store.state.common.mall_list;
      const index = mall_list.findIndex(
        obj => Number(obj.shop_no) === Number(shop_no),
      );
      if (index !== -1) {
        return mall_list[index].primary_domain;
      }

      return '';
    },
    colorHexCheck: function (value) {
      console.log(value);
    },
  },
  watch: {
    'params.intro_status': {
      handler(val) {
        if (val === 'enabled') {
          this.initMinicolors();
        }
      },
    },
    isOpenMemberLayer: function (val) {
      if (val === true) {
        let checked_count = 0;

        for (const [key, item] of Object.entries(this.membership_list)) {
          if (
            this.qna_config.permitted_group_text.findIndex(
              obj => obj.group_no === item.group_no,
            ) !== -1
          ) {
            this.membership_list[key].checked = true;
            checked_count++;
          } else {
            this.membership_list[key].checked = false;
          }
        }

        if (this.membership_list.length === checked_count) {
          this.all_checked = true;
        } else {
          this.all_checked = false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#layer_addshop {
  top: 60px;
  left: 0;
  width: 300px;
}
#layer_grade {
  top: 35px;
  left: 0;
  width: 300px;
}
#layer_apps {
  top: 60px;
  right: 10px;
  width: 450px;
}

.layer_group {
  margin-left: 4px;
}
.input_list > li {
  margin-left: 54px;
}
.input_list > li:nth-child(1) {
  margin-left: 0px;
}

#user_count_status > li {
  margin-left: 0px;
}

.input_wrap {
  margin-left: 4px;
}
.upload_view {
  margin-left: 4px;
}
.btn_del {
  margin-left: 4px;
}

.input_hidden {
  width: 63px;
  z-index: -1;
}

.pc_result_btn {
  margin-bottom: 100px;
}

.tip3 {
  display: none;
  position: absolute;
  right: 236px;
  padding: 14px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-top: 12px;
}

.dropDownWidthMargin {
  margin-left: 13px;
}

.maxWidth240 {
  max-width: 240px;
}

.minWidth170 {
  min-width: 170px;
}

.option dl dt {
  width: 200px;
}

.option dl dt.narrow {
  width: 120px;
}

.color .minicolors {
  width: 100px;
}

.buttonShape1 {
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
}
.buttonShape2 {
  padding: 0px 10px 0px 10px;
  border-radius: 30px;
}
.buttonShape3 {
  padding: 0px 10px 0px 10px;
  border-radius: 0px;
}
</style>
