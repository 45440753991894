import { createRouter, createWebHistory } from 'vue-router';

//import App from '@/App.vue';
//import Home from '../views/Home.vue';
import About from '../views/About.vue';

import Mall from '../views/mall/Mall.vue';
import MallPc from '../views/mall/MallPc.vue';
import MallMobile from '../views/mall/MallMobile.vue';

import Qna from '../views/qna/Qna.vue';
import QnaConfig from '../views/qna/QnaConfig.vue';
import QnaProduct from '../views/qna/QnaProduct.vue';
import QnaSetting from '../views/qna/QnaSetting.vue';
import QnaResult from '../views/qna/QnaResult.vue';

import Stats from '../views/stats/Stats.vue';
import StatsRecommend from '../views/stats/StatsRecommend.vue';
import StatsUser from '../views/stats/StatsUser.vue';
import StatsChoice from '../views/stats/StatsChoice.vue';

import Addition from '../views/addition/Addition.vue';
import Payment from '../views/payment/Payment.vue';

const routes = [
  {
    path: '/',
    redirect: '/stats/user',
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/mall',
    redirect: '/mall/pc',
    component: Mall,
    children: [
      {
        path: 'pc',
        name: 'MallPc',
        component: MallPc,
      },
      {
        path: 'mobile',
        name: 'MallMobile',
        component: MallMobile,
      },
    ],
  },
  {
    path: '/qna',
    redirect: '/qna/product',
    component: Qna,
    children: [
      {
        path: 'config',
        name: 'QnaConfig',
        component: QnaConfig,
      },
      {
        path: 'product',
        name: 'QnaProduct',
        component: QnaProduct,
      },
      {
        path: 'setting',
        name: 'QnaSetting',
        component: QnaSetting,
      },
      {
        path: 'result',
        name: 'QnaResult',
        component: QnaResult,
      },
    ],
  },
  {
    path: '/stats',
    redirect: '/stats/user',
    component: Stats,
    children: [
      {
        path: 'recommend',
        name: 'StatsRecommend',
        component: StatsRecommend,
      },
      {
        path: 'user',
        name: 'StatsUser',
        component: StatsUser,
      },
      {
        path: 'choice',
        name: 'StatsChoice',
        component: StatsChoice,
      },
    ],
  },
  {
    path: '/addition',
    name: 'Addition',
    component: Addition,
  },
  {
    path: '/survey/payment',
    name: 'Payment',
    component: Payment,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
