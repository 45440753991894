<template>
  <MallPc />
</template>

<script>
// @ is an alias to /src
import MallPc from '@/components/mall/MallPc.vue';

export default {
  components: {
    MallPc,
  },
};
</script>
