<template>
  <div>
    <!-- fullscreen : 결과화면 노출항목 설정 -->
    <section
      id="fullRecomResult"
      class="fullscreen"
      :class="{ upscreen: this.is_open }"
    >
      <div class="full_header">
        <h2>결과화면 노출항목 설정</h2>
        <button type="button" class="btn_close" @click="hidePopup">
          <span class="material-icons md-white">close</span>
        </button>
        <button type="button" class="btn_close2" @click="hidePopup">
          <span>닫기</span>
        </button>
      </div>

      <div class="full_content">
        <h3 class="tit">결과화면 노출항목 설정</h3>
        <p>
          추천 상품에 이미지, 텍스트 항목을 추가하여 자유롭게 정보를 등록할 수
          있습니다.<br />
          Step4. 결과 화면 설정에서 해당 정보를 선택/매칭하여 노출할 수
          있습니다.
        </p>

        <div class="conbox">
          <div class="clfix mb10">
            <div class="fl_l">
              <button
                type="button"
                name=""
                class="btn btn_small btn_secondary"
                @click="itemDelete"
              >
                선택 정보 삭제
              </button>
            </div>
            <div class="fl_r">
              <button
                type="button"
                class="btn btn_small btn_dark"
                @click="showModal('image')"
              >
                <span>이미지 항목 추가 +</span>
              </button>
              <button
                type="button"
                class="btn btn_small btn_dark ml5"
                @click="showModal('text')"
              >
                <span>텍스트 항목 추가+</span>
              </button>
            </div>
          </div>

          <table class="tbl_list">
            <caption>
              결과화면 노출항목 설정
            </caption>
            <colgroup>
              <col style="width: 4%" />
              <col style="width: 10%" />
              <col style="width: auto" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    v-model="allcheck"
                    @click="allChecked($event)"
                  />
                </th>
                <th scope="col">구분</th>
                <th scope="col">노출 항목명</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="
                  Array.isArray(result_items) !== true ||
                  result_items.length <= 0
                "
              >
                <td colspan="3" class="nodata">설정된 항목이 없습니다.</td>
              </tr>
              <tr v-for="(item, key) in result_items" :key="key">
                <td>
                  <input
                    type="checkbox"
                    name=""
                    v-model="item.checked"
                    :value="key"
                    @click="checkBoxClick(item.checked, key)"
                  />
                </td>
                <td>{{ item.type === 'text' ? '텍스트' : '이미지' }}</td>
                <td class="ta_l">
                  <span
                    v-if="item.type === 'text'"
                    @click="showModal('text', key)"
                    class="pointer"
                    >{{ item.title }}</span
                  >
                  <span
                    v-else
                    @click="showModal('image', key)"
                    class="pointer"
                    >{{ item.title }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- //full_content -->
    </section>

    <ModalsContainer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ModalsContainer } from 'vue-final-modal';
import common from '@/components/mixin/index.js';

import ModelQnaProductText from '@/components/qna/modal/ModalQnaProductText.vue';

export default {
  mixins: [common],
  inheritAttrs: false,
  components: [ModalsContainer],
  data: () => ({
    is_open: false,
    result_items: [],
    result_desktop_items: [],
    result_mobile_items: [],
    all_selected: false,
    params: [],
    allcheck: false,
  }),
  props: ['survey_config_info', 'is_result_option_popup'],
  created: async function () {
    this.init();

    if (this.is_result_option_popup !== true) {
      this.hideScrollbar();
    }
  },
  computed: {
    ...mapState('qna_product', ['product_list']),
  },
  mounted() {
    let timer = setTimeout(() => {
      if (this.is_open === true) {
        clearTimeout(timer);
      }

      this.is_open = true;
    }, 100);
  },
  unmounted() {
    if (this.is_open == true) {
      this.hidePopup();
    }
  },
  methods: {
    init: function () {
      if (
        this.survey_config_info.result_item === null ||
        this.survey_config_info.result_item === undefined ||
        this.survey_config_info.result_item === ''
      ) {
        return false;
      }

      this.result_items = JSON.parse(this.survey_config_info.result_item);
      this.result_desktop_items = JSON.parse(
        this.survey_config_info.result_desktop_config,
      );
      this.result_mobile_items = JSON.parse(
        this.survey_config_info.result_desktop_config,
      );
    },
    add: function () {
      //
    },
    itemDelete: async function () {
      let isChecked = false;
      for (const item of this.result_items) {
        if (item.checked === true) {
          isChecked = true;
          break;
        }
      }

      if (isChecked === false) {
        alert('삭제하실 항목을 선택해주세요.');
        return false;
      } else if (
        !confirm(`
선택하신 항목을 삭제하시면
추천 상품에 입력한 노출 항목 내역 (이미지,텍스트) 있는 경우
모두 삭제되므로 확인 후 삭제하시길 권장합니다.

정말 삭제하시겠습니까?`)
      ) {
        alert('취소하였습니다.');
        return false;
      }

      for (let i = this.result_items.length - 1; i >= 0; i--) {
        if (this.result_items[i].checked === true) {
          // result_desktop_config
          // result_mobile_config
          // 위 항목에 추가 되어있던 항목도 삭제한다.
          if (this.result_desktop_items.length !== 0) {
            for (const [key, item] of this.result_desktop_items.entries()) {
              if (item.type === 'show_items') {
                const show_item_index = item.show_items.findIndex(
                  obj =>
                    obj.type === this.result_items[i].type &&
                    obj.text == this.result_items[i].text,
                );
                if (show_item_index !== -1) {
                  this.result_desktop_items[key].show_items.splice(
                    show_item_index,
                    1,
                  );

                  // 항목이 없다면 그냥 지우자.
                  if (this.result_desktop_items[key].show_items.length === 0) {
                    this.result_desktop_items.splice(key, 1);
                  }
                }
              } else if (item.type === 'recommend_goods') {
                if (
                  item.info.show_items === undefined ||
                  item.info.show_items.select_item_list === undefined
                ) {
                  continue;
                }

                const show_item_index =
                  item.info.show_items.select_item_list.findIndex(
                    obj =>
                      obj.type === this.result_items[i].type &&
                      obj.text == this.result_items[i].text,
                  );

                if (show_item_index !== -1) {
                  this.result_desktop_items[
                    key
                  ].info.show_items.select_item_list.splice(show_item_index, 1);
                }
              }
            }
          }
          if (this.result_mobile_items.length !== 0) {
            for (const [key, item] of this.result_mobile_items.entries()) {
              if (item.type === 'show_items') {
                const show_item_index = item.show_items.findIndex(
                  obj =>
                    obj.type === this.result_items[i].type &&
                    obj.text == this.result_items[i].text,
                );
                if (show_item_index !== -1) {
                  this.result_mobile_items[key].show_items.splice(
                    show_item_index,
                    1,
                  );

                  // 항목이 없다면 그냥 지우자.
                  if (this.result_mobile_items[key].show_items.length === 0) {
                    this.result_mobile_items.splice(key, 1);
                  }
                }
              } else if (item.type === 'recommend_goods') {
                if (
                  item.info.show_items === undefined ||
                  item.info.show_items.select_item_list === undefined
                ) {
                  continue;
                }

                const show_item_index =
                  item.info.show_items.select_item_list.findIndex(
                    obj =>
                      obj.type === this.result_items[i].type &&
                      obj.text == this.result_items[i].text,
                  );

                if (show_item_index !== -1) {
                  this.result_mobile_items[
                    key
                  ].info.show_items.select_item_list.splice(show_item_index, 1);
                }
              }
            }
          }

          this.result_items.splice(i, 1);
        }
      }

      this.params = this.survey_config_info;
      this.params.result_item = JSON.stringify(this.result_items);

      const result = await this.$store.dispatch(
        'qna_config/postData',
        this.params,
      );

      if (result === true) {
        alert('삭제되었습니다.');
      } else {
        alert('삭제를 실패하였습니다.');
      }
    },
    deleteResultItem: function () {},
    allChecked(e) {
      if (this.result_items.length == 0) {
        e.target.checked = false;
        alert('선택된 항목이 없습니다.');
        return false;
      }

      this.all_selected = e.target.checked;

      for (let i in this.result_items) {
        this.result_items[i].checked = this.all_selected;
      }
    },
    hidePopup() {
      this.is_open = false;

      if (this.is_result_option_popup !== true) {
        this.showScrollbar();
      }

      this.$emit('hidePopup', 'option');
    },
    showModal(type = String | null, index = Number | -1) {
      this.$vfm.show({
        component: ModelQnaProductText,
        bind: {
          type: type,
          index: index,
          params: this.survey_config_info,
          result_items: this.result_items,
        },
        on: {
          updateResultItems: items => {
            this.result_items = JSON.parse(items);
          },
        },
      });
    },
    checkBoxClick: function (param, index) {
      let total_count = this.result_items.length;
      let check_count = 0;
      for (let i in this.result_items) {
        if (Number(i) == Number(index)) {
          this.result_items[i].checked = !param;
        }
        if (this.result_items[i].checked) {
          check_count += 1;
        }
      }
      if (this.allcheck === true && check_count != total_count) {
        this.allcheck = false;
      }
      if (this.allcheck === false && check_count == total_count) {
        this.allcheck = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upscreen {
  transform: none;
}

.full_content {
  height: unset;
}

.btn_close2 {
  position: absolute;
  top: 13px;
  right: 20px;
  color: #fff;
}
</style>
