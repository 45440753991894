import M from '@/libs/mapping.js';

const actions = {
  async getStatistics({ commit }, params = []) {
    try {
      const result = await M.api(M.entry('/get/stats/user'), params);
      commit('setStatistics', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async getQuestionStatistics({ commit }, params = []) {
    try {
      const result = await M.api(M.entry('/get/stats/user/question'), params);
      commit('setQuestionStatistics', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async getGenderStatistics({ commit }, params = []) {
    try {
      const result = await M.api(M.entry('/get/stats/user/gender'), params);
      commit('setGenderStatistics', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async getAgeStatistics({ commit }, params = []) {
    try {
      const result = await M.api(M.entry('/get/stats/user/age'), params);
      commit('setAgeStatistics', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async post({ commit }, params = []) {
    try {
      const result = await M.api(M.entry('/post'), params);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default actions;
