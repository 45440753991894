export default Object.freeze({
  /**
   * {1} mall_id
   * {2} shop_no
   * {3} survey_config_id
   */
  '/post': {
    path: '/app/survey/mall/{1}/api/survey/front/{2}/hierarchy',
    method: 'POST',
  },
  /**
   * 회원 등급 리스트
   */
  '/get/survey/groups': {
    path: '/app/survey/cafe24/{1}/api/survey/{2}/groups',
    method: 'GET',
  },
  /**
   * 맞춤 질문 설정 > 기본 정보 설정 > 등록
   */
  '/post/survey/config': {
    path: '/app/survey/cafe24/{1}/api/survey/{2}/config',
    method: 'POST',
  },
  /**
   * 맞춤 질문 설정 > 기본 정보 설정 > 상세보기
   */
  '/get/survey/config': {
    path: '/app/survey/cafe24/{1}/api/survey/{2}/config',
    method: 'GET',
  },
  /**
   * 맞춤 질문 설정 > 질문 설정 > 리스트
   */
  '/get/survey/setting': {
    path: '/app/survey/cafe24/{1}/api/survey/{3}/hierarchy',
    method: 'GET',
  },
  /**
   * 맞춤 질문 설정 > 질문 설정 > 등록
   */
  '/post/survey/setting': {
    path: '/app/survey/cafe24/{1}/api/survey/{3}/hierarchy/{4}',
    method: 'POST',
  },
  /**
   * 맞춤 질문 설정 > 질문 설정 > 순서 변경
   */
  '/post/survey/hierarchy/sequence': {
    path: '/app/survey/cafe24/{1}/api/survey/{3}/hierarchy/{4}/sequence',
    method: 'POST',
  },
  /**
   * 맞춤 질문 설정 > 질문 설정 > 추천 상품 연동
   */
  '/post/survey/hierarchy/products': {
    path: '/app/survey/cafe24/{1}/api/survey/{3}/hierarchy/{4}/products',
    method: 'POST',
  },
  /**
   * 맞춤 질문 설정 > 질문 설정 > 삭제
   */
  '/delete/survey/setting': {
    path: '/app/survey/cafe24/{1}/api/survey/{3}/hierarchy/{4}',
    method: 'DELETE',
  },
  /**
   * 추천 상품 리스트
   */
  '/get/survey/shops': {
    path: '/app/survey/cafe24/{1}/api/survey/{2}/shops',
    method: 'GET',
  },
  /**
   * 추천 상품 등록
   */
  '/post/survey/shops': {
    path: '/app/survey/cafe24/{1}/api/survey/shops',
    method: 'POST',
  },
  /**
   * 쇼핑몰 상세 정보
   */
  '/get/mall/store': {
    //path: '/app/survey/cafe24/{1}/api/store',
    path: '/app/survey/cafe24/{1}/api/survey/store',
    method: 'GET',
  },
  /**
   * 쇼핑몰 리스트
   */
  '/get/mall/shops': {
    path: '/app/survey/cafe24/{1}/api/shops',
    method: 'GET',
  },
  /**
   * 상품 리스트
   */
  '/get/survey/products': {
    path: '/app/survey/cafe24/{1}/api/survey/{2}/products',
    method: 'GET',
  },
  /**
   * 상품 리스트 수
   */
  '/get/survey/products/count': {
    path: '/app/survey/cafe24/{1}/api/survey/{2}/products/count',
    method: 'GET',
  },
  /**
   * 추천 상품 통계
   */
  '/get/stats/recommend': {
    path: '/app/survey/mall/{1}/api/survey/{3}/stats/recommend',
    method: 'GET',
  },
  /**
   * 사용자 통계
   */
  '/get/stats/user': {
    path: '/app/survey/mall/{1}/api/survey/{3}/stats/user',
    method: 'GET',
  },
  /**
   * 사용자 통계
   */
  '/get/stats/choice': {
    path: '/app/survey/mall/{1}/api/survey/{3}/stats/choice',
    method: 'GET',
  },
  /**
   * 사용자 통계 (질문)
   */
  '/get/stats/user/question': {
    path: '/app/survey/mall/{1}/api/survey/{3}/stats/user/question',
    method: 'GET',
  },
  /**
   * 사용자 통계 (성별)
   */
  '/get/stats/user/gender': {
    path: '/app/survey/mall/{1}/api/survey/{3}/stats/user/gender',
    method: 'GET',
  },
  /**
   * 사용자 통계 (나이)
   */
  '/get/stats/user/age': {
    path: '/app/survey/mall/{1}/api/survey/{3}/stats/user/age',
    method: 'GET',
  },
  /**
   * 대시보드 조회
   */
  '/get/survey/count/total': {
    path: '/app/survey/mall/{1}/api/survey/count/total',
    method: 'GET',
  },
  /**
   * 연령대비율 조회
   */
  '/get/survey/count/age': {
    path: '/app/survey/mall/{1}/api/survey/count/age',
    method: 'GET',
  },
  /**
   * 계층 순위 조회
   */
  '/get/survey/rank/hierarchy': {
    path: '/app/survey/mall/{1}/api/survey/rank/hierarchy/{2}',
    method: 'GET',
  },
  /**
   * 계층 리스트 조회
   */
  '/get/survey/hierarchy': {
    path: '/app/survey/mall/{1}/api/survey/hierarchy/{2}',
    method: 'GET',
  },
  /**
   * 계층 등록, 수정
   */
  '/post/survey/hierarchy': {
    path: '/app/survey/mall/{1}/api/survey/hierarchy/{2}',
    method: 'POST',
  },
  /**
   * 계층 삭제
   */
  '/delete/survey/hierarchy': {
    path: '/app/survey/mall/{1}/api/survey/hierarchy/{2}',
    method: 'DELETE',
  },
  /**
   * 가장-많이-추천된-제품
   */
  '/get/survey/rank/hierarchy/products': {
    path: '/app/survey/mall/{1}/api/survey/rank/hierarchy/products',
    method: 'GET',
  },
  /**
   * 분석결과
   */
  '/get/survey/analysis': {
    path: '/app/survey/mall/{1}/api/survey/analysis',
    method: 'GET',
  },
  /**
   * 이미지 등록
   */
  '/post/survey/upload/image': {
    path: '/app/survey/mall/{1}/api/survey/upload/image',
    method: 'POST',
  },
  /**
   * 제품 리스트 조회
   */
  '/get/products': {
    path: '/app/survey/mall/{1}/api/survey/{2}/{3}/products',
    method: 'GET',
  },
  /**
   * 제품 리스트 조회
   */
  '/sync/products': {
    path: '/app/survey/mall/{1}/api/survey/{2}/{3}/sync/products',
    method: 'GET',
  },
  /**
   * 제품 상세 조회
   */
  '/get/products/detail': {
    path: '/app/survey/mall/{1}/api/survey/{2}/{3}/products/{4}',
    method: 'GET',
  },
  /**
   * 제품 수정
   */
  '/put/products': {
    path: '/app/survey/mall/{1}/api/survey/{2}/{3}/products/{4}',
    method: 'PUT',
  },
  /**
   * 제품 등록
   */
  '/post/products': {
    path: '/app/survey/mall/{1}/api/survey/{2}/{3}/products',
    method: 'POST',
  },
  /**
   * 제품 삭제
   */
  '/delete/products': {
    path: '/app/survey/mall/{1}/api/survey/{2}/{3}/products/{4}',
    method: 'DELETE',
  },
  /**
   * 결과전송 조회
   */
  '/get/survey/sent': {
    path: '/app/survey/mall/{1}/api/survey/sent/search',
    method: 'GET',
  },
  /**
   * 어드민-결제-타입리스트-조회
   */
  '/get/orders/types': {
    path: '/app/survey/mall/{1}/api/orders/types',
    method: 'GET',
  },
  /**
   * 어드민-결제-리스트-조회
   */
  '/get/payments': {
    path: '/app/survey/mall/{1}/api/payments',
    method: 'GET',
  },
  /**
   * 어드민-결제-관리-입력-정보-조회
   */
  '/get/orders/latest': {
    path: '/app/survey/mall/{1}/api/orders/latest',
    method: 'GET',
  },
  /**
   * 어드민-결제
   */
  '/post/orders': {
    path: '/app/survey/mall/{1}/api/orders',
    method: 'POST',
  },
  /**
   * 토큰 갱신
   */
  '/get/auth/refresh': {
    path: '/app/survey/cafe24/{1}/auth/refresh',
    method: 'GET',
  },
  /**
   * 어드민-만료일-조회
   */
  '/get/survey/expire': {
    path: '/app/survey/mall/{1}/api/survey/{2}/expire',
    method: 'GET',
  },
  /**
   * 스킨 (Desing) 조회
   */
  '/get/survey/skincode': {
    path: '/app/survey/mall/{1}/api/survey/{2}/skincode',
    method: 'GET',
  },

  /**
   * 결제 관리 입력 정보 등록
   */
  '/post/orders/latest': {
    path: '/app/survey/mall/{1}/api/orders/latest',
    method: 'POST',
  },
});
