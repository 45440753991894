<template>
  <QnaProduct />
</template>

<script>
// @ is an alias to /src
import QnaProduct from '@/components/qna/QnaProduct.vue';

export default {
  name: 'qna-recommend',
  components: {
    QnaProduct,
  },
};
</script>
