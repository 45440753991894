const mutations = {
  setStatistics(state, payload) {
    state.stats = payload;
  },
  setQuestionStatistics(state, payload) {
    state.stats.question_stats = payload;
  },
  setGenderStatistics(state, payload) {
    state.stats.gender_stats = payload;
  },
  setAgeStatistics(state, payload) {
    state.stats.age_stats = payload;
  },
};

export default mutations;
