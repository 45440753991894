<template>
  <div>
    <Payment />
  </div>
</template>

<script>
import Payment from '@/components/payment/Payment.vue';

export default {
  data() {
    return {};
  },
  components: {
    Payment,
  },
};
</script>
