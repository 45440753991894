<template>
  <!-- container -->
  <section id="container">
    <div class="content">
      <h2 class="title">결제 관리</h2>
      <p class="dsc">
        맞춤 질문 추천 서비스는 클라우드 호스팅 서비스로 관련 서버/관리
        비용(월)을 결제하여 사용이 가능합니다.
      </p>

      <div class="conbox">
        <h3 class="tit">결제하기</h3>

        <ul class="pay_info">
          <li>
            <input
              type="text"
              name=""
              v-model="payment_mall_info.mall_name"
              placeholder="쇼핑몰명"
            />
          </li>
          <li>
            <input
              type="text"
              name=""
              v-model="payment_mall_info.mall_manager"
              placeholder="담당자명"
            />
          </li>
          <li>
            <input
              type="text"
              name=""
              v-model="payment_mall_info.mall_phone"
              @keydown="replaceNumber($event, 12, false)"
              @keyup="replaceNumber($event, 12, false)"
              placeholder="핸드폰번호"
            />
          </li>
          <li>
            <input
              type="text"
              name=""
              v-model="payment_mall_info.mall_email"
              placeholder="이메일주소"
            />
          </li>
        </ul>
        <ul class="pay_info">
          <li>
            <select name="" v-model="payment_type">
              <option value="" disabled>
                맞춤 질문 추천 서비스 - 개월 수 선택
              </option>
              <option
                v-for="payment_type in payment_type_list"
                :key="payment_type.order_type"
                :value="payment_type.order_type"
              >
                {{ payment_type.order_name }}
              </option>
            </select>
          </li>
          <li>
            <button
              type="button"
              class="btn btn_large btn_primary"
              @click.prevent="postOrders()"
            >
              <span>결제하기</span>
            </button>
          </li>
        </ul>
      </div>

      <div class="conbox">
        <h3 class="tit">결제내역</h3>
        <table class="tbl_list">
          <caption>
            결제일시
          </caption>
          <colgroup>
            <col style="width: 15%" />
            <col style="width: 18%" />
            <col style="width: auto" />
            <col style="width: 10%" />
            <col style="width: 10%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">결제일시</th>
              <th scope="col">주문번호</th>
              <th scope="col">결제내용</th>
              <th scope="col">시작일</th>
              <th scope="col">종료일</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="order_list.length === 0">
              <td colspan="5">조회된 결제 내역이 없습니다.</td>
            </tr>
            <tr v-for="order in order_list" :key="order.order_id">
              <td>{{ order.paid_date }}</td>
              <td>{{ order.order_id }}</td>
              <td>{{ order.order_name }}</td>
              <td>{{ dateFormat(order.start_date) }}</td>
              <td>{{ dateFormat(order.end_date) }}</td>
            </tr>
          </tbody>
        </table>

        <Pagination
          v-if="this.paging.total_count > 0"
          :per_page="this.paging.per_page"
          :total_count="this.paging.total_count"
          @page-change="pageChange"
        />
      </div>
    </div>
    <!-- //content -->
  </section>
  <!-- //container -->
</template>
<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import common from '@/components/mixin/index.js';

import Pagination from '@/components/layout/Pagination.vue';

export default {
  mixins: [common],
  components: {
    Pagination,
  },
  data: () => ({
    isLoading: true,
    fullPage: true,
    payment_type: '',
    mall_name: null,
    mall_manager: null,
    mall_phone: null,
    mall_email: null,
    total_order_count: 0,
    order_list: [],
    paging: {
      offset: 0,
      per_page: 10,
      total_count: 0,
    },
    order_no: 1,
    right_display: false,
    left_display: false,
  }),
  created: async function () {
    await this.getOrderType();
    await this.getOrderLatest();
    await this.getOrderList();

    this.setOrderList();
    // await this.paging();

    await this.getExpire();
  },
  computed: {
    ...mapState('payment', [
      'payment_type_list',
      'payment_list',
      'payment_mall_info',
    ]),
  },
  mounted() {},
  methods: {
    getOrderType: async function () {
      await this.$store.dispatch('payment/getOrderType');
    },
    getOrderLatest: async function () {
      await this.$store.dispatch('payment/getOrderLatest');
    },
    getOrderList: async function () {
      const params = {
        offset: this.paging.offset,
        limit: this.paging.per_page,
      };
      await this.$store.dispatch('payment/getOrderList', params);
    },
    setOrderList: function () {
      this.total_order_count = this.payment_list.payments_count;
      this.paging.total_count = this.payment_list.payments_count;
      this.order_list = this.payment_list.payments;
    },
    pageChange: async function (offset) {
      this.paging.offset = offset;
      await this.getOrderList();
      await this.setOrderList();
    },
    postOrders: async function () {
      if (!this.payment_mall_info.mall_name) {
        alert('쇼핑몰명을 입력해주세요.');
        return;
      }
      if (!this.payment_mall_info.mall_manager) {
        alert('담당자명을 입력해주세요.');
        return;
      }
      if (!this.payment_mall_info.mall_phone) {
        alert('핸드폰번호를 입력해주세요.');
        return;
      }
      if (!this.payment_mall_info.mall_email) {
        alert('이메일주소를 입력해주세요.');
        return;
      }

      let is_phone_number = this.isPhoneNumber(
        this.payment_mall_info.mall_phone,
      );
      if (!is_phone_number || this.payment_mall_info.mall_phone.length >= 12) {
        alert('핸드폰 번호를 확인해주세요.');
        return;
      }

      let is_mail = this.isMail(this.payment_mall_info.mall_email);
      if (!is_mail) {
        alert('이메일주소를 확인해주세요.');
        return;
      }

      let type = Number(this.payment_type);
      if (type == 0) {
        alert('개월 수를 선택해주세요.');
        return;
      }

      const params = {
        mall_order_type: this.payment_type,
        mall_name: this.payment_mall_info.mall_name,
        mall_manager: this.payment_mall_info.mall_manager,
        mall_phone: this.payment_mall_info.mall_phone,
        mall_email: this.payment_mall_info.mall_email,
      };
      const result = await this.$store.dispatch('payment/postOrders', params);
      if (result.confirmation_url) {
        window.location.href = result.confirmation_url;
      }
    },

    dateFormat: function (param) {
      return dayjs(param).format('YYYY-MM-DD');
    },

    isPhoneNumber: function (phone_number) {
      let regExp = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
      let result = regExp.test(phone_number);
      return result;
    },
    isMail: function (mail) {
      let regExp =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
      let result = regExp.test(mail);
      return result;
    },
    replaceNumber: function (e, max, chkNumber = false) {
      let val = e.target.value;
      let replaceVal = val.replace(/[^0-9]/g, '').substr(0, max - 1);
      e.target.value = chkNumber === true ? Number(replaceVal) : replaceVal;
      if (chkNumber === true && e.target.value == 0) {
        e.target.value = '';
      }
      var event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      e.target.dispatchEvent(event);
    },
    left_page: function () {
      this.plus_page_count = this.plus_page_count - this.max_page_count;
      if (this.plus_page_count < 1) {
        this.plus_page_count = 0;
        this.page = 1;
        this.left_display = false;
      }
      // 총페이지 수가 노출할 최대 버튼 수보다 크면 최대 버튼개수 노출
      if (this.total_page_count > this.max_page_count) {
        this.right_display = true;
      }
      let _show_btn = this.page - this.max_page_count;
      if (_show_btn <= 1) {
        this.page = 1;
      } else {
        this.page = _show_btn;
      }

      let _page_count = this.total_page_count - this.max_page_count;
      if (_page_count < this.max_page_count) {
        this.page_count = _page_count;
      } else {
        this.page_count = this.max_page_count;
      }
    },
    right_page: function () {
      this.left_display = true;
      this.plus_page_count = this.plus_page_count + this.max_page_count;
      this.page = this.page + this.max_page_count;

      let _page_count = this.total_page_count - this.max_page_count;
      if (_page_count < this.max_page_count) {
        this.page_count = _page_count;
        this.right_display = false;
      }

      if (_page_count <= this.plus_page_count) {
        this.right_display = false;
        this.page_count = _page_count - this.max_page_count;
      }
    },
    getExpire: async function () {
      await this.$store.dispatch('payment/getExpire');
    },
  },
};
</script>

<style scoped></style>
