const mutations = {
  setMallId(state, mall_id) {
    state.mall_id = mall_id;
    sessionStorage.setItem('mall_id', mall_id);
  },
  setShopNo(state, shop_no) {
    if (shop_no === null || shop_no === undefined) shop_no = 1;
    // state.shop_no = shop_no;
    sessionStorage.setItem('survey_shop_no', shop_no);
  },
  setToken(state, payload) {
    sessionStorage.setItem('access_token', payload.access_token);
    sessionStorage.setItem('refresh_token', payload.refresh_token);
  },
  setProductList(state, payload) {
    state.product_list = payload;
  },
  setSurveyConfigId(state, survey_config_id) {
    // state.survey_config_id = survey_config_id;
    sessionStorage.setItem('survey_survey_config_id', survey_config_id);
  },
  setStore(state, payload) {
    state.store = payload;
  },
  setMallList(state, payload) {
    state.mall_list = payload;
  },
  setSurveyMallList(state, payload) {
    state.survey_mall_list = payload;
  },
  setMembershipGroups(state, payload) {
    state.membership_list = payload;
  },
  setSkinCode(state, payload) {
    state.design_info = payload;
  },
};

export default mutations;
