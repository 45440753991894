<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="showModal"
    :click-to-close="false"
  >
    <!-- layer : 텍스트 항목 추가 -->
    <section id="modalAddText" class="layer_dialog">
      <h2>
        {{ type === 'text' ? '텍스트' : '이미지' }} 항목 추가 - 타이틀 입력
      </h2>
      <p class="txt">관리하실때 인지하기 쉬운 타이틀을 입력해주세요.</p>
      <input type="text" name="" v-model.trim="title" style="width: 100%" />
      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn_large btn_secondary"
              @click="close"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              class="btn btn_large btn_primary"
              @click="add"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 텍스트 항목 추가 -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    showModal: true,
    id: 1,
    title: '',
    items: [],
    config_params: {},
  }),
  props: ['type', 'index', 'params', 'result_items'],
  created: async function () {
    this.init();
  },
  computed: {
    ...mapState('qna_config', ['qna_config']),
  },
  mounted() {},
  methods: {
    init: function () {
      this.items = this.result_items;
      this.config_params = this.params;
      if (this.index > -1) {
        this.title = this.items[this.index].title;
        this.id = this.items[this.index].id;
      } else {
        if (this.items.length == 0) {
          this.id = 1;
        } else {
          this.id = this.items[this.items.length - 1].id + 1;
        }
      }
    },
    add: async function () {
      if (
        this.title === null ||
        this.title === '' ||
        this.title === undefined
      ) {
        alert('타이틀을 입력해주세요.');
        return false;
      } else if (this.title.length >= 50) {
        alert('타이틀은 50자 이내로 입력해주세요.');
        return false;
      }

      // 신규
      if (this.index === -1) {
        this.items.push({
          id: this.id,
          type: this.type,
          title: this.title,
        });
      } else {
        this.items.splice(this.index, 1, {
          id: this.id,
          type: this.type,
          title: this.title,
        });
      }
      this.config_params.result_item = JSON.stringify(this.items);
      const result = await this.$store.dispatch(
        'qna_config/postData',
        this.params,
      );

      if (result === true) {
        this.$emit('updateResultItems', this.config_params.result_item);
        this.showModal = false;
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upscreen {
  transform: none;
}
</style>
