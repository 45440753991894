<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="this.showModal"
    :click-to-close="false"
  >
    <!-- layer : 맞춤 질문 단계 입력 -->
    <section id="modalQnaStep" class="layer_dialog">
      <h2>맞춤 질문 단계 입력</h2>
      <p class="txt">설정하실 맞춤 질문 단계 타이틀을 입력합니다.</p>
      <input
        type="text"
        id="subject"
        v-model.trim="this.subject"
        style="width: 100%"
        placeholder="질문 단계 타이틀 입력"
        @keyup="inputText($event)"
      />
      <div class="btns">
        <ul>
          <li>
            <button
              @click="this.showModal = false"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              v-if="Object.keys(this.params).length > 0"
              @click="submit"
              class="btn btn_large btn_primary"
            >
              수정
            </button>
            <button v-else class="btn btn_large btn_primary" @click="submit">
              저장
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 맞춤 질문 단계 입력 -->
  </vue-final-modal>
</template>

<script>
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    subject: '',
    showModal: true,
  }),
  created: function () {
    this.subject = this.params.subject;
  },
  mounted: function () {
    //
  },
  methods: {
    submit: async function () {
      if (this.subject == undefined || this.subject == '') {
        alert('타이틀을 입력해주세요.');
        return false;
      }
      const params = {
        hierarchy_id: this.params.hierarchy_id || 0,
        hierarchy_type: 'folder',
        depth: this.params.depth || 0,
        sequence: this.params.sequence || 0,
        subject: this.subject,
      };

      const result = await this.$store.dispatch('qna_setting/postData', {
        parent_id: 0, // 최상위..
        parameters: params,
      });

      let success_message = '저장되었습니다.';
      if (this.params.hierarchy_id !== undefined) {
        success_message = '수정되었습니다.';
      }

      if (result === true) {
        alert(success_message);
        this.showModal = false;
      } else {
        alert('저장을 실패하였습니다.');
      }
    },

    inputText: function (e) {
      let value = this.replaceHtml(e.target.value);
      if (e.target.id == 'subject') {
        this.subject = value;
      }
    },
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
