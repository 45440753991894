<template>
  <QnaResult />
</template>

<script>
// @ is an alias to /src
import QnaResult from '@/components/qna/QnaResult.vue';

export default {
  name: 'qna-result',
  components: {
    QnaResult,
  },
};
</script>
