import M from '@/libs/mapping.js';

const actions = {
  async getMallProductCount({ commit }, params) {
    try {
      const result = await M.api(M.entry('/get/survey/products/count'), params);
      commit('setMallProductCount', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async getMallProductList({ commit }, params) {
    try {
      const result = await M.api(M.entry('/get/survey/products'), params);
      commit('setMallProductList', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async getProducts({ commit }, params) {
    try {
      const result = await M.api(M.entry('/get/products'), params);
      commit('setProductList', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async syncProducts({ commit }, params) {
    try {
      const result = await M.api(M.entry('/sync/products'), params);

      if (result.is_deleted) return true;
      else return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async postProducts({ commit }, params) {
    try {
      const result = await M.api(M.entry('/post/products'), params);

      if (result) return true;
      else return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async putProduct({ commit }, params) {
    try {
      const result = await M.api(
        M.entry('/put/products', params.product_id),
        params,
      );

      if (result) return true;
      else return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async deleteProduct({ commit }, params) {
    try {
      const result = await M.api(
        M.entry('/delete/products', params.product_id),
        params,
      );

      console.log(params);

      if (result) return true;
      else return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default actions;
