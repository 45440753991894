<template>
  <!-- container -->
  <section id="container">
    <div class="content">
      <h2 class="title">쇼핑몰 설정</h2>
      <p class="dsc">
        쇼핑몰에 설정하신 맞춤 질문 추천 페이지를 만들어 고객들이 사용할 수 있게
        합니다.<br />
        PC, 모바일 화면을 아래의 가이드에 따라 페이지 생성을 합니다.<br />
        디자인 소스 삽입이 어려우신 분들께는 고객센터로 연락주시면 세팅에 도움을
        드리오니 참고해주세요.<br />
        ※ 에디봇디자인의 경우 HTML 생성이 되지 않으므로 사용이 불가합니다.
        스마트디자인에서 사용이 가능하오니 참고해주세요.
      </p>

      <!-- tab -->
      <ul class="tab">
        <li><router-link to="/mall/pc">PC 화면 만들기</router-link></li>
        <li class="on">
          <router-link to="/mall/mobile">모바일 화면 만들기</router-link>
        </li>
      </ul>
      <!-- //tab -->

      <div class="conbox">
        <h3 class="tit">모바일 쇼핑몰 - '맞춤 질문 추천 서비스' 화면 만들기</h3>
        <p>
          [카페24 어드민 &gt; 모바일쇼핑몰] 메뉴에서 '<strong class="txt_point"
            >쇼핑몰 디자인 수정</strong
          >' 버튼을 클릭합니다.<br />
          클릭하시면 스마트디자인 편집창이 노출되게 됩니다.<br />
          좌측 하단에 위치한 '<strong>+ 화면추가</strong>' 버튼을 클릭해주세요.
        </p>
        <div class="imgbox">
          <img src="/image/m_guide1.jpg" alt="" />
        </div>
      </div>

      <div class="conbox">
        <div class="gbox">
          <p class="txt">
            클릭하시면 아래의 '쇼핑몰 화면 추가' 창이 노출하게 됩니다.<br />파일명을
            입력하는 칸에 아래 영문을 복사하여 넣어주세요.
          </p>
          <div class="btn_r">
            <strong id="survey">survey.html</strong>
            <button
              type="button"
              name=""
              class="btn btn_small btn_secondary"
              @click="copy('survey')"
            >
              복사하기
            </button>
          </div>
        </div>
        <div class="imgbox">
          <img src="/image/m_guide2.jpg" alt="" />
        </div>
      </div>

      <div class="conbox">
        <div class="gbox">
          <p class="txt">
            아래와 같이 새로운 화면이 추가되게 됩니다.<br />맞춤 질문 소스를
            복사하여 붙여주시고, 상단의 '저장'버튼을 클릭하면 완료됩니다.
          </p>
          <div class="btn_r">
            <button
              type="button"
              name=""
              class="btn btn_small btn_secondary"
              @click="sourceFileReader()"
            >
              맞춤 질문 소스 복사하기
            </button>
          </div>
        </div>
        <div class="imgbox">
          <img src="/image/m_guide3.jpg" alt="" />
        </div>
      </div>

      <div class="conbox">
        <p class="txt">
          모두 따라하셨나요?<br />
          내 모바일 쇼핑몰에 '맞춤 질문 추천 서비스'가 적용되었습니다.<br />
          PC, 모바일 쇼핑몰 설치 방법은 동일합니다.<br /><br />
          위 과정을 모두 완료하셨다면 아래의 페이지 경로를 확인하시고, 배너등에
          링크 주소를 연결하여 사용하시면 됩니다.
        </p>

        <div class="gbox">
          <p class="txt" id="link">{{ getBaseUrl }}/survey.html</p>
          <div class="btn_r">
            <button
              type="button"
              name=""
              class="btn btn_small btn_secondary"
              @click="copy('link')"
            >
              경로 복사하기
            </button>
          </div>
        </div>
      </div>

      <div class="conbox">
        <h3 class="tit">결과 내용 보기 링크주소</h3>
        <p class="txt">
          쇼핑몰 회원의 경우 참여한 마지막 결과 내용을 쇼핑몰에서 연결하여
          보여주고 싶으시다면 아래 링크주소를 활용하여 노출 할 수 있습니다.<br />
          마이페이지에 노출을 권장드리며, 원하시는 다른 페이지에도 텍스트 또는
          배너에 링크를 연결하여 활용하실 수가 있으니 쇼핑몰 운영 정책에 맞게
          사용해보세요.<br /><br />
          해당 결과 내용 보기는 로그인된 회원 기준으로 사용이 가능합니다.<br />
          회원이 맞춤 질문 추천 서비스를 사용한 경우에만 확인이 가능하오니
          사용에 참고해주세요.
        </p>
        <div class="gbox">
          <p class="txt" id="result_link">
            {{ getBaseUrl }}/survey.html?result=Y
          </p>
          <div class="btn_r">
            <button
              type="button"
              name=""
              class="btn btn_small btn_secondary"
              @click="copy('result_link')"
            >
              경로 복사하기
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- //content -->
    <input type="hidden" id="clipboard" />
  </section>
  <!-- //container -->
</template>
<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    mobile_skin_code: null,
  }),
  created: async function () {
    await this.setMobileUrl();
  },
  methods: {
    copy: function (id) {
      const container = document.getElementById('container');
      const text = document.getElementById(id).innerHTML;

      const input = document.createElement('input');
      input.setAttribute('type', 'text');

      container.appendChild(input);
      input.value = text.trim();
      input.select();

      document.execCommand('copy');
      container.removeChild(input);

      alert('복사가 완료되었습니다.');
    },
    sourceFileReader() {
      let rawFile = new XMLHttpRequest();
      rawFile.open('GET', '/survey_m.html', false);
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4) {
          if (rawFile.status === 200 || rawFile.status == 0) {
            let allText = rawFile.responseText;
            let aux = document.createElement('input');
            aux.setAttribute('value', allText);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand('copy');
            document.body.removeChild(aux);
            alert('복사가 완료되었습니다.');
          }
        }
      };
      rawFile.send(null);
    },
    setMobileUrl: function () {
      const shop_no = sessionStorage.getItem('survey_shop_no');
      for (let i = 0; i < this.mall_list.length; i++) {
        if (this.mall_list[i].shop_no == shop_no) {
          for (let j = 0; j < this.design_info.length; j++) {
            if (
              Number(this.design_info[j].skin_no) ==
                Number(this.mall_list[i].mobile_skin_no) &&
              Number(shop_no) > 1
            ) {
              this.mobile_skin_code = this.design_info[j].skin_code;
            }
          }
        }
      }
    },
  },
  computed: {
    ...mapState('common', [
      'shop_no',
      'store',
      'mall_list',
      'survey_mall_list',
      'design_info',
    ]),
    getBaseUrl: function () {
      const shop_no = sessionStorage.getItem('survey_shop_no');
      const mall_list = this.$store.state.common.mall_list;
      const index = mall_list.findIndex(obj => obj.shop_no === shop_no);
      if (index !== -1) {
        if (this.mobile_skin_code) {
          return (
            'http://skin-' +
            this.mobile_skin_code +
            '--' +
            mall_list[index].primary_domain
          );
        } else {
          return 'http://m.' + mall_list[index].primary_domain;
        }
      }

      return '';
    },
  },
};
</script>

<style scoped></style>
