<template>
  <QnaSetting />
</template>

<script>
// @ is an alias to /src
import QnaSetting from '@/components/qna/QnaSetting.vue';


export default {
  components: {
    QnaSetting,
  },
};
</script>
