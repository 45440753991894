import M from '@/libs/mapping.js';

const actions = {
  async getSettingList({ commit }, params) {
    try {
      const result = await M.api(M.entry('/get/survey/setting'), params);
      commit('setSettingList', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async postData(commit, obj) {
    try {
      const parent_id = obj.parent_id;
      const params = obj.parameters;
      const result = await M.api(
        M.entry('/post/survey/setting', parent_id),
        params,
      );

      if (result) return true;
      else return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async deleteData(commit, obj) {
    try {
      const hierarchy_id = obj.hierarchy_id;
      const params = obj.parameters;
      const result = await M.api(
        M.entry('/delete/survey/setting', hierarchy_id),
        params,
      );

      if (result) return true;
      else return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async postChangeSequence({ commit }, obj) {
    try {
      const parent_hierarchy_id = obj.parent_hierarchy_id;
      const params = obj.hierarchy;
      const result = await M.api(
        M.entry('/post/survey/hierarchy/sequence', parent_hierarchy_id),
        params,
      );

      if (result) return true;
      else return false;
    } catch (e) {}
  },
  async postHierarchyProducts(commit, obj) {
    try {
      const hierarchy_id = obj.hierarchy_id;
      const params = obj.parameters;
      //'/app/survey/cafe24/{1}/api/survey/{3}/hierarchy/products',
      const result = await M.api(
        M.entry('/post/survey/hierarchy/products', hierarchy_id),
        params,
      );

      if (result) return true;
      else return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default actions;
