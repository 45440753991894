<template>
  <StatsUser />
</template>

<script>
// @ is an alias to /src
import StatsUser from '@/components/stats/StatsUser.vue';

export default {
  components: {
    StatsUser,
  },
};
</script>
