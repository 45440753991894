import M from '@/libs/mapping.js';

const actions = {
  async getStatistics({ commit }, params = []) {
    try {
      const result = await M.api(M.entry('/get/stats/choice'), params);
      commit('setStatistics', result);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default actions;
