<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer : 질문답변 추가 -->
    <section id="modalQnaAdd" class="layer_dialog" ref="layer_dialog">
      <h2>[{{ folder_subject }}] 질문/답변 설정</h2>
      <p class="txt">
        질문하실 내역과 사용자가 답변을 선택 또는 입력할 항목에 대해
        설정합니다.<br />이름/성별/나이를 답변 받고자하는 경우 '질문 입력'
        항목을 선택하고 질문을 만들어주세요.<br />이름, 나이, 성별을 사용자가
        답변을 한 후에 해당 값을 노출할 수 있습니다.<br />질문 또는 결과 화면에
        $이름$, $나이$, $성별$ 텍스트를 넣어 사용이 가능하며, 성별/나이의 경우
        통계 메뉴에서 확인이 가능합니다.
      </p>

      <table class="tbl_noline">
        <caption>
          질문/답변 설정
        </caption>
        <colgroup>
          <col style="width: 25%" />
          <col style="width: 75%" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">질문 화면 아이콘 노출</th>
            <td>
              <ul class="input_list">
                <li>
                  <input
                    type="radio"
                    name="use_icon"
                    v-model="this.use_icon"
                    value="disabled"
                    id="use_icon_disabled"
                  /><label for="use_icon_disabled">사용안함</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="use_icon"
                    v-model="this.use_icon"
                    value="enabled"
                    id="use_icon_enabled"
                  /><label for="use_icon_enabled">사용함</label>
                </li>
              </ul>
              <!-- 사용함 선택 시 노출 -->
              <!-- file upload -->
              <div
                v-if="this.use_icon === 'enabled'"
                class="upload mt10 d-inline-block"
              >
                <span class="material-icons md-light ico_file"
                  >attach_file</span
                >
                <input
                  type="text"
                  id="use_icon_image"
                  class="input_read"
                  readonly="readonly"
                  v-model="icon_image_path"
                  placeholder="150px * 150px 권장/ 5M 이하 / gif, png, jpg(jpeg)"
                />
                <span class="input_wrap" v-if="this.icon_image_path === ''">
                  <input
                    type="button"
                    class="btn btn_small btn_light pointer"
                    value="업로드"
                    title="업로드"
                    @click="fileClick($event)"
                  /><input
                    type="file"
                    class="input_hidden"
                    @change="changeImage($event, 'icon')"
                  />
                </span>
              </div>
              <!-- //file upload -->
              <!-- 업로드 후 -->
              <p
                v-if="this.icon_image_path"
                class="upload_view mt10 d-inline-block"
              >
                <button
                  type="button"
                  @click="showImage('icon')"
                  class="btn btn_small btn_secondary ml5 mr10"
                >
                  <span>이미지 보기</span>
                </button>
                <button type="button" @click="clearImage('icon')">
                  <span class="material-icons md-light">cancel</span>
                </button>
              </p>
              <!-- //사용함 선택 시 노출 -->
            </td>
          </tr>
          <tr>
            <th scope="row">질문 입력</th>
            <td class="input_td">
              <!-- 셀렉트, 인풋박스 있을 경우 클래스 추가 -->
              <ul class="input_set">
                <li class="slt">
                  <select
                    name=""
                    class=""
                    v-model="this.subject_type"
                    v-on:change="change(rowId, $event)"
                  >
                    <option value="free">자유 설정</option>
                    <option value="name">이름</option>
                    <option value="age">나이</option>
                    <option value="sex">성별</option>
                  </select>
                </li>
                <li class="inputbox">
                  <textarea
                    id="subject"
                    rows="1"
                    v-model.trim="this.subject"
                    placeholder="질문하실 내용을 입력해주세요"
                    data-autoresize
                    @input="textAreaReSize($event)"
                    @click="textAreaReSize($event)"
                    @keyup="inputText($event)"
                    class="question_title"
                  ></textarea>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="row">질문 화면 이미지 삽입</th>
            <td>
              <ul class="input_list">
                <li>
                  <input
                    type="radio"
                    name="use_image"
                    value="disabled"
                    v-model="this.use_image"
                    id="use_image_disabled"
                  /><label for="use_image_disabled">사용안함</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="use_image"
                    value="enabled"
                    v-model="this.use_image"
                    id="use_image_enabled"
                  /><label for="use_image_enabled">사용함</label>
                </li>
              </ul>
              <!-- 사용함 선택 시 노출 -->
              <div
                v-if="this.use_image === 'enabled'"
                class="option pt10 pb10 d-inline-block"
              >
                <dl>
                  <dt class="narrow" style="width: 100px">PC</dt>
                  <dd>
                    <!-- file upload -->
                    <div class="upload fl_l">
                      <span class="material-icons md-light ico_file"
                        >attach_file</span
                      >
                      <input
                        type="text"
                        class="input_read answer_input_image_width"
                        readonly="readonly"
                        placeholder="PC 화면 - 5M 이하 / gif, png, jpg(jpeg)"
                        v-model="question_pc_image_path"
                      />
                      <span
                        class="input_wrap"
                        v-if="this.question_pc_image_path === ''"
                      >
                        <input
                          type="button"
                          class="btn btn_small btn_light pointer"
                          value="업로드"
                          title="업로드"
                          @click="fileClick($event)"
                        /><input
                          type="file"
                          class="input_hidden"
                          @change="changeImage($event, 'question_pc')"
                        />
                      </span>
                    </div>
                    <!-- //file upload -->
                    <!-- 업로드 후 -->
                    <p
                      v-if="this.question_pc_image_path"
                      class="upload_view d-inline-block"
                    >
                      <button
                        type="button"
                        @click="showImage('question_pc')"
                        class="btn btn_small btn_secondary ml5 mr10"
                      >
                        <span>이미지 보기</span>
                      </button>
                      <button type="button" @click="clearImage('question_pc')">
                        <span class="material-icons md-light">cancel</span>
                      </button>
                    </p>
                  </dd>
                </dl>

                <dl>
                  <dt class="narrow" style="width: 100px">모바일</dt>
                  <dd>
                    <!-- file upload -->
                    <div class="upload fl_l">
                      <span class="material-icons md-light ico_file"
                        >attach_file</span
                      >
                      <input
                        type="text"
                        class="input_read answer_input_image_width"
                        readonly="readonly"
                        placeholder="모바일 화면 - 5M 이하 / gif, png, jpg(jpeg)"
                        v-model="question_mobile_image_path"
                      />
                      <span
                        class="input_wrap"
                        v-if="this.question_mobile_image_path === ''"
                      >
                        <input
                          type="button"
                          class="btn btn_small btn_light pointer"
                          value="업로드"
                          title="업로드"
                          @click="fileClick($event)"
                        /><input
                          type="file"
                          class="input_hidden"
                          @change="changeImage($event, 'question_mobile')"
                        />
                      </span>
                    </div>
                    <!-- //file upload -->
                    <!-- 업로드 후 -->
                    <p
                      v-if="this.question_mobile_image_path"
                      class="upload_view d-inline-block"
                    >
                      <button
                        type="button"
                        @click="showImage('question_mobile')"
                        class="btn btn_small btn_secondary ml5 mr10"
                      >
                        <span>이미지 보기</span>
                      </button>
                      <button
                        type="button"
                        @click="clearImage('question_mobile')"
                      >
                        <span class="material-icons md-light">cancel</span>
                      </button>
                    </p>
                  </dd>
                </dl>
              </div>
              <!-- //사용함 선택 시 노출 -->
            </td>
          </tr>
          <tr class="line">
            <td colspan="2"></td>
          </tr>
          <tr>
            <th scope="row" class="pt10">답변 타입</th>
            <td class="input_td">
              <select
                name=""
                class=""
                v-model="this.answer_type"
                style="width: 100%"
                :disabled="!this.is_answer_type"
                @change="answerTypeChange(this.answer_type)"
              >
                <option value="text" :disabled="!this.is_answer_type_text">
                  텍스트 입력
                </option>
                <option
                  value="dropdown"
                  :disabled="!this.is_answer_type_dropdown"
                >
                  드롭다운
                </option>
                <option value="radio" :disabled="!this.is_answer_type_radio">
                  라디오버튼
                </option>
                <option value="check" :disabled="!this.is_answer_type_check">
                  체크박스 (다중선택 가능)
                </option>
                <option
                  value="button1_1"
                  :disabled="!this.is_answer_type_button1_1"
                >
                  버튼 선택 (1줄 1개씩, 다중선택 가능)
                </option>
                <option
                  value="button1_2"
                  :disabled="!this.is_answer_type_button1_2"
                >
                  버튼 선택 (1줄 2개씩, 다중선택 가능)
                </option>
                <option
                  value="button1_3"
                  :disabled="!this.is_answer_type_button1_3"
                >
                  버튼 선택 (1줄 3개씩, 다중선택 가능)
                </option>
                <option value="file" :disabled="!this.is_answer_type_file">
                  파일첨부 (5M 이하 파일 가능)
                </option>
              </select>
            </td>
          </tr>
          <!-- free -->
          <tr v-if="this.subject_type === 'free'">
            <td colspan="2" class="input_td">
              <!-- text -->
              <table
                v-if="this.answer_type === 'text'"
                class="tbl_noline tbl_noline2"
              >
                <colgroup>
                  <col style="width: 25%" />
                  <col style="width: 75%" />
                </colgroup>
                <tbody>
                  <tr
                    v-for="(input, i) in this.children.length"
                    :key="input.id"
                  >
                    <th scope="row">답변{{ i + 1 }}</th>
                    <td class="input_td">
                      <ul class="input_set">
                        <li class="inputbox">
                          <textarea
                            name=""
                            rows="1"
                            cols="1"
                            v-model.trim="this.children[i].subject"
                            placeholder="입력란에 기본 노출되는 텍스트 입력이 가능합니다."
                            data-autoresize
                            v-on:keydown.enter="textAreaEnterNoInput($event)"
                            :index="i"
                            @keyup="inputText($event)"
                            class="textAreaResizeNone"
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td class="input_td">
                      <div class="option pt10">
                        <dl>
                          <dt class="narrow">답변 입력/선택시</dt>
                          <dd>
                            <select
                              name=""
                              v-model="this.next_hierarchy_id"
                              class=""
                              style="width: 100%"
                            >
                              <option value="0">
                                설정한 다음 질문 이동 (기본)
                              </option>
                              <option
                                v-for="(input, i) in this.question_list"
                                :key="i"
                                v-bind:value="input.id"
                              >
                                {{ optionTextLength(input.subject, 38) }}
                              </option>
                            </select>
                          </dd>
                        </dl>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- // text -->
              <!-- radio, dropdown -->
              <table
                v-else-if="
                  this.answer_type === 'radio' ||
                  this.answer_type === 'dropdown'
                "
                class="tbl_noline tbl_noline2"
              >
                <colgroup>
                  <col style="width: 25%" />
                  <col style="width: 75%" />
                </colgroup>
                <tbody>
                  <tr v-for="(input, i) in this.children" :key="input.id">
                    <th scope="row">답변{{ i + 1 }}</th>
                    <td class="input_td">
                      <ul class="input_set">
                        <li class="inputbox">
                          <textarea
                            v-if="this.answer_type === 'dropdown'"
                            name=""
                            rows="1"
                            cols="1"
                            v-model.trim="this.children[i].subject"
                            placeholder="답변 내용을 입력해주세요."
                            data-autoresize
                            :index="i"
                            @keyup="inputText($event)"
                            v-on:keydown.enter="textAreaEnterNoInput($event)"
                            class="textAreaResizeNone"
                          ></textarea>
                          <textarea
                            v-else
                            name=""
                            rows="1"
                            cols="1"
                            v-model.trim="this.children[i].subject"
                            placeholder="답변 내용을 입력해주세요."
                            data-autoresize
                            @input="textAreaReSize($event)"
                            @click="textAreaReSize($event)"
                            :index="i"
                            @keyup="inputText($event)"
                            class="ansert_title"
                          ></textarea>
                        </li>
                      </ul>
                      <div class="option pt10">
                        <dl>
                          <dt class="narrow">답변 선택시</dt>
                          <dd>
                            <select
                              name=""
                              v-model="this.next_hierarchy_ids[i]"
                              class=""
                              style="width: 100%"
                            >
                              <option value="0">
                                설정 목록의 다음 단계 이동
                              </option>
                              <option
                                v-for="(input, index) in this.question_list"
                                :key="index"
                                v-bind:value="input.id"
                              >
                                {{ optionTextLength(input.subject, 38) }}
                              </option>
                            </select>
                          </dd>
                        </dl>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- //radio, dropdown -->
              <!-- check -->
              <table
                v-else-if="
                  this.answer_type === 'check' ||
                  this.answer_type === 'button1_1' ||
                  this.answer_type === 'button1_2' ||
                  this.answer_type === 'button1_3'
                "
                class="tbl_noline tbl_noline2"
              >
                <colgroup>
                  <col style="width: 25%" />
                  <col style="width: 75%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row" class="pt10">답변 선택</th>
                    <td class="input_td">
                      <input
                        type="number"
                        name=""
                        v-model="multiple_select_min"
                        min="0"
                        placeholder="0"
                        style="width: 80px"
                        @input="onlyNumber($event, 'min')"
                      />
                      ~
                      <input
                        type="number"
                        name=""
                        v-model="multiple_select_max"
                        min="0"
                        placeholder="0"
                        style="width: 80px"
                        @input="onlyNumber($event, 'max')"
                      />
                      개 까지 선택 가능
                    </td>
                  </tr>
                  <tr v-for="(input, i) in this.children" :key="input.id">
                    <th scope="row">답변{{ i + 1 }}</th>
                    <td class="input_td">
                      <ul class="input_set">
                        <li class="inputbox">
                          <!--
                          <textarea
                            v-if="this.answer_type === 'check'"
                            name=""
                            rows="1"
                            cols="1"
                            v-model.trim="this.children[i].subject"
                            placeholder=""
                            autoresize
                            @input="textAreaReSize($event)"
                            @click="textAreaReSize($event)"
                            :index="i"
                            @keyup="inputText($event)"
                            class="ansert_title"
                          ></textarea>
                          <textarea
                            v-else
                            name=""
                            rows="1"
                            cols="1"
                            v-model.trim="this.children[i].subject"
                            placeholder=""
                            autoresize
                            v-on:keydown.enter="textAreaEnterNoInput($event)"
                            :index="i"
                            @keyup="inputText($event)"
                            class="textAreaResizeNone"
                          ></textarea>
                          -->
                          <textarea
                            name=""
                            rows="1"
                            cols="1"
                            v-model.trim="this.children[i].subject"
                            placeholder=""
                            autoresize
                            @input="textAreaReSize($event)"
                            @click="textAreaReSize($event)"
                            :index="i"
                            @keyup="inputText($event)"
                            class="ansert_title"
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td>
                      <div class="option pt10">
                        <dl>
                          <dt class="narrow">답변 선택시</dt>
                          <dd>
                            <select
                              name=""
                              v-model="this.next_hierarchy_id"
                              class=""
                              style="width: 100%"
                            >
                              <option value="0">
                                설정 목록의 다음 단계 이동
                              </option>
                              <option
                                v-for="(input, i) in this.question_list"
                                :key="i"
                                v-bind:value="input.id"
                              >
                                {{ optionTextLength(input.subject, 38) }}
                              </option>
                            </select>
                          </dd>
                        </dl>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- //check -->
              <!-- text -->
              <table
                v-else-if="this.answer_type === 'file'"
                class="tbl_noline tbl_noline2"
              >
                <colgroup>
                  <col style="width: 25%" />
                  <col style="width: 75%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th></th>
                    <td class="input_td">
                      <div class="option pt10">
                        <dl>
                          <dt class="narrow">파일 첨부시</dt>
                          <dd>
                            <select
                              name=""
                              v-model="this.next_hierarchy_id"
                              class=""
                              style="width: 100%"
                            >
                              <option value="0">
                                설정 목록의 다음 단계 이동
                              </option>
                              <option
                                v-for="(input, i) in this.question_list"
                                :key="i"
                                v-bind:value="input.id"
                              >
                                {{ optionTextLength(input.subject, 38) }}
                              </option>
                            </select>
                          </dd>
                        </dl>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- // text -->
              <div
                v-if="this.is_button_option_use && this.answer_type !== 'file'"
                class="btn_wrap right mb20"
              >
                <button @click="removeInputs" class="btn btn_small btn_light">
                  - 답변 삭제
                </button>
                <button
                  @click="appendInputs"
                  class="btn btn_small btn_secondary ml5"
                >
                  + 답변 추가
                </button>
              </div>
            </td>
          </tr>
          <!-- //free -->
          <tr v-if="this.subject_type === 'age'">
            <th scope="row">답변</th>
            <td class="input_td">
              <ul class="input_set">
                <li class="inputbox">
                  <textarea
                    name=""
                    rows="1"
                    cols="1"
                    v-model.trim="this.children[0].subject"
                    placeholder="나이 입력"
                    data-autoresize
                    v-on:keydown.enter="textAreaEnterNoInput($event)"
                    :index="0"
                    @keyup="inputText($event)"
                    class="textAreaResizeNone"
                  ></textarea>
                </li>
              </ul>
              <div class="option pt10 pb50">
                <dl>
                  <dt class="narrow">답변 입력/선택시</dt>
                  <dd>
                    <select
                      name=""
                      v-model="this.next_hierarchy_id"
                      class=""
                      style="width: 100%"
                    >
                      <option value="0">설정한 다음 질문 이동 (기본)</option>
                      <option
                        v-for="(input, i) in this.question_list"
                        :key="i"
                        v-bind:value="input.id"
                      >
                        {{ optionTextLength(input.subject, 38) }}
                      </option>
                    </select>
                  </dd>
                </dl>
              </div>
            </td>
          </tr>
          <tr v-else-if="this.subject_type === 'name'">
            <th scope="row">답변</th>
            <td class="input_td">
              <!-- 질문 입력 - '이름' 선택시 -->
              <ul class="input_set">
                <li class="inputbox">
                  <textarea
                    name=""
                    rows="1"
                    cols="1"
                    v-model.trim="this.children[0].subject"
                    placeholder="이름 입력"
                    data-autoresize
                    v-on:keydown.enter="textAreaEnterNoInput($event)"
                    :index="0"
                    @keyup="inputText($event)"
                    class="textAreaResizeNone"
                  ></textarea>
                </li>
              </ul>
              <div class="option pt10 pb50">
                <dl>
                  <dt class="narrow">답변 입력/선택시</dt>
                  <dd>
                    <select
                      name=""
                      v-model="this.next_hierarchy_id"
                      class=""
                      style="width: 100%"
                    >
                      <option value="0">설정한 다음 질문 이동 (기본)</option>
                      <option
                        v-for="(input, i) in this.question_list"
                        :key="i"
                        v-bind:value="input.id"
                      >
                        {{ optionTextLength(input.subject, 38) }}
                      </option>
                    </select>
                  </dd>
                </dl>
              </div>
            </td>
          </tr>
          <!-- free -->
          <tr v-else-if="this.subject_type === 'sex'">
            <td colspan="2" class="input_td">
              <!-- text -->
              <table
                v-if="
                  this.answer_type === 'radio' ||
                  this.answer_type === 'dropdown'
                "
                class="tbl_noline tbl_noline2"
              >
                <colgroup>
                  <col style="width: 25%" />
                  <col style="width: 75%" />
                </colgroup>
                <tbody>
                  <tr v-for="(input, i) in this.children" :key="input.id">
                    <th scope="row">답변{{ i + 1 }}</th>
                    <td class="input_td">
                      <ul class="input_set">
                        <li class="slt">
                          <select
                            name=""
                            v-model="this.children[i].gender_type"
                            class=""
                          >
                            <option value="male">남성</option>
                            <option value="female">여성</option>
                            <option value="etc">기타</option>
                          </select>
                        </li>
                        <li class="inputbox">
                          <textarea
                            v-if="this.answer_type === 'radio'"
                            rows="1"
                            v-model.trim="this.children[i].subject"
                            data-autoresize
                            @input="textAreaReSize($event)"
                            @click="textAreaReSize($event)"
                            :index="i"
                            @keyup="inputText($event)"
                            class="ansert_title"
                          ></textarea>
                          <textarea
                            v-else
                            rows="1"
                            v-model.trim="this.children[i].subject"
                            data-autoresize
                            v-on:keydown.enter="textAreaEnterNoInput($event)"
                            :index="i"
                            @keyup="inputText($event)"
                            class="textAreaResizeNone"
                          ></textarea>
                        </li>
                      </ul>
                      <div class="option pt10 pb10">
                        <dl>
                          <dt class="narrow">답변 입력/선택시</dt>
                          <dd>
                            <select
                              name=""
                              v-model="this.next_hierarchy_ids[i]"
                              class=""
                              style="width: 100%"
                            >
                              <option value="0">
                                설정한 다음 질문 이동 (기본)
                              </option>
                              <option
                                v-for="(input, index) in this.question_list"
                                :key="index"
                                v-bind:value="input.id"
                              >
                                <font>{{
                                  optionTextLength(input.subject, 38)
                                }}</font>
                              </option>
                            </select>
                          </dd>
                        </dl>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- // text -->

              <div v-if="this.is_button_option_use" class="btn_wrap right mb20">
                <button @click="removeInputs" class="btn btn_small btn_light">
                  - 답변 삭제
                </button>
                <button
                  @click="appendInputs"
                  class="btn btn_small btn_secondary ml5"
                >
                  + 답변 추가
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="btns">
        <ul>
          <li>
            <button
              @click="this.show_modal = false"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button @click="submit" class="btn btn_large btn_primary">
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 질문답변 추가 -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';
import ModalImagePreview from '@/components/qna/modal/ModalImagePreview.vue';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    use_icon: String,
    icon_image: '',
    icon_image_path: '',
    subject: String,
    subject_type: String,
    answer_type: String,
    multiple_select: String,
    multiple_select_min: Number,
    multiple_select_max: Number,
    use_image: String,
    question_pc_image: '',
    question_pc_image_path: '',
    question_mobile_image: '',
    question_mobile_image_path: '',
    is_button_option_use: true,
    is_answer_type: true,
    is_answer_type_text: true,
    is_answer_type_radio: true,
    is_answer_type_check: true,
    is_answer_type_dropdown: true,
    is_answer_type_button1_1: true,
    is_answer_type_button1_2: true,
    is_answer_type_button1_3: true,
    is_answer_type_file: true,
    answerType: String,
    show_modal: false,
    free_text_inputs: Array,
    free_radio_inputs: Array,
    free_check_inputs: Array,
    gender_types: Array,
    gender_text_inputs: Array,
    free_text_ids: Array,
    free_radio_ids: Array,
    free_check_ids: Array,
    question_list: [],
    children: [],
    remove_children: [],
    next_hierarchy_id: 0,
    next_hierarchy_ids: [],
    answer_pc_image: '',
    answer_pc_image_path: '',
    answer_mobile_image: '',
    answer_mobile_image_path: '',
    before_answer_type: null,
    is_self: false,
    next_answer_hierarchy_id: null,
    before_children: [],
  }),
  props: {
    message: {
      type: String,
      default: '',
    },
    hierarchy_id: Number,
    parent_id: Number,
    params: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    folder_subject: String,
  },
  created: async function () {
    await this.getSettingList();

    this.use_icon = 'disabled';
    this.subject = '';
    this.subject_type = 'free';
    this.answer_type = 'text';
    this.use_image = 'disabled';
    this.multiple_select_min = 0;
    this.multiple_select_max = 0;

    this.gender_types = ['male', 'female', 'etc'];
    this.gender_text_inputs = ['남성', '여성', '기타'];

    if (typeof this.params.subject === 'string') {
      this.subject = this.params.subject;
      this.subject_type = this.params.subject_type;
      this.use_icon = this.params.use_icon;
      this.use_image = this.params.use_image;
      this.icon_image = this.params.survey_icon;
      this.icon_image_path = this.params.survey_icon;
      this.question_pc_image = this.params.survey_desktop_image;
      this.question_pc_image_path = this.params.survey_desktop_image;
      this.question_mobile_image = this.params.survey_mobile_image;
      this.question_mobile_image_path = this.params.survey_mobile_image;
      this.multiple_select = this.params.multiple_select;
      this.multiple_select_min = this.params.multiple_select_min;
      this.multiple_select_max = this.params.multiple_select_max;

      this.setPermissionByAnswerType(this.subject_type);
    }

    if (
      typeof this.params.answer === 'object' &&
      this.params.answer.length > 0
    ) {
      this.answer_type = this.params.answer[0].subject_type;
      this.next_hierarchy_id = this.params.answer[0].next_hierarchy_id || 0;
      this.answer_pc_image = this.params.answer[0].survey_desktop_image;
      this.answer_pc_image_path = this.params.answer[0].survey_desktop_image;
      this.answer_mobile_image = this.params.answer[0].survey_mobile_image;
      this.answer_mobile_image_path = this.params.answer[0].survey_mobile_image;

      if (
        this.question_list.findIndex(e => e.id === this.next_hierarchy_id) ===
        -1
      ) {
        this.next_hierarchy_id = 0;
      }

      for (const [key, item] of Object.entries(this.params.answer)) {
        let param = {
          hierarchy_id: item['hierarchy_id'],
          hierarchy_type: 'answer',
          subject: item['subject'],
          subject_type: item['subject_type'],
          gender_type: item['gender_type'],
          answer_pc_image: item['survey_desktop_image'],
          answer_pc_image_path: item['survey_desktop_image'],
          answer_mobile_image: item['survey_mobile_image'],
          answer_mobile_image_path: item['survey_mobile_image'],
          next_hierarchy_id: item.next_hierarchy_id || 0,
        };

        this.children.push(param);

        if (this.next_answer_hierarchy_id == item.next_hierarchy_id) {
          this.next_hierarchy_ids.push(0);
        } else {
          this.next_hierarchy_ids.push(
            item.next_hierarchy_id === null ? 0 : item.next_hierarchy_id,
          );
        }
      }
      // 복사..
      this.remove_children = JSON.parse(JSON.stringify(this.children));
      this.before_children = JSON.parse(JSON.stringify(this.children));
    } else {
      this.children.push({
        hierarchy_id: 0,
        hierarchy_type: 'answer',
        subject: '',
        subject_type: this.answer_type,
        gender_type: '',
      });
      this.next_hierarchy_ids.push(0);
    }

    this.show_modal = true;
  },

  computed: {
    ...mapState('qna_setting', ['setting_list']),
  },
  methods: {
    getSettingList: async function () {
      await this.$store.dispatch('qna_setting/getSettingList');
      await this.setQuestionList(this.setting_list);
    },
    setQuestionList: async function (questions) {
      if (Array.isArray(questions) && questions.length > 0) {
        for (const [key, item] of Object.entries(questions)) {
          if (item.hierarchy_type === 'question') {
            // 나 자신의 질문은 답변 입력/선택시 미노출 ( question_list push 안함 )
            if (this.hierarchy_id === item.hierarchy_id) {
              // 나 자신의 질문 체크
              this.is_self = true;
            } else {
              // 나 자신의 질문 다음 질문은 미노출 한다 -  설정한 다음 질문 이동 (기본)에 체크 하기 위함
              if (this.is_self === false) {
                this.question_list.push({
                  id: item.hierarchy_id,
                  subject: item.subject,
                });
              } else {
                this.next_answer_hierarchy_id = item.hierarchy_id;
              }
              this.is_self = false;
            }
          }
          if (Array.isArray(item.children) && item.children.length > 0) {
            await this.setQuestionList(item.children);
          }
        }
      }
    },
    submit: async function () {
      if (this.subject == undefined || this.subject.trim() == '') {
        alert('질문하실 내용을 입력해주세요.');
        return false;
      } else if (
        this.subject_type === 'sex' &&
        (this.answer_type === 'radio' || this.answer_type === 'dropdown')
      ) {
        let male_cnt = 0;
        let female_cnt = 0;
        let etc_cnt = 0;

        for (const [key, item] of Object.entries(this.children)) {
          if (item.gender_type === 'male') male_cnt++;
          else if (item.gender_type === 'female') female_cnt++;
          else etc_cnt++;

          if (male_cnt > 1 || female_cnt > 1 || etc_cnt > 1) {
            alert('동일한 성별이 선택되어 있습니다.\r\n변경 후 저장해주세요.');
            return false;
          }
        }
      }

      if (this.subject.length > 250) {
        alert('질문 내용은 최대 250글자 까지 입력 가능합니다.');
        return false;
      }

      for (let _i = 0; _i < this.children.length; _i++) {
        if (this.children[_i].subject.length > 250) {
          alert('답변 내용은 최대 250글자 까지 입력 가능합니다.');
          return false;
        }
      }

      this.multiple_select = 'disabled';
      if (
        this.answer_type === 'check' ||
        this.answer_type === 'button1_1' ||
        this.answer_type === 'button1_2' ||
        this.answer_type === 'button1_3'
      ) {
        this.multiple_select = 'enabled';
      }

      for (const children of this.children) {
        if (
          this.answer_type !== 'file' &&
          (children.subject === '' || children.subject === undefined)
        ) {
          alert('답변을 입력/선택해주세요.');
          return false;
        }
      }

      for (const [key, item] of Object.entries(this.children)) {
        this.children[key].subject_type = this.answer_type;

        if (this.answer_type === 'radio' || this.answer_type === 'dropdown')
          this.children[key].next_hierarchy_id = this.next_hierarchy_ids[key];
        else this.children[key].next_hierarchy_id = this.next_hierarchy_id;

        if (
          this.answer_type === 'check' ||
          this.answer_type === 'button1_1' ||
          this.answer_type === 'button1_2' ||
          this.answer_type === 'button1_3'
        ) {
          if (
            this.multiple_select_min === '' ||
            this.multiple_select_max === '' ||
            (this.multiple_select_min === 0 && this.multiple_select_max === 0)
          ) {
            alert('선택 가능한 답변의 수를 입력해주세요.');
            return false;
          } else if (this.multiple_select_min === 0) {
            alert('최소 선택 수는 1개 이상으로 입력해주세요.');
            return false;
          } else if (this.multiple_select_max < this.multiple_select_min) {
            alert('최대 개수 보다 최소 개수가 큽니다.');
            return false;
          }
        }

        if (
          item['hierarchy_id'] === 0 ||
          item['hierarchy_id'] === null ||
          item['hierarchy_id'] === undefined
        ) {
          continue;
        }

        this.children[key].survey_desktop_image = this.answer_pc_image;
        this.children[key].survey_mobile_image = this.answer_mobile_image;

        if (
          this.answer_type === 'file' &&
          this.answer_pc_image.length > 0 &&
          this.answer_pc_image.indexOf('http://') == -1 &&
          this.answer_pc_image.indexOf('https://') == -1
        ) {
          this.answer_pc_image = await this.$store.dispatch(
            'common/postImage',
            { image: this.answer_pc_image },
          );
          this.answer_pc_image_path = this.answer_pc_image;
          this.children[key].survey_desktop_image = this.answer_pc_image;
        }

        if (
          this.answer_type === 'file' &&
          this.answer_mobile_image.length > 0 &&
          this.answer_mobile_image.indexOf('http://') == -1 &&
          this.answer_mobile_image.indexOf('https://') == -1
        ) {
          this.answer_mobile_image = await this.$store.dispatch(
            'common/postImage',
            { image: this.answer_mobile_image },
          );
          this.answer_mobile_image_path = this.answer_mobile_image;
          this.children[key].survey_mobile_image = this.answer_mobile_image;
        }

        for (const [key2, item2] of Object.entries(this.remove_children)) {
          if (item['hierarchy_id'] === item2['hierarchy_id']) {
            this.remove_children.splice(key2, 1);
          }
        }
      }

      const params = {
        parent_id: this.parent_id,
        hierarchy_type: 'question',
        subject: this.subject,
        subject_type: this.subject_type,
        use_icon: this.use_icon,
        use_image: this.use_image,
        multiple_select: this.multiple_select,
        multiple_select_min: this.multiple_select_min,
        multiple_select_max: this.multiple_select_max,
        children: this.children,
        remove_children: this.remove_children,
        answer_type: this.answer_type,
      };

      if (this.hierarchy_id) {
        params['hierarchy_id'] = this.hierarchy_id;
      }

      if (this.use_icon === 'enabled' && this.icon_image.length === 0) {
        alert('질문 화면 아이콘 이미지를 등록해주세요.');
        return false;
      } else if (
        this.use_image === 'enabled' &&
        this.question_pc_image.length === 0 &&
        this.question_mobile_image.length === 0
      ) {
        alert('질문 화면 이미지를 등록해주세요.');
        return false;
      }

      params['survey_icon'] = this.icon_image;
      params['survey_desktop_image'] = this.question_pc_image;
      params['survey_mobile_image'] = this.question_mobile_image;

      if (
        this.use_icon === 'enabled' &&
        this.icon_image.length > 0 &&
        this.icon_image.indexOf('http://') == -1 &&
        this.icon_image.indexOf('https://') == -1
      ) {
        this.icon_image = await this.$store.dispatch('common/postImage', {
          image: this.icon_image,
        });
        this.icon_image_path = this.icon_image;
        params['survey_icon'] = this.icon_image;
      }

      if (
        this.use_image === 'enabled' &&
        this.question_pc_image.length > 0 &&
        this.question_pc_image.indexOf('http://') == -1 &&
        this.question_pc_image.indexOf('https://') == -1
      ) {
        this.question_pc_image = await this.$store.dispatch(
          'common/postImage',
          { image: this.question_pc_image },
        );
        this.question_pc_image_path = this.question_pc_image;
        params['survey_desktop_image'] = this.question_pc_image;
      }

      if (
        this.use_image === 'enabled' &&
        this.question_mobile_image.length > 0 &&
        this.question_mobile_image.indexOf('http://') == -1 &&
        this.question_mobile_image.indexOf('https://') == -1
      ) {
        this.question_mobile_image = await this.$store.dispatch(
          'common/postImage',
          { image: this.question_mobile_image },
        );
        this.question_mobile_image_path = this.question_mobile_image;
        params['survey_mobile_image'] = this.question_mobile_image;
      }
      const result = await this.$store.dispatch('qna_setting/postData', {
        parent_id: this.parent_id,
        parameters: params,
      });

      if (result === true) {
        alert('저장되었습니다.');
        this.show_modal = false;
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
    setPermissionByAnswerType(type = '') {
      this.subject_type = type;
      this.is_button_option_use = true;
      this.is_answer_type = true;
      this.is_answer_type_text = true;
      this.is_answer_type_radio = true;
      this.is_answer_type_check = true;
      this.is_answer_type_dropdown = true;
      this.is_answer_type_button1_1 = true;
      this.is_answer_type_button1_2 = true;
      this.is_answer_type_button1_3 = true;
      this.is_answer_type_file = true;

      if (type == 'name' || type == 'age') {
        this.is_answer_type = false;
        this.is_button_option_use = false;
        this.answer_type = 'text';
      } else if (type == 'sex') {
        this.is_answer_type_text = false;
        this.is_answer_type_radio = true;
        this.is_answer_type_check = false;
        this.is_answer_type_dropdown = true;
        this.is_answer_type_button1_1 = false;
        this.is_answer_type_button1_2 = false;
        this.is_answer_type_button1_3 = false;
        this.is_answer_type_file = false;
        this.answer_type = 'radio';
      }
    },
    change(rowId, e) {
      let type = null;
      if (e) {
        type = e.target.value;
      } else {
        type = rowId;
      }

      this.multiple_select_min = 0;
      this.multiple_select_max = 0;
      if (this.children.length >= 1) {
        this.next_hierarchy_ids = [];

        if (type == 'sex') {
          this.children = [
            {
              hierarchy_id: 0,
              hierarchy_type: 'answer',
              subject: '남성',
              gender_type: 'male',
            },
            {
              hierarchy_id: 0,
              hierarchy_type: 'answer',
              subject: '여성',
              gender_type: 'female',
            },
          ];
          this.next_hierarchy_ids.push(0);
          this.next_hierarchy_ids.push(0);
          if (!e && rowId) {
            return;
          }
        } else if (type === 'age') {
          this.children = [
            { hierarchy_id: 0, hierarchy_type: 'answer', subject: '나이 입력' },
          ];
          this.next_hierarchy_id = 0;
          this.next_hierarchy_ids.push(0);
        } else if (type === 'name') {
          this.children = [
            { hierarchy_id: 0, hierarchy_type: 'answer', subject: '이름 입력' },
          ];
          this.next_hierarchy_id = 0;
          this.next_hierarchy_ids.push(0);
        } else {
          if (
            this.before_answer_type == 'text' ||
            this.before_answer_type == 'dropdown'
          ) {
            for (const children_item of this.children) {
              if (children_item.subject.indexOf('\n') > -1) {
                let children_subject = children_item.subject.split('\n');
                children_item.subject = children_subject[0];
              }
              this.next_hierarchy_id = 0;
              this.next_hierarchy_ids.push(0);
            }
          } else if (this.before_answer_type == 'file') {
            this.children = [];
            this.appendInputs();
          } else {
            this.children = JSON.parse(JSON.stringify(this.before_children));
            if (this.children.length == 0) {
              this.appendInputs();
            }
            for (const children_item of this.children) {
              this.next_hierarchy_id = 0;
              this.next_hierarchy_ids.push(0);
            }
          }
        }
      }

      this.setPermissionByAnswerType(type);
    },
    appendInputs() {
      if (this.subject_type === 'free') {
        if (this.children.length >= 99) {
          return false;
        }

        this.children.push({
          hierarchy_id: 0,
          hierarchy_type: 'answer',
          subject: '',
          subject_type: this.answer_type,
        });
        this.next_hierarchy_ids.push(0);
      } else if (this.subject_type === 'sex') {
        if (this.answer_type === 'radio' || this.answer_type === 'dropdown') {
          if (this.children.length >= 3) {
            alert('성별 답변 선택은\r\n총 3개까지 설정 가능합니다.');
            return;
          }

          if (this.children.length == 1) {
            this.gender_types.push('famale');
            this.children.push({
              hierarchy_id: 0,
              hierarchy_type: 'answer',
              subject: '여성',
              subject_type: this.answer_type,
              gender_type: 'female',
            });
            this.next_hierarchy_ids.push(0);
          } else {
            this.gender_types.push('etc');
            this.children.push({
              hierarchy_id: 0,
              hierarchy_type: 'answer',
              subject: '기타',
              subject_type: this.answer_type,
              gender_type: 'etc',
            });
            this.next_hierarchy_ids.push(0);
          }
        }
      }

      // 스크롤 맨 아래로
      const layer_dialog = this.$refs.layer_dialog;
      this.$nextTick(() => {
        layer_dialog.scrollTo(0, layer_dialog.scrollHeight);
      });
    },
    removeInputs() {
      if (this.subject_type == 'free') {
        if (this.answer_type === 'text') {
          if (this.children.length <= 1) {
            return;
          }
          this.children.pop();
          this.next_hierarchy_ids.pop();
        } else if (
          this.answer_type === 'radio' ||
          this.answer_type === 'dropdown'
        ) {
          if (this.children.length <= 1) {
            return;
          }
          this.children.pop();
          this.next_hierarchy_ids.pop();
        } else if (
          this.answer_type === 'check' ||
          this.answer_type === 'button1_1' ||
          this.answer_type === 'button1_2' ||
          this.answer_type === 'button1_3'
        ) {
          if (this.children.length <= 1) {
            return;
          }
          this.children.pop();
          this.next_hierarchy_ids.pop();
        }
      } else if (this.subject_type === 'sex') {
        if (this.answer_type === 'radio' || this.answer_type === 'dropdown') {
          if (this.children.length <= 1) {
            return;
          }
          this.children.pop();
          this.gender_types.pop();
          this.next_hierarchy_ids.pop();
        }
      }
    },
    changeImage: async function (event, type = 'icon') {
      if (type === 'icon') {
        this.icon_image_path = event.target.value;
        const result = await this.uploadImage(event.target.files[0]);
        if (!result) {
          this.icon_image_path = '';
        }
        this.icon_image = result;
      } else if (type === 'question_pc') {
        this.question_pc_image_path = event.target.value;
        const result = await this.uploadImage(event.target.files[0]);
        if (!result) {
          this.question_pc_image_path = '';
        }
        this.question_pc_image = result;
      } else if (type === 'question_mobile') {
        this.question_mobile_image_path = event.target.value;
        const result = await this.uploadImage(event.target.files[0]);
        if (!result) {
          this.question_mobile_image_path = '';
        }
        this.question_mobile_image = result;
      } else if (type === 'answer_pc') {
        this.answer_pc_image_path = event.target.value;
        const result = await this.uploadImage(event.target.files[0]);
        if (!result) {
          this.answer_pc_image_path = '';
        }
        this.answer_pc_image = result;
      } else if (type === 'answer_mobile') {
        this.answer_mobile_image_path = event.target.value;
        const result = await this.uploadImage(event.target.files[0]);
        if (!result) {
          this.answer_mobile_image_path = '';
        }
        this.answer_mobile_image = result;
      }
    },
    clearImage(type = 'icon') {
      if (type === 'icon') {
        this.icon_image = '';
        this.icon_image_path = '';
      } else if (type === 'question_pc') {
        this.question_pc_image = '';
        this.question_pc_image_path = '';
      } else if (type === 'question_mobile') {
        this.question_mobile_image = '';
        this.question_mobile_image_path = '';
      } else if (type === 'answer_pc') {
        this.answer_pc_image = '';
        this.answer_pc_image_path = '';
      } else if (type === 'answer_mobile') {
        this.answer_mobile_image = '';
        this.answer_mobile_image_path = '';
      }
    },
    showImage(type = 'icon') {
      let preview_image = '';
      if (type === 'icon') preview_image = this.icon_image;
      else if (type === 'question_pc') preview_image = this.question_pc_image;
      else if (type === 'question_mobile')
        preview_image = this.question_mobile_image;
      else if (type === 'answer_pc') preview_image = this.answer_pc_image;
      else if (type === 'answer_mobile')
        preview_image = this.answer_mobile_image;
      else return false;

      this.$vfm.show({
        component: ModalImagePreview,
        bind: {
          image: preview_image,
        },
      });
    },
    onlyNumber: function (event, id = 'min') {
      let value = event.target.value.replace(/[^0-9.]/g, '');
      if (value === '') {
        value = 0;
      }

      value = parseInt(value);

      if (id === 'min') {
        this.multiple_select_min = value;
      } else {
        this.multiple_select_max = value;
      }

      event.target.value = value;
    },

    textAreaReSize: function (e) {
      e.target.style.height = 'auto';
      e.target.style.height = e.target.scrollHeight + 'px';
    },
    textAreaEnterNoInput: function (e) {
      e.preventDefault();
    },
    textAreaLengthLimit: function (e, limit) {
      if (e.target.value.length > limit) {
        alert('500글자를 초과 할 수 없습니다.');
        e.preventDefault();
      }
    },
    answerTypeChange: function (value) {
      this.before_answer_type = value;
      this.change(this.subject_type, null);
    },
    inputText: function (e) {
      let value = this.replaceHtml(e.target.value);
      if (e.target.id == 'subject') {
        this.subject = value;
      } else {
        if (e.target.attributes && e.target.attributes.index) {
          let _index = e.target.attributes.index.value;
          this.children[_index].subject = value;
          if (!this.before_children[_index]) {
            this.before_children[_index] = this.children[_index];
          }
          this.before_children[_index].subject = value;
        }
      }
    },
    fileClick: function (e) {
      $(e.target).next('input').click();
    },
    optionTextLength: function (text, length) {
      if (!length || length == 0) {
        length = 25;
      }
      if (text.length > length) {
        return text.substr(0, length) + '...';
      } else {
        return text;
      }
    },
  },
  watch: {
    use_icon: function (val) {
      if (val !== 'enabled') {
        this.icon_image = '';
        this.icon_image_path = '';
      }
    },
    use_image: function (val) {
      if (val !== 'enabled') {
        this.question_pc_image = '';
        this.question_pc_image_path = '';
        this.question_mobile_image = '';
        this.question_mobile_image_path = '';
      }
    },
    answer_type: function (val) {
      if (val !== 'file') {
        this.answer_pc_image = '';
        this.answer_pc_image_path = '';
        this.answer_mobile_image = '';
        this.answer_mobile_image_path = '';
      }
    },
    multiple_select_min: function (val) {
      const value = val.toString();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tbl_noline2 {
  border: 0px;
}
#subject_type_free > li {
  margin-top: 10px;
}
#subject_type_free > li:nth-child(1) {
  margin-top: 0px;
}

.ml5 {
  margin-left: 5px !important;
}

textarea {
  resize: auto;
  overflow: hidden;
}

.textAreaResizeNone {
  resize: none;
}
.question_title {
  max-width: 460px;
  min-width: 460px;
}

.ansert_title {
  max-width: 580px;
  min-width: 580px;
}

.input_hidden {
  width: 63px;
  z-index: -1;
}

.input_wrap {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}

.d-inline-block {
  display: inline-block;
}

.answer_input_image_width {
  width: 350px;
}
</style>
