<template>
  <!-- fullscreen : 추천 상품 설정 - 상품 조회 및 추가 -->
  <section
    id="fullRecomAdd"
    class="fullscreen"
    :class="{ upscreen: this.is_open }"
  >
    <div class="full_header">
      <h2>추천 상품 설정 - 상품 조회 및 추가</h2>
      <button type="button" @click="hidePopup" class="btn_close">
        <span class="material-icons md-white">close</span>
      </button>
      <button type="button" class="btn_close2" @click="hidePopup">
        <span>닫기</span>
      </button>
    </div>

    <div class="full_content">
      <h3 class="tit">추천 상품 조회 및 추가</h3>
      <p>
        추천 상품 설정이 필요한 상품을 조회하여 추가합니다.<br />
        상품을 추가하시면 추천 상품 설정 목록에 추가되며, [Step2 질문 설정]
        [Step4 결과 화면 설정]에서 노출 설정이 가능해집니다.<br />
        ※ 세트상품의 경우 결과 화면에서 장바구니 기능이 지원되지 않습니다.
        결과화면에 노출하여 클릭시 상세페이지로 연결하는 용도로 사용하시길
        권장합니다.
      </p>
      <div class="conbox">
        <div class="search">
          <div class="slt">
            <select v-model="this.search_type">
              <option value="product_name">상품명</option>
              <option value="product_code">상품코드</option>
            </select>
          </div>
          <div class="inputbox">
            <input
              type="text"
              name=""
              v-model="this.search_value"
              @keyup.enter="search('search')"
            />
          </div>
          <div class="btn_group">
            <button
              type="button"
              @click="search('search')"
              class="btn btn_large btn_primary btn_wide"
            >
              조회
            </button>
            <button
              type="button"
              @click="reset"
              class="btn btn_large btn_light ml5"
            >
              초기화
            </button>
          </div>
        </div>
      </div>

      <div class="conbox">
        <div class="clfix mb10">
          <button
            type="button"
            @click="add"
            class="btn btn_small btn_secondary"
          >
            + 선택 상품 추가
          </button>
        </div>
        <table class="tbl_list">
          <caption>
            추천 상품 목록
          </caption>
          <colgroup>
            <col style="width: 4%" />
            <col style="width: 10%" />
            <col style="width: auto" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  @change="allChecked($event)"
                  v-model="all_selected"
                />
              </th>
              <th scope="col">상품이미지</th>
              <th scope="col">상품정보</th>
              <th scope="col">판매가</th>
              <th scope="col">진열상태</th>
              <th scope="col">판매상태</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="
                Array.isArray(this.products) !== true ||
                this.products.length === 0
              "
            >
              <td colspan="6" class="nodata">설정된 상품이 없습니다.</td>
            </tr>
            <tr v-for="(item, i) in this.products" :key="i">
              <td>
                <input
                  type="checkbox"
                  name=""
                  :value="item.product_no"
                  v-model="item.checked"
                  :disabled="item.disabled"
                  @click="checkBoxClick(item.checked, i)"
                />
              </td>
              <td>
                <p v-if="item.images.length > 0" class="thumb new_thumb">
                  <img :src="this.getImage(item.images)" alt="" />
                </p>
                <p v-else class="thumb"></p>
              </td>
              <td class="ta_l customTextStart">
                <strong class="goods_name">{{ item.product_name }}</strong>
                <span class="goods_code">상품코드 {{ item.product_code }}</span>
              </td>
              <td>
                <strong>{{ numberFormat(item.price) }}</strong>
              </td>
              <td v-if="item.display === 'T'">진열함</td>
              <td v-else class="purpleFont">진열안함</td>
              <td v-if="item.selling === 'T'">판매함</td>
              <td v-else class="purpleFont">판매안함</td>
            </tr>
          </tbody>
        </table>

        <Pagination
          v-if="this.paging.total_count > 0"
          :per_page="this.paging.per_page"
          :total_count="this.paging.total_count"
          @page-change="pageChange"
        />

        <br />
      </div>
    </div>
    <!-- //full_content -->
  </section>
  <!-- //fullscreen : 추천 상품 설정 - 상품 조회 및 추가 -->
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';

import Pagination from '@/components/layout/Pagination.vue';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    is_open: false,
    search_type: 'product_name',
    search_value: '',
    products: [],
    all_selected: false,
    paging: {
      offset: 0,
      per_page: 50,
      total_count: 0,
    },
  }),
  components: {
    Pagination,
  },
  props: ['product_id_list'],
  created: async function () {
    this.products = [];
    this.hideScrollbar();
  },
  computed: {
    ...mapState('common', ['survey_config_id']),
    ...mapState('qna_product', ['mall_product_count', 'mall_product_list']),
  },
  mounted() {
    let timer = setTimeout(() => {
      if (this.is_open === true) {
        clearTimeout(timer);
      }

      this.is_open = true;
    }, 100);
  },
  methods: {
    add: async function () {
      if (
        Array.isArray(this.mall_product_list) !== true ||
        this.mall_product_list.length < 0
      ) {
        alert('등록 하실 상품을 선택해주세요.');
        return false;
      }

      let params = [];

      for (const [key, item] of Object.entries(this.products)) {
        if (item.checked !== true) {
          continue;
        }

        params.push({
          survey_config_id: sessionStorage.getItem('survey_survey_config_id'),
          product_no: item.product_no,
          product_name: item.product_name,
        });
      }

      if (Array.isArray(params) !== true || params.length <= 0) {
        alert('등록 하실 상품을 선택해주세요.');
        return false;
      }

      //postProduct
      const result = await this.$store.dispatch(
        'qna_product/postProducts',
        params,
      );
      this.$parent.is_update_product_list = result;
      if (result === true) {
        alert('추천 상품 목록에 추가되었습니다.');

        // 체크박스 및 배열 초기화
        this.all_selected = false;

        this.hasItemCheck();
      } else {
        alert('추천 상품 목록에 추가를 실패하였습니다.');
      }
    },
    pageChange: async function (offset) {
      this.paging.offset = offset;
      await this.search('pageChange');
    },
    search: async function (type) {
      if (this.search_type === '') {
        alert('검색하실 항목을 선택해주세요.');
        return false;
      } else if (this.search_value === '') {
        alert('검색 단어를 입력해주세요.');
        return false;
      }

      // this.showLoading();

      let params = {};

      if (this.search_type === 'product_name') {
        params = {
          product_name: this.search_value,
        };
      } else {
        params = {
          product_code: this.search_value,
        };
      }

      await this.$store.dispatch('qna_product/getMallProductCount', params);

      if (type == 'search') {
        this.paging.offset = 0;
        this.paging.per_page = 50;
      }

      await this.$store.dispatch('qna_product/getMallProductList', {
        search_type: this.search_type,
        search_value: this.search_value,
        offset: this.paging.offset,
        limit: this.paging.per_page,
      });

      // this.products = this.mall_product_list;
      this.products = [];
      for (const product of this.mall_product_list) {
        // 카페24 이미지를 <img> 불러올때 오류 발생으로 수정 ( 도메인명 변경 )
        if (product.images) {
          for (const image of product.images) {
            let _image_href_array = image.image_url.split('/');
            if (
              _image_href_array[3] == 'web' &&
              _image_href_array[4] == 'product'
            ) {
              _image_href_array[2] = `${this.$store.state.common.mall_id}.cafe24.com`;
            }
            image.image_url = _image_href_array.join('/');
          }
        }

        product.product_name = this.replaceHtml(product.product_name);
        this.products.push(product);
      }
      this.products.reverse();

      this.paging.total_count = this.mall_product_count;

      this.hasItemCheck(this.product_id_list);

      // this.hideLoading();
    },
    reset() {
      this.search_type = 'product_name';
      this.search_value = '';
      this.products = [];
    },
    getImage(images) {
      if (!(Array.isArray(images) && images.length > 0)) return images;
      return images[0].image_url;
    },
    hasItemCheck(hasItems = []) {
      for (const [key, item] of Object.entries(this.products)) {
        if (Array.isArray(hasItems) && hasItems.length > 0) {
          if (hasItems.indexOf(this.products[key].product_no) >= 0) {
            this.products[key].disabled = true;
            this.products[key].checked = false;
          }
        } else {
          if (item.checked !== true) {
            continue;
          }

          this.product_id_list.push(item.product_no);

          this.products[key].disabled = true;
          this.products[key].checked = false;
        }
      }
    },
    allChecked(e) {
      let is_checked_count = 0;
      if (this.products.length == 0) {
        e.target.checked = false;
        alert('선택된 상품이 없습니다.');
        return false;
      }

      this.all_selected = e.target.checked;

      for (let i in this.products) {
        if (this.products[i].disabled) {
          continue;
        }
        is_checked_count += 1;
        this.products[i].checked = this.all_selected;
      }

      if (is_checked_count == 0) {
        this.all_selected = false;
        alert('선택된 상품이 없습니다.');
        return false;
      }
    },
    hidePopup() {
      this.is_open = false;
      this.showScrollbar();

      let timer = setTimeout(() => {
        if (this.is_open === true) {
          clearTimeout(timer);
        }

        this.$emit('hidePopup', 'add');
      }, 500);
    },
    checkBoxClick: function (param, index) {
      let total_count = this.products.length;
      let check_count = 0;
      let disabled_count = 0;
      for (let i in this.products) {
        if (Number(i) == Number(index)) {
          this.products[i].checked = !param;
        }
        if (this.products[i].checked) {
          check_count += 1;
        }

        if (this.products[i].disabled) {
          disabled_count += 1;
        }
      }
      if (this.all_selected === true && check_count != total_count) {
        this.all_selected = false;
      }
      if (this.all_selected === false && check_count == total_count) {
        this.all_selected = true;
      }

      let _count = check_count + disabled_count;

      if (this.all_selected === false && total_count == _count) {
        this.all_selected = true;
      }
      if (this.all_selected === true && total_count != _count) {
        this.all_selected = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upscreen {
  transform: none;
}

.paginate {
  min-height: 10px;
}

.btn_close2 {
  position: absolute;
  top: 13px;
  right: 20px;
  color: #fff;
}

.new_thumb::before,
.new_thumb::after {
  background-color: #fff !important;
}

.purpleFont {
  color: #8041d9;
}
</style>
