<template>
  <!-- container -->
  <section id="container">
    <div class="content">
      <h2 class="title">부가서비스</h2>
      <p class="dsc">
        내 쇼핑몰에 적합한 맞춤 서비스가 가능합니다.<br />맞춤 질문 추천 서비스
        관련 화면 및 기능 설정 관련한 커스텀 제작이 가능합니다.<br />샵플러스
        고객센터 (0505-277-7770)를 통해 문의하시면 친절하게 상담해드립니다.
      </p>

      <div class="conbox">
        <h3 class="tit">01. 쇼핑몰 화면 커스텀 제작</h3>
        <p>
          PC와 모바일 쇼핑몰에 적합한 화면을 쇼핑몰에 맞게 별도 디자인한
          화면으로 제작이 가능합니다.
        </p>
      </div>

      <div class="conbox">
        <h3 class="tit">02. 쇼핑몰 기능 커스텀 제작</h3>
        <p>
          쇼핑몰 특성에 맞게 별도 커스텀 기능이 필요한 경우 제작이 가능합니다.
        </p>
      </div>

      <div class="btn_wrap">
        <button
          type="button"
          @click="inquiry"
          class="btn btn_large btn_primary"
        >
          부가서비스 신청하기
        </button>
      </div>
    </div>
    <!-- //content -->
  </section>
  <!-- //container -->
</template>
<script>
export default {
  methods: {
    inquiry: function () {
      window.open(
        'https://docs.google.com/forms/d/e/1FAIpQLSeBYyHpdQ471psiid2eGbd2JbAJywahwc7HGeXCJ1UE8DxTrQ/viewform',
      );
    },
  },
};
</script>

<style scoped></style>
