<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="showModal"
    :click-to-close="true"
  >
    <section class="layer_dialog">
      <img
        v-if="this.image"
        :src="this.image"
        class="modal_preview_img"
        alt="Image Preview"
      />
      <div class="btns">
        <ul>
          <li>
            <button
              @click="this.showModal = false"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
  </vue-final-modal>
</template>

<script>
export default {
  inheritAttrs: false,
  data: () => ({
    showModal: true,
  }),
  props: {
    image: String,
  },
  created: function () {
    //console.log('image : ', this.image);
  },
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layer_dialog .btns > ul > li {
  width: 100%;
}

.modal_preview_img {
  max-width: 520px;
}
</style>
