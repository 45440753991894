import APIEntry from '@/api/APIEntry.js';
import axios from 'axios';
import store from '../store';

/**
 * Global mapping interfaces
 */
export default Object.freeze({
  /**
   * api entry 매칭 시키기
   */
  entry: function () {
    if (!arguments.length) {
      return null;
    }

    const args = Array.from(arguments);
    const entry = args.shift();
    const match = store.state.entries[entry];

    if (typeof match === undefined) {
      return null;
    }

    const mall_id = store.state.common.mall_id;
    const app_code = store.state.app_code;
    const shop_no = sessionStorage.getItem('survey_shop_no') || 1;
    const survey_config_id = sessionStorage.getItem('survey_survey_config_id');

    if (match.requireAuth && (!mall_id || !app_code)) {
      return null;
    }

    const merged_args = [app_code, mall_id, shop_no, survey_config_id].concat(
      args,
    );
    let uri = match.path;
    merged_args.forEach((item, index) => {
      uri = uri.replace(`{${index}}`, item);
    });

    return new APIEntry(uri, match.method, match.target);
  },
  /**
   * api request
   * @param {APIEntry} entry API URI 맵핑 객체
   * @param {Object} payload 요청 본문 파라미터
   */
  api: async function (entry, payload) {
    if (entry === null) {
      throw new Error("'entry' parameter is required.");
    }

    // status code 확인 하여 토큰 갱신 처리
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.Authorization =
      sessionStorage.getItem('access_token');

    await axios.interceptors.response.use(null, async error => {
      console.error('error.response', error);
      if (error.response.status === 401) {
        await store.dispatch('common/getRefreshToken');
        error.config.headers.Authorization =
          sessionStorage.getItem('access_token');
        return axios.request(error.config);
      }
      return error;
    });

    let statement = {
      baseURL: store.state.api_url,
      url: entry.getPath(),
      method: entry.getMethod(),
    };

    if (payload) {
      if (['POST', 'DELETE', 'PUT'].includes(entry.getMethod())) {
        statement.data = payload;
      } else {
        statement.params = payload;
      }
    }

    let res = await axios(statement);
    return res.data;
  },
});
