import actions from './actions';
import mutations from './mutations';

const state = {
  mall_id: '',
  shop_no: 1,
  access_token: '',
  refresh_token: '',
  survey_config_id: 0,
  store: [],
  mall_list: [],
  survey_mall_list: [],
  membership_list: [],
  design_info: {},
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters: {
    getSurveyConfigId: function (state) {
      return state.survey_config_id;
    },
  },
};
