import dayjs from 'dayjs';

const mutations = {
  setOrderType(state, payload) {
    state.payment_type_list = payload;
  },
  setOrderList(state, payload) {
    state.payment_list = payload;
  },
  setOrderLatest(state, payload) {
    state.payment_mall_info = payload;
  },
  setExpire(state, payload) {
    state.expire = payload;
    if (typeof payload == 'undefined') {
      alert(
        'Session이 종료되었거나, 비정상적인 접속입니다.\n쇼핑몰의 소중한 정보보호 및 보안 강화를 위하여 앱스토어 > 마이 앱으로 이동합니다.',
      );
      let mall_id = sessionStorage.getItem('mall_id');
      location.replace(
        `https://${mall_id}.cafe24.com/disp/admin/shop1/myapps/list#none`,
      );
      return false;
    }
    if (payload.is_expired === false) {
      let today = dayjs();
      let expired_at = dayjs(payload.expired_at);
      let result = expired_at.diff(today, 'day', true);
      state.expire.days = Math.ceil(result);
    } else {
      state.expire.days = 0;
    }
  },
};

export default mutations;
