<template>
  <div v-show="is_show">
    <!-- container -->
    <section id="container">
      <div class="content">
        <h2 class="title">맞춤 질문 설정</h2>
        <p class="dsc">
          쇼핑몰 고객들에게 제공할 맞춤 질문 설정 및 관리를 합니다.<br />
          수집한 데이터를 기반으로 해당 고객에게 적합한 상품 추천이
          가능합니다.<br />
          아래 1~4 스텝별 메뉴를 클릭하여 순서대로 설정해주세요.
        </p>

        <div class="step">
          <ul>
            <li>
              <em><a v-bind:href="`/qna/product`">1</a></em>
              <router-link to="/qna/product">추천 상품 설정</router-link>
            </li>
            <li>
              <em><a v-bind:href="`/qna/setting`">2</a></em>
              <router-link to="/qna/setting">질문 설정</router-link>
            </li>
            <li>
              <em><a v-bind:href="`/qna/config`">3</a></em>
              <router-link to="/qna/config">화면 관련 설정</router-link>
            </li>

            <li class="on">
              <em>4</em>
              결과 화면 설정
            </li>
          </ul>
        </div>

        <div class="conbox">
          <h3 class="tit">결과 로딩 화면</h3>
          <table class="tbl_write">
            <caption>
              결과 로딩 화면
            </caption>
            <colgroup>
              <col style="width: 20%" />
              <col style="width: 80%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">로딩 문구</th>
                <td>
                  <div class="clfix">
                    <textarea
                      id="result_loading_text"
                      v-model="result_loading_text"
                      rows="3"
                      style="width: 550px"
                      class="fl_l"
                      @keyup="inputText($event)"
                    ></textarea>
                    <!--input
                      type="text"
                      name=""
                      v-model="result_loading_text"
                      autocomplete="off"
                      placeholder="결과를 분석중입니다."
                      style="width: 550px"
                      class="fl_l"
                    /-->
                    <div class="color fl_l ml10">
                      <label for="loadingTextColor"
                        ><input
                          type="text"
                          class="minicolors"
                          v-model="result_loading_text_color"
                          autocomplete="off"
                          id="loadingTextColor"
                          maxlength="7"
                          @input="
                            onlyHtmlColor($event, 'result_loading_text_color')
                          "
                        /><span class="material-icons notranslate"
                          >color_lens</span
                        ></label
                      >
                      <span
                        class="color_preview_box"
                        :style="getBackgroundColor(result_loading_text_color)"
                      ></span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">로딩바 색상</th>
                <td>
                  <div class="color">
                    <label for="loadingProgressBarColor"
                      ><input
                        type="text"
                        class="minicolors"
                        autocomplete="off"
                        v-model="result_progress_bar_color"
                        id="loadingProgressBarColor"
                        maxlength="7"
                        @input="
                          onlyHtmlColor($event, 'result_progress_bar_color')
                        "
                      /><span class="material-icons notranslate"
                        >color_lens</span
                      ></label
                    >
                    <span
                      class="color_preview_box"
                      :style="getBackgroundColor(result_progress_bar_color)"
                    ></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">배경 이미지</th>
                <td>
                  <!-- 사용함 선택 시 노출 -->
                  <div class="option">
                    <dl>
                      <dt class="narrow" style="width: 100px">PC</dt>
                      <dd>
                        <!-- file upload -->
                        <div class="upload fl_l">
                          <span
                            class="material-icons notranslate md-light ico_file"
                            >attach_file</span
                          >
                          <input
                            type="text"
                            id="addFile_2"
                            class="input_read"
                            readonly="readonly"
                            v-model="result_pc_bg_image_path"
                            placeholder="940px * 680px 권장 / 5M 이하 / gif, png, jpg(jpeg)"
                          />
                          <span
                            class="input_wrap"
                            v-if="result_pc_bg_image_path === ''"
                          >
                            <input
                              type="button"
                              class="btn btn_small btn_light pointer"
                              value="업로드"
                              title="업로드"
                              @click="fileClick($event)"
                            /><input
                              type="file"
                              class="input_hidden"
                              @change="changeImage($event, 'pc')"
                            />
                          </span>
                        </div>
                        <!-- 업로드 후 -->
                        <span
                          v-if="result_pc_bg_image_path"
                          class="upload_view ml5"
                        >
                          <button
                            type="button"
                            @click="showImagePreview('pc')"
                            class="btn btn_small btn_secondary mr10"
                          >
                            <span>이미지 보기</span>
                          </button>
                          <button type="button" @click="clearImage('pc')">
                            <span class="material-icons notranslate md-light"
                              >cancel</span
                            >
                          </button>
                        </span>
                        <!-- //file upload -->
                      </dd>
                    </dl>

                    <dl>
                      <dt class="narrow" style="width: 100px">모바일</dt>
                      <dd>
                        <!-- file upload -->
                        <div class="upload fl_l">
                          <span
                            class="material-icons notranslate md-light ico_file"
                            >attach_file</span
                          >
                          <input
                            type="text"
                            id="addFile_3"
                            class="input_read"
                            readonly="readonly"
                            v-model="result_mobile_bg_image_path"
                            placeholder="360px * 640px 권장 / 5M 이하 / gif, png, jpg(jpeg)"
                          />
                          <span
                            class="input_wrap"
                            v-if="result_mobile_bg_image_path === ''"
                          >
                            <input
                              type="button"
                              class="btn btn_small btn_light pointer"
                              value="업로드"
                              title="업로드"
                              @click="fileClick($event)"
                            /><input
                              type="file"
                              class="input_hidden"
                              @change="changeImage($event, 'mobile')"
                            />
                          </span>
                        </div>
                        <span
                          v-if="result_mobile_bg_image_path"
                          class="upload_view ml5"
                        >
                          <button
                            type="button"
                            @click="showImagePreview('mobile')"
                            class="btn btn_small btn_secondary mr10"
                          >
                            <span>이미지 보기</span>
                          </button>
                          <button type="button" @click="clearImage('mobile')">
                            <span class="material-icons notranslate md-light"
                              >cancel</span
                            >
                          </button>
                        </span>
                        <!-- //file upload -->
                      </dd>
                    </dl>
                  </div>
                  <!-- //사용함 선택 시 노출 -->

                  <p class="txt_comt mt20">
                    ※ 이미지 미등록시 3.화면 관련 설정 메뉴에서 설정한 질문 박스
                    배경 색상이 노출됩니다
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="btn_wrap right">
            <button
              type="button"
              class="btn btn_large btn_secondary btn_wide"
              @mouseover="previewTip(1, 'pc')"
              @mouseleave="previewTip(0, 'pc')"
              @click="previewOpen('pc_loading')"
            >
              <span>PC 미리보기</span>
            </button>
            <button
              type="button"
              class="btn btn_large btn_secondary btn_wide ml5"
              @mouseover="previewTip(1, 'mobile')"
              @mouseleave="previewTip(0, 'mobile')"
              @click="previewOpen('mobile_loading')"
            >
              <span>Mobile 미리보기</span>
            </button>
            <div ref="preview_tip" class="pc_loading_tip">
              [앱 어드민 > 쇼핑몰 설정] 메뉴의 가이드에 따라<br />
              html 파일을 설정해주셔야 합니다.<br />
              <br />
              버튼을 클릭하시면 미리보기 화면으로 연결됩니다.
            </div>
            <div ref="mobile_preview_tip" class="mobile_loading_tip">
              [앱 어드민 > 쇼핑몰 설정] 메뉴의 가이드에 따라<br />
              html 파일을 설정해주셔야 합니다.<br />
              <br />
              버튼을 클릭하시면 미리보기 화면으로 연결됩니다.
            </div>
            <button
              type="button"
              @click="setConfigResultInfo"
              class="btn btn_large btn_primary btn_wide ml5"
            >
              <span>저장하기</span>
            </button>
          </div>
        </div>

        <div class="conbox">
          <h3 class="tit">결과 화면 설정 (PC)</h3>
          <p class="mb10">
            <strong>아래 버튼을 클릭하여 화면을 만들어주세요.</strong>
          </p>
          <ul class="mb20">
            <li>
              - 이름, 나이, 성별을 질문에 설정하신 경우 화면 노출이 가능합니다.
            </li>
            <li>
              - 텍스트를 추가하여 $이름$, $나이$, $성별$ 형식으로 넣으시면
              되오니 참고해주세요.
            </li>
            <li>
              - 미리보기의 경우 [쇼핑몰 설정] 메뉴를 참고하여 survey.html을
              만드셔야 확인이 가능합니다.
            </li>
            <li>
              - 설정하신 항목들의 순서는 마우스 드래그 앤 드롭으로 이동하여
              설정이 가능합니다.
            </li>
          </ul>

          <div class="result_wrap mobile">
            <div class="result">
              <ul class="btn_group">
                <li>
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultText', 'desktop')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 텍스트</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultImage', 'desktop')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 이미지</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultBlank', 'desktop')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 여백</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultRecommend', 'desktop')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 추천 상품</span>
                  </button>
                </li>
                <li class="wide">
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultShowItems', 'desktop')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 추천 상품과 연결된 '노출항목'</span>
                  </button>
                </li>
                <li class="wide">
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultCartButton', 'desktop')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 모두 장바구니 담기 버튼</span>
                  </button>
                </li>
              </ul>

              <ul class="result_list mobile_result_list sortable">
                <draggable
                  v-model="result_desktop_items"
                  tag="transition-group"
                  item-key="_id"
                >
                  <template #item="{ element }">
                    <li v-if="element.type === 'text'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultText', 'desktop', {
                            type: element.type,
                            text: element.text,
                            color: element.color,
                            font_size: element.font_size,
                            font_bold: element.font_bold,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem2('desktop', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'image'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultImage', 'desktop', {
                            type: element.type,
                            image: element.image,
                            image_path: element.image_path,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem2('desktop', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'blank'" class="blank">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultBlank', 'desktop', {
                            type: element.type,
                            blank: element.blank,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem2('desktop', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'recommend_goods'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultRecommend', 'desktop', {
                            recommend_goods: element,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem2('desktop', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'show_items'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultShowItems', 'desktop', {
                            show_item: element,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem2('desktop', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'cart_button'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultCartButton', 'desktop', {
                            cart_button: element,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem2('desktop', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else></li>
                  </template>
                </draggable>
              </ul>
            </div>
          </div>

          <div class="btn_wrap right">
            <button
              type="button"
              class="btn btn_large btn_secondary btn_wide"
              @mouseover="resultPreviewTip(1, 'p')"
              @mouseleave="resultPreviewTip(0, 'p')"
              @click="previewOpen('pc_result')"
            >
              <span>미리보기</span>
            </button>
            <div ref="preview_tip2" class="tip2">
              [앱 어드민 > 쇼핑몰 설정] 메뉴의 가이드에 따라<br />
              html 파일을 설정하시고<br />
              결과 화면 설정이 되어 있어야 정상적으로 미리보기가 가능합니다.<br />
              <br />
              버튼을 클릭하시면 미리보기 화면으로 연결됩니다.
            </div>
            <button
              type="button"
              @click="saveDesktop"
              class="btn btn_large btn_primary btn_wide ml5"
            >
              <span>저장하기</span>
            </button>
          </div>
        </div>

        <div class="conbox">
          <h3 class="tit">결과 화면 설정 (Mobile)</h3>
          <p class="mb10">
            <strong
              >아래 버튼을 클릭하여 화면을 만들어주세요.<br />
              PC와 동일하게 만드실 경우 아래 버튼을 클릭하시면 동일한 설정으로
              기본 세팅이 됩니다.<br />
              다만, 모바일 화면 사이즈상 PC에서 설정하신 문구, 이미지등이
              모바일과 다르게 나올 수 있으니 별도 설정을 하여 사용하시길
              권장합니다.</strong
            >
          </p>
          <ul class="mb20">
            <li>
              - 이름, 나이, 성별을 질문에 설정하신 경우 화면 노출이 가능합니다.
            </li>
            <li>
              - 텍스트를 추가하여 $이름$, $나이$, $성별$ 형식으로 넣으시면
              되오니 참고해주세요.
            </li>
            <li>
              - 미리보기의 경우 [쇼핑몰 설정] 메뉴를 참고하여 모바일쇼핑몰
              survey.html을 만드셔야 확인이 가능합니다.
            </li>
            <li>
              - 설정하신 항목들의 순서는 마우스 드래그 앤 드롭으로 이동하여
              설정이 가능합니다.
            </li>
          </ul>

          <div class="btn_wrap">
            <button
              type="button"
              @click="copyDesktop"
              class="btn btn_large btn_wide btn_purple"
            >
              <span>PC 설정과 동일하게 적용</span>
            </button>
          </div>

          <div class="result_wrap mobile">
            <div class="result">
              <ul class="btn_group">
                <li>
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultText', 'mobile')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 텍스트</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultImage', 'mobile')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 이미지</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultBlank', 'mobile')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 여백</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultRecommend', 'mobile')"
                    class="btn btn_large btn_secondary btn_dark"
                  >
                    <span>+ 추천 상품</span>
                  </button>
                </li>
                <li class="wide">
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultShowItems', 'mobile')"
                    class="btn btn_large btn_secondary btn_dark btn_wide"
                  >
                    <span>+ 추천 상품과 연결된 '노출항목'</span>
                  </button>
                </li>
                <li class="wide">
                  <button
                    type="button"
                    @click="showModal('ModalQnaResultCartButton', 'mobile')"
                    class="btn btn_large btn_secondary btn_dark btn_wide"
                  >
                    <span>+ 모두 장바구니 담기 버튼</span>
                  </button>
                </li>
              </ul>

              <ul class="result_list mobile_result_list sortable">
                <draggable
                  v-model="result_mobile_items"
                  tag="transition-group"
                  item-key="_id"
                >
                  <template #item="{ element }">
                    <li v-if="element.type === 'text'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultText', 'mobile', {
                            type: element.type,
                            text: element.text,
                            color: element.color,
                            font_size: element.font_size,
                            font_bold: element.font_bold,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem('mobile', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'image'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultImage', 'mobile', {
                            type: element.type,
                            image: element.image,
                            image_path: element.image_path,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem('mobile', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'blank'" class="blank">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultBlank', 'mobile', {
                            type: element.type,
                            blank: element.blank,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem('mobile', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'recommend_goods'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultRecommend', 'mobile', {
                            recommend_goods: element,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem('mobile', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'show_items'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultShowItems', 'mobile', {
                            show_item: element,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem('mobile', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                    <li v-else-if="element.type === 'cart_button'">
                      <a
                        href="javascript:;"
                        @click="
                          showModal('ModalQnaResultCartButton', 'mobile', {
                            cart_button: element,
                            index: element._id,
                          })
                        "
                      >
                        <span>{{ element._name }}</span>
                      </a>
                      <button
                        type="button"
                        @click="deleteItem('mobile', element._id)"
                        class="btn_del"
                      >
                        <span
                          class="material-icons notranslate md-median md-light"
                          >cancel</span
                        >
                      </button>
                    </li>
                  </template>
                </draggable>
              </ul>
            </div>
          </div>

          <div class="btn_wrap right mobile_result_btn">
            <button
              type="button"
              class="btn btn_large btn_secondary btn_wide"
              @mouseover="resultPreviewTip(1, 'm')"
              @mouseleave="resultPreviewTip(0, 'm')"
              @click="previewOpen('mobile_result')"
            >
              <span>미리보기</span>
            </button>
            <div ref="preview_tip3" class="tip3">
              [앱 어드민 > 쇼핑몰 설정] 메뉴의 가이드에 따라<br />
              html 파일을 설정하시고<br />
              결과 화면 설정이 되어 있어야 정상적으로 미리보기가 가능합니다.<br />
              <br />
              버튼을 클릭하시면 미리보기 화면으로 연결됩니다.
            </div>
            <button
              type="button"
              @click="saveMobile"
              class="btn btn_large btn_primary btn_wide ml5"
            >
              <span>저장하기</span>
            </button>
          </div>
        </div>
      </div>
      <!-- //content -->
    </section>
    <!-- //container -->

    <ModalsContainer />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapState } from 'vuex';
import { ModalsContainer } from 'vue-final-modal';

import common from '@/components/mixin/index.js';
import draggable from 'vuedraggable';
import ModalImagePreview from '@/components/qna/modal/ModalImagePreview.vue';
// import ModalQnaResultText from '@/components/qna/modal/ModalQnaResultText.vue';

export default {
  mixins: [common],
  data: () => ({
    result_loading_text: '',
    result_loading_text_color: '',
    result_progress_bar_color: '',
    result_pc_bg_image: '',
    result_pc_bg_image_path: '',
    result_mobile_bg_image: '',
    result_mobile_bg_image_path: '',
    result_desktop_items: [],
    result_mobile_items: [],
    is_show: false,
    mobile_skin_code: null,
  }),
  components: {
    ModalsContainer,
    // eslint-disable-next-line vue/no-unused-components
    ModalImagePreview,
    draggable,
    // ModalQnaResultText,
  },
  computed: {
    ...mapState('qna_config', ['qna_config']),
    ...mapState('common', [
      'shop_no',
      'store',
      'mall_list',
      'survey_mall_list',
      'design_info',
    ]),
    ...mapState('qna_product', ['products']),
  },
  created: async function () {
    await this.init();
    this.is_show = true;
  },
  mounted() {
    this.$nextTick(function () {
      $('.minicolors').minicolors({
        animationEasing: 'swing',
        keywords: '#',

        change: (hex, opacity) => {
          const target = $('.minicolors').find('input');
          for (const item of target) {
            if ($(item).val() === hex) {
              const id = $(item).prop('id');

              if (id === 'loadingTextColor')
                this.result_loading_text_color = hex;
              if (id === 'loadingProgressBarColor')
                this.result_progress_bar_color = hex;

              break;
            }
          }
        },
      });
    });
  },
  methods: {
    init: async function () {
      await this.$store.dispatch('qna_config/getConfig');

      await this.$store.dispatch('qna_product/getProducts', {
        offset: 0,
        limit: 1,
      });

      if (
        !this.qna_config.result_loading_text &&
        !this.qna_config.result_loading_text_color &&
        (!this.qna_config.result_progress_bar_color ||
          this.qna_config.result_progress_bar_color == '#')
      ) {
        this.qna_config.result_loading_text = '결과를 분석중입니다.';
        this.qna_config.result_loading_text_color = '#333333';
        this.qna_config.result_progress_bar_color = '#ff5c35';
      }

      this.result_loading_text = this.qna_config.result_loading_text;
      this.result_loading_text_color =
        this.qna_config.result_loading_text_color;
      this.result_progress_bar_color =
        this.qna_config.result_progress_bar_color;

      this.result_pc_bg_image = this.qna_config.result_desktop_bg;
      this.result_pc_bg_image_path = this.qna_config.result_desktop_bg;
      this.result_mobile_bg_image = this.qna_config.result_mobile_bg;
      this.result_mobile_bg_image_path = this.qna_config.result_mobile_bg;

      $('#loadingTextColor').minicolors(
        'value',
        this.result_loading_text_color,
      );
      $('#loadingProgressBarColor').minicolors(
        'value',
        this.result_progress_bar_color,
      );

      if (
        this.qna_config &&
        this.qna_config.result_desktop_config &&
        Object.keys(this.qna_config.result_desktop_config).length !== 0
      ) {
        this.result_desktop_items = JSON.parse(
          this.qna_config.result_desktop_config,
        );
      }

      if (
        this.qna_config &&
        this.qna_config.result_mobile_config &&
        Object.keys(this.qna_config.result_mobile_config).length !== 0
      ) {
        this.result_mobile_items = JSON.parse(
          this.qna_config.result_mobile_config,
        );
      }

      await this.$store.dispatch('common/getSkinCode', {
        type: 'mobile',
      });
      this.setMobileUrl();
    },

    setMobileUrl: function () {
      const shop_no = sessionStorage.getItem('survey_shop_no');
      for (let i = 0; i < this.mall_list.length; i++) {
        if (this.mall_list[i].shop_no == shop_no) {
          for (let j = 0; j < this.design_info.length; j++) {
            if (
              Number(this.design_info[j].skin_no) ==
                Number(this.mall_list[i].mobile_skin_no) &&
              Number(shop_no) > 1
            ) {
              this.mobile_skin_code = this.design_info[j].skin_code;
            }
          }
        }
      }
    },
    copyDesktop: function () {
      if (
        !confirm(`
PC 화면에 설정한 정보로 모두 변경되므로
모바일에 맞게 변경한 정보가 있다면
확인 후 진행하시길 권장합니다.

PC 설정과 동일하게 변경하시겠습니까?`)
      ) {
        alert('취소하였습니다.');
        return false;
      }

      this.result_mobile_items = JSON.parse(
        JSON.stringify(this.result_desktop_items),
      );

      alert('적용되었습니다.');
    },
    setConfigResultInfo: async function (type) {
      if (this.result_loading_text === '') {
        alert('결과 로딩 문구를 입력해주세요.');
        return false;
      } else if (
        this.result_loading_text_color === '' ||
        this.result_loading_text_color === '#'
      ) {
        alert('결과 로딩 문구 색상을 지정해주세요.');
        return false;
      } else if (
        this.result_progress_bar_color === '' ||
        this.result_progress_bar_color === '#'
      ) {
        alert('결과 로딩바 색상을 지정해주세요.');
        return false;
      }

      this.qna_config.result_desktop_bg = this.result_pc_bg_image;
      this.qna_config.result_mobile_bg = this.result_mobile_bg_image;

      let image = {};

      if (
        this.result_pc_bg_image &&
        this.result_pc_bg_image !== '' &&
        this.result_pc_bg_image.indexOf('http') == -1
      ) {
        //제품 이미지 업로드
        image = {
          image: this.result_pc_bg_image,
        };

        this.result_pc_bg_image = await this.$store.dispatch(
          'common/postImage',
          image,
        );
        this.result_pc_bg_image_path = this.result_pc_bg_image;
        this.qna_config.result_desktop_bg = this.result_pc_bg_image;
      }

      if (
        this.result_mobile_bg_image &&
        this.result_mobile_bg_image !== '' &&
        this.result_mobile_bg_image.indexOf('http') == -1
      ) {
        //제품 이미지 업로드
        image = {
          image: this.result_mobile_bg_image,
        };

        this.result_mobile_bg_image = await this.$store.dispatch(
          'common/postImage',
          image,
        );
        this.result_mobile_bg_image_path = this.result_mobile_bg_image;
        this.qna_config.result_mobile_bg = this.result_mobile_bg_image;
      }

      this.qna_config.result_loading_text = this.result_loading_text;
      this.qna_config.result_loading_text_color =
        this.result_loading_text_color;
      this.qna_config.result_progress_bar_color =
        this.result_progress_bar_color;

      if (type == 'preview') {
        const params = this.qna_config;
        await this.$store.dispatch('qna_config/postData', params);
        return true;
      }

      const params = this.qna_config;
      const result = await this.$store.dispatch('qna_config/postData', params);

      if (result === true) {
        alert('저장되었습니다.');
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
    saveDesktop: async function (type) {
      for (const [key, item] of Object.entries(this.result_desktop_items)) {
        if (
          item.type === 'image' &&
          item.image !== '' &&
          item.image.indexOf('http') == -1
        ) {
          let image = {
            image: item.image,
          };

          const image_url = await this.$store.dispatch(
            'common/postImage',
            image,
          );

          this.result_desktop_items[key].image = image_url;
          this.result_desktop_items[key].image_path = image_url;
        }
      }

      let params = this.qna_config;
      params.result_desktop_config = JSON.stringify(this.result_desktop_items);
      if (type == 'preview') {
        let _params = this.qna_config;
        return await this.$store.dispatch('qna_config/postData', params);
      }

      const result = await this.$store.dispatch('qna_config/postData', params);

      if (result === true) {
        alert('저장되었습니다.');
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
    saveMobile: async function (type) {
      for (const [key, item] of Object.entries(this.result_mobile_items)) {
        if (
          item.type === 'image' &&
          item.image !== '' &&
          item.image.indexOf('http') == -1
        ) {
          let image = {
            image: item.image,
          };

          const image_url = await this.$store.dispatch(
            'common/postImage',
            image,
          );

          this.result_mobile_items[key].image = image_url;
          this.result_mobile_items[key].image_path = image_url;
        }
      }

      let params = this.qna_config;
      params.result_mobile_config = JSON.stringify(this.result_mobile_items);
      if (type == 'preview') {
        return await this.$store.dispatch('qna_config/postData', params);
      }
      const result = await this.$store.dispatch('qna_config/postData', params);

      if (result === true) {
        alert('저장되었습니다.');
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
    deleteItem: function (type, index) {
      if (!confirm('삭제하시겠습니까?')) {
        alert('취소하였습니다.');
        return false;
      }

      if (type === 'desktop') {
        this.result_desktop_items.splice(index, 1);
      } else if (type === 'mobile') {
        this.result_mobile_items.splice(index, 1);
      }
    },
    deleteItem2: function (type, id) {
      if (!confirm('삭제하시겠습니까?')) {
        alert('취소하였습니다.');
        return false;
      }

      let key = 0;
      if (type === 'desktop') {
        key = this.result_desktop_items.findIndex(obj => obj._id === id);
        if (key !== -1) {
          this.result_desktop_items.splice(key, 1);
        }
      } else {
        key = this.result_mobile_items.findIndex(obj => obj._id === id);
        if (key !== -1) {
          this.result_mobile_items.splice(key, 1);
        }
      }
    },
    showModal(id = String | null, type, item = null) {
      if (id === null || id === '') {
        return;
      }

      // + 추천 상품과 연결된 '노출항목' 선택시
      // 추천 상품이 없으면 alert 메시지를 띄운다.
      // 2021.09.17, 라울 요청

      if (
        id === 'ModalQnaResultShowItems' ||
        id === 'ModalQnaResultRecommend'
      ) {
        if (
          this.products &&
          (this.products.total_count == 0 || this.products.list.length == 0)
        ) {
          if (item && item.index) {
            if (
              confirm(
                "[1. 추천 상품 설정] 메뉴에 추가한 상품이 삭제되어 있습니다.\n재설정을 해주세요.\n확인 버튼을 클릭하면 해당 '추천 상품' 설정이 삭제되며 재설정을 할 수 있습니다.",
              )
            ) {
              //
            } else {
              return false;
            }
          } else {
            alert(
              '[1. 추천 상품 설정] 메뉴에서 추천 상품을 등록 후 설정해주세요.',
            );
            return false;
          }
        }
      }

      this.$vfm.show({
        component: defineAsyncComponent(() =>
          import(`@/components/qna/modal/${id}.vue`),
        ),
        bind: {
          type: type,
          item: item,
          result_desktop_items: this.result_desktop_items,
          result_mobile_items: this.result_mobile_items,
          survey_config_info: this.qna_config,
        },
        on: {
          updateList: (type, items) => {
            if (type === 'desktop') {
              this.result_desktop_items = items;
            } else {
              this.result_mobile_items = items;
            }
          },
        },
      });
    },
    previewTip: function (type, device) {
      if (device == 'pc') {
        if (type === 1) {
          this.$refs.preview_tip.style.display = 'block';
        } else {
          this.$refs.preview_tip.style.display = 'none';
        }
      } else {
        if (type === 1) {
          this.$refs.mobile_preview_tip.style.display = 'block';
        } else {
          this.$refs.mobile_preview_tip.style.display = 'none';
        }
      }
    },
    resultPreviewTip: function (type, target) {
      if (type === 1) {
        if (target == 'p') {
          this.$refs.preview_tip2.style.display = 'block';
        } else {
          this.$refs.preview_tip3.style.display = 'block';
        }
      } else {
        if (target == 'p') {
          this.$refs.preview_tip2.style.display = 'none';
        } else {
          this.$refs.preview_tip3.style.display = 'none';
        }
      }
    },
    previewOpen: async function (type) {
      let _baseUrl = this.getBaseUrl();
      if (type == 'pc_loading') {
        let _result = await this.setConfigResultInfo('preview');
        if (_result == true) {
          window.open(
            'http://' + _baseUrl + '/survey.html?preview=Y&type=loading',
          );
        }
        return;
      }

      if (type == 'mobile_loading') {
        let _result = await this.setConfigResultInfo('preview');
        if (_result == true && this.mobile_skin_code) {
          window.open(
            'http://skin-' +
              this.mobile_skin_code +
              '--' +
              _baseUrl +
              '/survey.html?preview=Y&type=loading',
            'survey_mobile_privew_loading',
            'width = 360, height = 620,',
          );
          return;
        }
        if (_result == true) {
          window.open(
            'http://m.' + _baseUrl + '/survey.html?preview=Y&type=loading',
            'survey_mobile_privew_loading',
            'width = 360, height = 620,',
          );
          return;
        }
      }
      if (type == 'pc_result') {
        let _result = await this.saveDesktop('preview');
        if (_result == true) {
          window.open(
            'http://' + _baseUrl + '/survey.html?preview=Y&type=result',
          );
        }
      }
      if (type == 'mobile_result') {
        let _result = await this.saveMobile('preview');

        if (_result == true && this.mobile_skin_code) {
          window.open(
            'http://skin-' +
              this.mobile_skin_code +
              '--' +
              _baseUrl +
              '/survey.html?preview=Y&type=result',
            'survey_mobile_privew_loading',
            'width = 360, height = 620,',
          );
        } else {
          window.open(
            'http://m.' + _baseUrl + '/survey.html?preview=Y&type=result',
            'survey_mobile_privew_loading',
            'width = 360, height = 620,',
          );
        }
      }
      return;
    },
    getBaseUrl: function () {
      const shop_no = sessionStorage.getItem('survey_shop_no');
      const mall_list = this.$store.state.common.mall_list;
      const index = mall_list.findIndex(
        obj => Number(obj.shop_no) === Number(shop_no),
      );
      if (index !== -1) {
        return mall_list[index].primary_domain;
      }

      return '';
    },
    changeImage: async function (event, type = 'pc') {
      if (type === 'pc') {
        this.result_pc_bg_image_path = event.target.value;
        const result = await this.uploadImage(event.target.files[0]);
        if (!result) {
          event.target.value = '';
          this.result_pc_bg_image_path = '';
        }
        this.result_pc_bg_image = result;
      } else if (type === 'mobile') {
        this.result_mobile_bg_image_path = event.target.value;
        const result = await this.uploadImage(event.target.files[0]);
        if (!result) {
          event.target.value = '';
          this.result_mobile_bg_image_path = '';
        }
        this.result_mobile_bg_image = result;
      }
    },
    clearImage(id) {
      if (id === 'pc') {
        this.result_pc_bg_image = '';
        this.result_pc_bg_image_path = '';
      } else if (id === 'mobile') {
        this.result_mobile_bg_image = '';
        this.result_mobile_bg_image_path = '';
      }
    },
    showImagePreview(id) {
      let preview_image = '';

      if (id === 'pc') preview_image = this.result_pc_bg_image;
      else if (id === 'mobile') preview_image = this.result_mobile_bg_image;
      else return alert('Error');

      this.$vfm.show({
        component: ModalImagePreview,
        bind: {
          image: preview_image,
        },
      });
    },
    onlyHtmlColor: function (event, key) {
      const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      if (korean.test(event.target.value)) {
        alert('한글은 입력 할 수 없습니다.');
        value = '#';
      }

      let value = event.target.value.replace(/[^#0-9a-zA-Z]/g, '');
      value = '#' + value.replace(/#/g, '');

      if (value.length == 7) {
        let regex = /^#(?:[0-9a-f]{3}){1,2}$/i;
        if (!regex.test(value)) {
          alert('올바른 색상코드가 아닙니다.');
          value = '#';
        }
      }

      if (key === 'result_loading_text_color')
        this.result_loading_text_color = value;
      else if (key === 'result_progress_bar_color')
        this.result_progress_bar_color = value;

      event.target.value = value;
    },
    inputText: function (e) {
      let value = this.replaceHtml(e.target.value);
      if (e.target.id == 'result_loading_text') {
        this.result_loading_text = value;
      }
    },
    fileClick: function (e) {
      $(e.target).next('input').click();
    },
  },
  watch: {
    result_desktop_items: {
      handler(val, oldVal) {
        let _name = '';
        this.result_desktop_items.forEach((item, index) => {
          if (item.type === 'text') _name = '텍스트';
          else if (item.type === 'image') _name = '이미지';
          else if (item.type === 'recommend_goods') _name = '추천 상품';
          else if (item.type === 'show_items')
            _name = "추천 상품과 연결된 '노출항목'";
          else if (item.type === 'blank') _name = '여백';
          else if (item.type === 'cart_button')
            _name = '모두 장바구니 담기 버튼';

          this.result_desktop_items[index]._name = _name;
          this.result_desktop_items[index]._id = index;
        });
      },
      deep: true,
    },
    result_mobile_items: {
      handler(val, oldVal) {
        let _name = '';
        this.result_mobile_items.forEach((item, index) => {
          if (item.type === 'text') _name = '텍스트';
          else if (item.type === 'image') _name = '이미지';
          else if (item.type === 'recommend_goods') _name = '추천 상품';
          else if (item.type === 'show_items')
            _name = "추천 상품과 연결된 '노출항목'";
          else if (item.type === 'blank') _name = '여백';
          else if (item.type === 'cart_button')
            _name = '모두 장바구니 담기 버튼';

          this.result_mobile_items[index]._name = _name;
          this.result_mobile_items[index]._id = index;
        });
      },
      deep: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layer_dialog {
  display: none;
}

.mobile_result_list {
  min-height: 350px;
}

.pc_loading_tip {
  display: none;
  position: absolute;
  right: 492px;
  padding: 14px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-top: 12px;
}

.mobile_loading_tip {
  display: none;
  position: absolute;
  right: 236px;
  padding: 14px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-top: 12px;
}

.tip2 {
  display: none;
  position: absolute;
  right: 227px;
  padding: 14px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-top: 12px;
}

.tip3 {
  display: none;
  position: absolute;
  right: 227px;
  padding: 14px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-top: 12px;
}

.mobile_result_btn {
  margin-bottom: 100px;
}

.option {
  padding: 10px 0;
}

textarea {
  resize: none;
}

.input_hidden {
  width: 63px;
  z-index: -1;
}
</style>
