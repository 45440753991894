const mutations = {
  setMallProductCount(state, payload) {
    state.mall_product_count = payload;
  },
  setMallProductList(state, payload) {
    state.mall_product_list = payload;
  },
  setProductList(state, payload) {
    state.products = payload;
  },
};

export default mutations;
