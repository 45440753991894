<template>
  <!-- container -->
  <section id="container" v-show="is_show">
    <div class="content">
      <h2 class="title">통계 관리</h2>
      <p class="dsc">
        사용자가 선택한 내역에 대한 통계 내역을 확인할 수 있습니다.<br />해당
        내용을 바탕으로 쇼핑몰 운영에 참고하여 사용하시길 권장합니다.
      </p>

      <!-- tab -->
      <ul class="tab">
        <li><router-link to="/stats/user">사용자 통계</router-link></li>
        <li class="on">
          <router-link to="/stats/recommend">추천 상품 통계</router-link>
        </li>
        <li><router-link to="/stats/choice">사용자 선택 내역</router-link></li>
      </ul>
      <!-- //tab -->

      <div class="conbox">
        <h3 class="tit">추천 상품 - 결과 노출 통계</h3>
        <p>사용자에게 추천된 상품 통계를 확인하실 수 있습니다.</p>

        <div class="datepicker">
          <input
            type="text"
            name="from"
            class="datefrom vue_datepicker"
            id="recommend_from"
            v-model="recommend_sdate"
          /><span class="sec">~</span
          ><input
            type="text"
            name="to"
            id="recommend_to"
            class="dateto vue_datepicker"
            v-model="recommend_edate"
          />
          <button
            type="button"
            name=""
            value=""
            class="btn btn_large btn_primary"
            @click="
              getStatistics(
                {
                  type: 'recommend',
                  from: this.recommend_sdate,
                  to: this.recommend_edate,
                },
                'recommend',
              )
            "
          >
            조회
          </button>
        </div>

        <table class="tbl_list mt20">
          <caption>
            추천 상품 목록
          </caption>
          <colgroup>
            <col style="width: 8%" />
            <col style="width: auto" />
            <col style="width: 12%" />
            <col style="width: 12%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">순위</th>
              <th scope="col">추천 상품 정보</th>
              <th scope="col">결과 노출</th>
              <th scope="col">클릭 수</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="this.product_list.length === 0">
              <td colspan="4" class="nodata">조회 내역이 없습니다.</td>
            </tr>
            <tr
              v-for="(item, index) in this.product_list"
              :key="item.survey_config_id"
            >
              <td>
                {{ index + 1 }}
              </td>
              <td class="ta_l customTextStart">
                <div class="goods_wrap">
                  <p class="thumb">
                    <img
                      v-if="item.mall_product_image !== null"
                      :src="item.mall_product_image.image_url"
                      alt=""
                    />
                  </p>
                  <strong class="goods_name">{{
                    item.mall_product.product_name
                  }}</strong>
                  <span class="goods_code">{{
                    item.mall_product.product_code
                  }}</span>
                </div>
              </td>
              <td>{{ this.numberFormat(Number(item.display_count)) }}</td>
              <td>
                {{
                  item.click_count !== null
                    ? this.numberFormat(Number(item.click_count))
                    : 0
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="conbox">
        <h3 class="tit">추천 상품 - 장바구니 통계</h3>
        <p>사용자가 장바구니에 담은 통계 내역을 확인하실 수 있습니다.</p>

        <div class="datepicker">
          <input
            type="text"
            name="from"
            id="cart_from"
            class="datefrom vue_datepicker"
            v-model="cart_sdate"
          /><span class="sec">~</span
          ><input
            type="text"
            name="to"
            id="cart_to"
            class="dateto vue_datepicker"
            v-model="cart_edate"
          />
          <button
            type="button"
            name=""
            value=""
            class="btn btn_large btn_primary"
            @click="
              getStatistics(
                {
                  type: 'cart',
                  from: this.cart_sdate,
                  to: this.cart_edate,
                },
                'cart',
              )
            "
          >
            조회
          </button>
        </div>

        <table class="tbl_list mt20">
          <caption>
            추천 상품 목록
          </caption>
          <colgroup>
            <col style="width: 8%" />
            <col style="width: auto" />
            <col style="width: 12%" />
            <col style="width: 12%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">순위</th>
              <th scope="col">추천 상품 정보</th>
              <th scope="col">장바구니</th>
              <th scope="col">담긴 금액</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="this.cart_list.length === 0">
              <td colspan="4" class="nodata">조회 내역이 없습니다.</td>
            </tr>
            <tr v-for="(item, index) in this.cart_list" :key="item">
              <td>{{ index + 1 }}</td>
              <td class="ta_l customTextStart">
                <div class="goods_wrap">
                  <p class="thumb">
                    <img
                      v-if="item.mall_product_image !== null"
                      :src="item.mall_product_image.image_url"
                      alt=""
                    />
                  </p>
                  <strong class="goods_name">{{
                    item.mall_product.product_name
                  }}</strong>
                  <span
                    v-if="item.variant_code_list.length > 0"
                    class="goods_code"
                  >
                    <!-- - 옵션명 : {{ item.option_info }} -->
                    {{ item.option_info }}
                  </span>
                  <span v-else class="goods_code">- 옵션없음</span>
                </div>
              </td>
              <td>{{ this.numberFormat(Number(item.cart_count)) }}</td>
              <td>
                {{
                  this.numberFormat(
                    Number(item.cart_price) * Number(item.cart_count),
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- //content -->
  </section>
  <!-- //container -->
</template>
<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  data: () => ({
    is_show: false,
    survey_config_id: 0,
    recommend_sdate: '',
    recommend_edate: '',
    recommend_rank: 1,
    recommend_list: [],
    cart_sdate: '',
    cart_edate: '',
    cart_rank: 1,
    cart_list: [],
    product_list: [],
  }),
  created: async function () {
    await this.init();
  },
  mounted: function () {
    var self = this;

    $(document.querySelectorAll('.vue_datepicker')).datepicker({
      dateFormat: 'yy-mm-dd',
      prevText: '이전 달',
      nextText: '다음 달',
      monthNames: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      monthNamesShort: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      dayNames: ['일', '월', '화', '수', '목', '금', '토'],
      dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
      dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
      showMonthAfterYear: true,
      yearSuffix: '년',
      onSelect: function (d) {
        self.updateDate(d, $(this).prop('id'));
      },
    });

    $('#recommend_from').datepicker('option', 'maxDate', this.recommend_edate);
    $('#recommend_to').datepicker('option', 'minDate', this.recommend_sdate);
    $('#cart_from').datepicker('option', 'maxDate', this.cart_edate);
    $('#cart_to').datepicker('option', 'minDate', this.cart_sdate);
  },
  computed: {
    ...mapState('payment', ['expire']),
    ...mapState('common', [
      'shop_no',
      'store',
      'mall_list',
      'survey_mall_list',
    ]),
    ...mapState('stats_recommend', ['stats']),
  },
  methods: {
    init: async function () {
      // 첫 접속 시 survey_config_id 조회
      if (this.$route.query.access_token) {
        await this.$store.dispatch('qna_config/getConfig');
        this.$store.commit(
          'common/setSurveyConfigId',
          this.$store.state.qna_config.qna_config.id,
        );
      }
      this.survey_config_id = sessionStorage.getItem('survey_survey_config_id');

      this.recommend_sdate = dayjs().add(-1, 'month').format('YYYY-MM-DD');
      this.recommend_edate = dayjs().format('YYYY-MM-DD');

      this.cart_sdate = dayjs().add(-1, 'month').format('YYYY-MM-DD');
      this.cart_edate = dayjs().format('YYYY-MM-DD');

      await this.getStatistics(
        {
          type: 'recommend',
          from: this.recommend_sdate,
          to: this.recommend_edate,
        },
        'recommend',
      );

      await this.getStatistics(
        {
          type: 'cart',
          from: this.cart_sdate,
          to: this.cart_edate,
        },
        'cart',
      );

      this.is_show = true;
    },
    getStatistics: async function (params, type = 'recommend') {
      const from = new Date(params.from);
      const to = new Date(params.to);

      if (to < from) {
        alert('기간 검색 날짜를 정확히 입력해주세요.');
        return false;
      }

      await this.$store.dispatch('stats_recommend/getStatistics', params);
      if (type == 'recommend') {
        this.product_list = [];
        for (const [key, item] of Object.entries(this.stats)) {
          // 카페24 이미지를 <img> 불러올때 오류 발생으로 수정 ( 도메인명 변경 )
          let image_url = this.imageUrlChange(item);
          if (image_url) {
            item.mall_product_image.image_url = image_url;
          }

          let is_product = false;
          for (const product of this.product_list) {
            if (
              item.mall_product.product_code ==
              product.mall_product.product_code
            ) {
              product.display_count =
                Number(product.display_count) + Number(item.display_count);
              product.click_count =
                Number(product.click_count) + Number(item.click_count);
              product.cart_count =
                Number(product.cart_count) + Number(item.cart_count);
              is_product = true;
            }
          }
          if (!is_product) {
            this.product_list.push(item);
          }
        }

        // 노출수 낮은 순서 대로 정렬
        this.product_list.sort(function (a, b) {
          return Number(a.display_count) - Number(b.display_count);
        });
        // 노출수 높은 순으로 역정렬
        this.product_list.reverse();

        this.recommend_list = this.stats;
      } else {
        for (const [key, item] of Object.entries(this.stats)) {
          // 카페24 이미지를 <img> 불러올때 오류 발생으로 수정 ( 도메인명 변경 )
          let image_url = this.imageUrlChange(item);
          if (image_url) {
            item.mall_product_image.image_url = image_url;
          }

          if (item.variant_code_list) {
            item.variant_code_list = JSON.parse(item.variant_code_list);

            let option_text = '';

            for (const variant of item.variant_code_list) {
              if (variant.name && variant.value) {
                option_text += variant.name + ' : ' + variant.value + ' / ';
              }
            }
            if (option_text) {
              item.option_info = option_text.substr(0, option_text.length - 2);
            } else {
              item.variant_code_list = [];
            }
          } else {
            item.variant_code_list = [];
          }
        }
        this.cart_list = [];
        for (const stat of this.stats) {
          if (Number(stat.cart_count) > 0 && Number(stat.cart_price)) {
            this.cart_list.push(stat);
          }
        }
      }
    },
    updateDate: function (d, type) {
      if (type === 'recommend_from') {
        this.recommend_sdate = d;
        $('#recommend_to').datepicker('option', 'minDate', d);
      } else if (type === 'recommend_to') {
        this.recommend_edate = d;
        $('#recommend_from').datepicker('option', 'maxDate', d);
      } else if (type === 'cart_from') {
        this.cart_sdate = d;
        $('#cart_to').datepicker('option', 'minDate', d);
      } else if (type === 'cart_to') {
        this.cart_edate = d;
        $('#cart_from').datepicker('option', 'maxDate', d);
      }
    },
    imageUrlChange: function (item) {
      if (item.mall_product_image && item.mall_product_image.image_url) {
        let _image_href_array = item.mall_product_image.image_url.split('/');
        if (
          _image_href_array[3] == 'web' &&
          _image_href_array[4] == 'product'
        ) {
          _image_href_array[2] = `${this.$store.state.common.mall_id}.cafe24.com`;
        }
        return _image_href_array.join('/');
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped></style>
