<template>
  <QnaConfig ref="QnaConfig" />
</template>

<script>
// @ is an alias to /src
import QnaConfig from '@/components/qna/QnaConfig.vue';

export default {
  name: 'qna-config',
  components: {
    QnaConfig,
  },
};
</script>
