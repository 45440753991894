<template>
  <!-- paginate -->
  <div class="paginate">
    <ul v-if="paging.list.length > 0">
      <li>
        <button
          v-if="this.paging.total_page > this.paging.display_page_count"
          type="button"
          class="btn_page"
          @click="pageChange(1)"
        >
          <span class="material-icons">first_page</span>
        </button>
      </li>
      <li>
        <template
          v-if="this.paging.total_page <= this.paging.display_page_count"
        >
          <!--
          <button type="button" class="btn_page">
            <span class="material-icons">chevron_left</span>
          </button>
          -->
        </template>
        <template v-else>
          <button
            type="button"
            class="btn_page"
            @click="
              pageChange(
                this.paging.start_page - this.paging.display_page_count,
              )
            "
          >
            <span class="material-icons">chevron_left</span>
          </button>
        </template>
      </li>
      <li v-for="page_index in paging.list" :key="page_index">
        <button
          type="button"
          @click="pageChange(page_index)"
          :class="page_index === paging.current_page ? 'current' : ''"
        >
          <span>{{ page_index }}</span>
        </button>
      </li>
      <li>
        <template
          v-if="this.paging.total_page <= this.paging.display_page_count"
        >
          <!--
          <button type="button" class="btn_page">
            <span class="material-icons">chevron_right</span>
          </button>
          -->
        </template>
        <template v-else>
          <button
            type="button"
            class="btn_page"
            @click="pageChange(this.paging.end_page + 1)"
          >
            <span class="material-icons">chevron_right</span>
          </button>
        </template>
      </li>
      <li>
        <button
          v-if="this.paging.total_page > this.paging.display_page_count"
          type="button"
          class="btn_page"
          @click="pageChange(paging.total_page)"
        >
          <span class="material-icons">last_page</span>
        </button>
      </li>
    </ul>
  </div>
  <!-- //paginate -->
</template>

<script>
export default {
  data: () => ({
    paging: {
      offset: 0,
      per_page: 10,
      total_count: 0,
      current_page: 1,
      display_page_count: 10,
      block: 0,
      total_page: 0,
      start_page: 0,
      end_page: 0,
      list: [],
    },
  }),
  props: ['per_page', 'total_count'],
  created: async function () {
    await this.pagination();
  },
  methods: {
    pagination: function () {
      this.paging.total_count = this.total_count;
      this.paging.total_page = Math.ceil(
        this.paging.total_count / this.per_page,
      );

      this.paging.start_page =
        ((this.paging.current_page - 1) / this.paging.display_page_count) *
          this.paging.display_page_count +
        1;

      const block = Math.ceil(
        this.paging.current_page / this.paging.display_page_count,
      );

      this.paging.start_page = (block - 1) * this.paging.display_page_count + 1;
      this.paging.end_page =
        this.paging.start_page - 1 + this.paging.display_page_count;

      if (this.paging.total_page < this.paging.end_page) {
        this.paging.end_page = this.paging.total_page;
      }

      this.paging.list = [];
      for (let i = this.paging.start_page; i <= this.paging.end_page; i++) {
        this.paging.list.push(i);
      }

      if (this.paging.total_page < this.paging.current_page) {
        this.paging.current_page = 1;
        this.paging.offset = 0;
        this.paging.per_page = 10;
      }
    },
    pageChange: async function (page_no) {
      if (page_no <= 0) page_no = 1;
      else if (page_no >= this.paging.total_page)
        page_no = this.paging.total_page;

      this.paging.current_page = page_no;
      this.paging.offset = (this.paging.current_page - 1) * this.per_page;

      if (
        this.paging.current_page < this.paging.start_page ||
        this.paging.end_page < this.paging.current_page
      ) {
        this.pagination();
      }

      this.$emit('page-change', this.paging.offset);
    },
  },
  watch: {
    total_count: async function (v) {
      this.paging.current_page = 1;
      this.paging.offset = 0;
      this.paging.per_page = 10;

      await this.pagination();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
