<template>
  <!-- container -->
  <section id="container" v-show="is_show">
    <div class="content">
      <h2 class="title">통계 관리</h2>
      <p class="dsc">
        사용자가 선택한 내역에 대한 통계 내역을 확인할 수 있습니다.<br />해당
        내용을 바탕으로 쇼핑몰 운영에 참고하여 사용하시길 권장합니다.
      </p>

      <!-- tab -->
      <ul class="tab">
        <li class="on">
          <router-link to="/stats/user">사용자 통계</router-link>
        </li>
        <li><router-link to="/stats/recommend">추천 상품 통계</router-link></li>
        <li><router-link to="/stats/choice">사용자 선택 내역</router-link></li>
      </ul>
      <!-- //tab -->

      <div class="conbox">
        <h3 class="tit">참여 수 통계</h3>
        <p>기간별 사용자 참여 수 통계를 확인하세요.</p>

        <div class="total_stats">
          <ul>
            <li>
              <div class="box">
                <h4>금일 참여 수</h4>
                <strong>{{ this.numberFormat(Number(today_count)) }} 회</strong>
              </div>
            </li>
            <li>
              <div class="box">
                <h4>이번달 참여 수</h4>
                <strong>{{ this.numberFormat(Number(month_count)) }} 회</strong>
              </div>
            </li>
            <li>
              <div class="box">
                <h4>총 참여 수</h4>
                <strong>{{ this.numberFormat(Number(total_count)) }} 회</strong>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="conbox">
        <h3 class="tit">질문별 답변 선택 순위</h3>
        <p>
          설정하신 질문에 대한 답변 순위입니다. (텍스트 입력, 파일첨부 질문은
          제외합니다.)
        </p>
        <div class="datepicker">
          <select v-model="hierarchy_id" class="mr10" style="width: 420px">
            <option value="0" disabled>설정한 질문명 선택</option>
            <template v-for="(hierarchy, index) of hierarchy_list">
              <option
                v-if="
                  hierarchy.subject_type !== 'file' &&
                  hierarchy.subject_type !== 'text'
                "
                :key="index"
                :value="hierarchy.id"
              >
                {{ optionTextLength(hierarchy.subject, 34) }}
              </option>
            </template>
          </select>
          <input
            type="text"
            name="from"
            v-model="hierarchy_from"
            id="hierarchy_from"
            class="datefrom vue_datepicker"
          /><span class="sec">~</span
          ><input
            type="text"
            name="to"
            v-model="hierarchy_to"
            id="hierarchy_to"
            class="dateto vue_datepicker"
          />
          <button
            type="button"
            name=""
            value=""
            @click="getStatisticsQuestion"
            class="btn btn_large btn_primary"
          >
            조회
          </button>
        </div>

        <table class="tbl_list mt20">
          <caption>
            질문별 답변 선택 목록
          </caption>
          <colgroup>
            <col style="width: 8%" />
            <col style="width: auto" />
            <col style="width: 12%" />
            <col style="width: 12%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">순위</th>
              <th scope="col">답변</th>
              <th scope="col">비율</th>
              <th scope="col">선택 수</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="answer_total_count === 0">
              <td colspan="4" class="nodata">조회 내역이 없습니다.</td>
            </tr>
            <template v-else>
              <tr v-for="(answer, index) of answer_list" :key="index">
                <td>{{ this.numberFormat(Number(index + 1)) }}</td>
                <td class="customTextStart">{{ answer.subject }}</td>
                <td>
                  {{ Math.round((answer.count / answer_total_count) * 100) }}%
                </td>
                <td>{{ this.numberFormat(Number(answer.count)) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="conbox">
        <h3 class="tit">성별 통계</h3>
        <p>성별 질문/답변을 설정한 경우에 통계 데이터 확인이 가능합니다.</p>

        <div class="datepicker">
          <input
            type="text"
            name="from"
            v-model="sex_from"
            class="datefrom vue_datepicker"
            id="sex_from"
          /><span class="sec">~</span
          ><input
            type="text"
            name="to"
            v-model="sex_to"
            class="dateto vue_datepicker"
            id="sex_to"
          />
          <button
            type="button"
            name=""
            value=""
            class="btn btn_large btn_primary"
            @click="getStatisticsGender"
          >
            조회
          </button>
        </div>

        <table class="tbl_list mt20">
          <caption>
            추천 상품 목록
          </caption>
          <colgroup>
            <col style="width: 25%" />
            <col style="width: 25%" />
            <col style="width: 25%" />
            <col style="width: 25%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">남성</th>
              <th scope="col">여성</th>
              <th scope="col">기타</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="gender_total_count === 0">
              <td colspan="4" class="nodata">조회 내역이 없습니다.</td>
            </tr>
            <template v-else>
              <tr>
                <td>횟수</td>
                <td>{{ this.numberFormat(Number(gender_list.male.count)) }}</td>
                <td>
                  {{ this.numberFormat(Number(gender_list.female.count)) }}
                </td>
                <td>{{ this.numberFormat(Number(gender_list.etc.count)) }}</td>
              </tr>
              <tr>
                <td>비율</td>
                <td>{{ gender_list.male.rate }}%</td>
                <td>{{ gender_list.female.rate }}%</td>
                <td>{{ gender_list.etc.rate }}%</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="conbox">
        <h3 class="tit">연령대 통계</h3>
        <p>나이 질문/답변을 설정한 경우에 통계 데이터 확인이 가능합니다.</p>

        <div class="datepicker">
          <input
            type="text"
            name="from"
            v-model="age_from"
            class="datefrom vue_datepicker"
            id="age_from"
          /><span class="sec">~</span
          ><input
            type="text"
            name="to"
            v-model="age_to"
            class="dateto vue_datepicker"
            id="age_to"
          />
          <button
            type="button"
            name=""
            value=""
            class="btn btn_large btn_primary"
            @click="getStatisticsAge"
          >
            조회
          </button>
        </div>

        <table class="tbl_list mt20">
          <caption>
            추천 상품 목록
          </caption>
          <colgroup>
            <col style="width: auto" />
            <col style="width: 11%" />
            <col style="width: 11%" />
            <col style="width: 11%" />
            <col style="width: 11%" />
            <col style="width: 11%" />
            <col style="width: 11%" />
            <col style="width: 11%" />
            <col style="width: 11%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">10대 이하</th>
              <th scope="col">10대</th>
              <th scope="col">20대</th>
              <th scope="col">30대</th>
              <th scope="col">40대</th>
              <th scope="col">50대</th>
              <th scope="col">60대</th>
              <th scope="col">70대 이상</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="age_total_count === 0">
              <td colspan="9" class="nodata">조회 내역이 없습니다.</td>
            </tr>
            <template v-else>
              <tr>
                <td>횟수</td>
                <td>{{ this.numberFormat(Number(age_list.age_0.count)) }}</td>
                <td>{{ this.numberFormat(Number(age_list.age_10.count)) }}</td>
                <td>{{ this.numberFormat(Number(age_list.age_20.count)) }}</td>
                <td>{{ this.numberFormat(Number(age_list.age_30.count)) }}</td>
                <td>{{ this.numberFormat(Number(age_list.age_40.count)) }}</td>
                <td>{{ this.numberFormat(Number(age_list.age_50.count)) }}</td>
                <td>{{ this.numberFormat(Number(age_list.age_60.count)) }}</td>
                <td>{{ this.numberFormat(Number(age_list.age_70.count)) }}</td>
              </tr>
              <tr>
                <td>비율</td>
                <td>{{ age_list.age_0.rate }}%</td>
                <td>{{ age_list.age_10.rate }}%</td>
                <td>{{ age_list.age_20.rate }}%</td>
                <td>{{ age_list.age_30.rate }}%</td>
                <td>{{ age_list.age_40.rate }}%</td>
                <td>{{ age_list.age_50.rate }}%</td>
                <td>{{ age_list.age_60.rate }}%</td>
                <td>{{ age_list.age_70.rate }}%</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <!-- //content -->

    <MallManagerInfo ref="MallManagerInfo"> </MallManagerInfo>
  </section>
  <!-- //container -->
</template>
<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

import common from '@/components/mixin/index.js';
import MallManagerInfo from '@/components/stats/modal/MallManagerInfo.vue';

export default {
  mixins: [common],
  data: () => ({
    is_show: false,
    survey_config_id: 0,
    today_count: 0,
    month_count: 0,
    total_count: 0,
    hierarchy_id: 0,
    hierarchy_list: [],
    answer_total_count: 0,
    answer_list: [],
    gender_total_count: 0,
    gender_list: {
      male: {
        count: 0,
        rate: 0,
      },
      female: {
        count: 0,
        rate: 0,
      },
      etc: {
        count: 0,
        rate: 0,
      },
    },
    age_total_count: 0,
    age_list: {
      age_0: {
        count: 0,
        rate: 0,
      },
      age_10: {
        count: 0,
        rate: 0,
      },
      age_20: {
        count: 0,
        rate: 0,
      },
      age_30: {
        count: 0,
        rate: 0,
      },
      age_40: {
        count: 0,
        rate: 0,
      },
      age_50: {
        count: 0,
        rate: 0,
      },
      age_60: {
        count: 0,
        rate: 0,
      },
      age_70: {
        count: 0,
        rate: 0,
      },
    },
    hierarchy_from: '',
    hierarchy_to: '',
    sex_from: '',
    sex_to: '',
    age_from: '',
    age_to: '',
  }),
  created: async function () {
    this.init();
  },
  computed: {
    ...mapState('stats_user', ['stats']),
    ...mapState('common', ['shop_no', 'survey_mall_list']),
    ...mapState('payment', ['payment_mall_info']),
  },
  components: {
    MallManagerInfo,
  },
  mounted() {
    var self = this;

    $(document.querySelectorAll('.vue_datepicker')).datepicker({
      dateFormat: 'yy-mm-dd',
      prevText: '이전 달',
      nextText: '다음 달',
      monthNames: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      monthNamesShort: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      dayNames: ['일', '월', '화', '수', '목', '금', '토'],
      dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
      dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
      showMonthAfterYear: true,
      yearSuffix: '년',
      onSelect: function (d) {
        self.updateDate(d, $(this).prop('id'));
      },
    });

    $('#hierarchy_from').datepicker('option', 'maxDate', this.hierarchy_to);
    $('#hierarchy_to').datepicker('option', 'minDate', this.hierarchy_from);
    $('#sex_from').datepicker('option', 'maxDate', this.sex_to);
    $('#sex_to').datepicker('option', 'minDate', this.sex_from);
    $('#age_from').datepicker('option', 'maxDate', this.age_to);
    $('#age_to').datepicker('option', 'minDate', this.age_from);
  },
  methods: {
    init: async function () {
      this.survey_config_id = sessionStorage.getItem('survey_survey_config_id');
      const sdate = dayjs().add(-1, 'month').format('YYYY-MM-DD');
      const edate = dayjs().format('YYYY-MM-DD');

      this.hierarchy_from = sdate;
      this.hierarchy_to = edate;
      this.sex_from = sdate;
      this.sex_to = edate;
      this.age_from = sdate;
      this.age_to = edate;
      if (this.$route.query.access_token) {
        await this.$store.dispatch('qna_config/getConfig');
        // qna_config.id가 없으면 자동 생성 한다
        if (typeof this.$store.state.qna_config.qna_config.id == 'undefined') {
          await this.$store.dispatch('qna_config/postData');
          await this.$store.dispatch('qna_config/getConfig');
        }

        if (this.$store.state.qna_config.qna_config.id) {
          this.$store.commit(
            'common/setSurveyConfigId',
            this.$store.state.qna_config.qna_config.id,
          );
        }

        // 쇼핑몰 담당자 정보를 입력 모달
        await this.getOrderLatest();
        if (!this.payment_mall_info.mall_phone) {
          this.$refs.MallManagerInfo.showModal(this.payment_mall_info);
        }
      }

      await this.getStatistics({
        from: sdate,
        to: edate,
        hierarchy_from: this.hierarchy_from,
        hierarchy_to: this.hierarchy_to,
        sex_from: this.sex_from,
        sex_to: this.sex_to,
        age_from: this.age_from,
        age_to: this.age_to,
      });

      this.today_count = this.stats.today.count;
      this.month_count = this.stats.month.count;
      this.total_count = this.stats.total.count;

      this.setStatisticsQuestion();
      this.setStatisticsGender();
      this.setStatisticsAge();

      this.hierarchy_list = this.stats.hierarchy_list;

      this.is_show = true;
    },
    getOrderLatest: async function () {
      await this.$store.dispatch('payment/getOrderLatest');
    },
    getStatistics: async function (params) {
      await this.$store.dispatch('stats_user/getStatistics', params);
    },
    getStatisticsQuestion: async function () {
      if (this.hierarchy_id === 0) {
        alert('조회 하실 질문을 선택해주세요.');
        return false;
      }

      const params = {
        hierarchy_id: this.hierarchy_id,
        from: this.hierarchy_from,
        to: this.hierarchy_to,
      };
      await this.$store.dispatch('stats_user/getQuestionStatistics', params);
      this.setStatisticsQuestion();
    },
    setStatisticsQuestion: function () {
      if (
        this.stats !== null &&
        this.stats.question_stats.total !== undefined
      ) {
        this.answer_total_count = this.stats.question_stats.total;
        this.answer_list = this.stats.question_stats.list;
      } else {
        this.answer_total_count = 0;
        this.answer_list = [];
      }
    },
    getStatisticsGender: async function () {
      const params = {
        from: this.sex_from,
        to: this.sex_to,
      };
      await this.$store.dispatch('stats_user/getGenderStatistics', params);
      this.setStatisticsGender();
    },
    setStatisticsGender: function () {
      if (this.stats !== null) {
        this.gender_total_count = this.stats.gender_stats.total.count;

        for (const gender of this.stats.gender_stats.list) {
          if (gender.sex_type === 'male') {
            this.gender_list.male.count = gender.count;
            this.gender_list.male.rate =
              gender.count > 0
                ? Math.round((gender.count / this.gender_total_count) * 100)
                : 0;
          } else if (gender.sex_type === 'female') {
            this.gender_list.female.count = gender.count;
            this.gender_list.female.rate =
              gender.count > 0
                ? Math.round((gender.count / this.gender_total_count) * 100)
                : 0;
          } else if (gender.sex_type === 'etc') {
            this.gender_list.etc.count = gender.count;
            this.gender_list.etc.rate =
              gender.count > 0
                ? Math.round((gender.count / this.gender_total_count) * 100)
                : 0;
          }
        }

        if (this.stats.gender_stats.list.length === 0) {
          this.gender_total_count = 0;
          this.gender_list.male.count = 0;
          this.gender_list.male.rate = 0;
          this.gender_list.female.count = 0;
          this.gender_list.female.rate = 0;
          this.gender_list.etc.count = 0;
          this.gender_list.etc.rate = 0;
        }
      }
    },
    getStatisticsAge: async function () {
      const params = {
        from: this.age_from,
        to: this.age_to,
      };
      await this.$store.dispatch('stats_user/getAgeStatistics', params);
      this.setStatisticsAge();
    },
    setStatisticsAge: function () {
      // age_list
      if (this.stats !== null) {
        this.age_total_count = this.stats.age_stats.total.count;

        for (const age of this.stats.age_stats.list) {
          if (age.age_type === '0') {
            this.age_list.age_0.count = age.count;
            this.age_list.age_0.rate =
              age.count > 0
                ? Math.round((age.count / this.age_total_count) * 100)
                : 0;
          } else if (age.age_type === '10') {
            this.age_list.age_10.count = age.count;
            this.age_list.age_10.rate =
              age.count > 0
                ? Math.round((age.count / this.age_total_count) * 100)
                : 0;
          } else if (age.age_type === '20') {
            this.age_list.age_20.count = age.count;
            this.age_list.age_20.rate =
              age.count > 0
                ? Math.round((age.count / this.age_total_count) * 100)
                : 0;
          } else if (age.age_type === '30') {
            this.age_list.age_30.count = age.count;
            this.age_list.age_30.rate =
              age.count > 0
                ? Math.round((age.count / this.age_total_count) * 100)
                : 0;
          } else if (age.age_type === '40') {
            this.age_list.age_40.count = age.count;
            this.age_list.age_40.rate =
              age.count > 0
                ? Math.round((age.count / this.age_total_count) * 100)
                : 0;
          } else if (age.age_type === '50') {
            this.age_list.age_50.count = age.count;
            this.age_list.age_50.rate =
              age.count > 0
                ? Math.round((age.count / this.age_total_count) * 100)
                : 0;
          } else if (age.age_type === '60') {
            this.age_list.age_60.count = age.count;
            this.age_list.age_60.rate =
              age.count > 0
                ? Math.round((age.count / this.age_total_count) * 100)
                : 0;
          } else if (age.age_type === '70') {
            this.age_list.age_70.count = age.count;
            this.age_list.age_70.rate =
              age.count > 0
                ? Math.round((age.count / this.age_total_count) * 100)
                : 0;
          }
        }
      }
    },
    updateDate: function (d, type) {
      if (type === 'hierarchy_from') {
        this.hierarchy_from = d;
        $('#hierarchy_to').datepicker('option', 'minDate', this.hierarchy_from);
      } else if (type === 'hierarchy_to') {
        this.hierarchy_to = d;
        $('#hierarchy_from').datepicker('option', 'maxDate', this.hierarchy_to);
      } else if (type === 'sex_from') {
        this.sex_from = d;
        $('#sex_to').datepicker('option', 'minDate', this.sex_from);
      } else if (type === 'sex_to') {
        this.sex_to = d;
        $('#sex_from').datepicker('option', 'maxDate', this.sex_to);
      } else if (type === 'age_from') {
        this.age_from = d;
        $('#age_to').datepicker('option', 'minDate', this.age_from);
      } else if (type === 'age_to') {
        this.age_to = d;
        $('#age_from').datepicker('option', 'maxDate', this.age_to);
      }
    },
    optionTextLength: function (text, length) {
      if (!length || length == 0) {
        length = 25;
      }
      if (text.length > length) {
        return text.substr(0, length) + '...';
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped></style>
