<template>
  <div v-show="is_show">
    <!-- container -->
    <section id="container">
      <div class="content">
        <h2 class="title">맞춤 질문 설정</h2>
        <p class="dsc">
          쇼핑몰 고객들에게 제공할 맞춤 질문 설정 및 관리를 합니다.<br />
          수집한 데이터를 기반으로 해당 고객에게 적합한 상품 추천이
          가능합니다.<br />
          아래 1~4 스텝별 메뉴를 클릭하여 순서대로 설정해주세요.
        </p>

        <div class="step">
          <ul>
            <li class="on">
              <em>1</em>
              추천 상품 설정
            </li>
            <li>
              <em><a v-bind:href="`/qna/setting`">2</a></em>
              <router-link to="/qna/setting">질문 설정</router-link>
            </li>
            <li>
              <em><a v-bind:href="`/qna/config`">3</a></em>
              <router-link to="/qna/config">화면 관련 설정</router-link>
            </li>
            <li>
              <em><a v-bind:href="`/qna/result`">4</a></em>
              <router-link to="/qna/result">결과 화면 설정</router-link>
            </li>
          </ul>
        </div>

        <form action="">
          <div class="conbox">
            <h3 class="tit">추천 알고리즘 설정</h3>
            <p class="mb20">
              결과 화면에서 보여줄 추천 상품의 알고리즘을 선택하여 저장해주세요.
            </p>
            <table class="tbl_write">
              <caption>
                추천 알고리즘 설정
              </caption>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 80%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">추천 알고리즘</th>
                  <td>
                    <select
                      name=""
                      v-model="question_type"
                      style="width: 650px"
                      @change="questionTypeChange()"
                    >
                      <option value="none">
                        개별 상품 추천형 - 질문/답변과 상관없이 정해진 상품을
                        결과화면에 노출
                      </option>
                      <option value="choice">
                        질문/답변 선택형 - 답변에 적합한 상품을 설정하며 고객
                        선택이 많은 상품을 결과화면에 노출
                      </option>
                      <option value="score">
                        질문/답변 점수형 - 답변에 적합한 상품을 설정, 점수를
                        입력하며 고객 선택에 따른 점수로 결과화면에 노출
                      </option>
                    </select>
                    <button
                      type="button"
                      class="
                        btn btn_large btn_primary btn_wide
                        ml10
                        custom_width150
                      "
                      @click="setConfigQuestionType"
                    >
                      <span>저장하기</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="conbox">
            <h3 class="tit">추천 상품 및 노출항목 설정</h3>
            <p class="mb20">
              결과 화면에서 보여줄 추천 상품을 조회하여 추가해주세요.<br />
              추가된 상품에 '결과화면 노출항목'을 설정하시면 상품에 해당 정보를
              매칭할 수 있어 결과 화면에 해당 정보(이미지, 텍스트) 노출이
              가능합니다.<br />
              '카페24 상품정보 반영' 버튼을 클릭하시면 추가한 상품 중에 카페24
              어드민에서 삭제하거나 상품명,가격,이미지,진열/판매상태를 변경한
              상품이 있는 경우 반영합니다.
            </p>
            <div class="clfix mb10">
              <div class="fl_l">
                <button
                  v-if="this.is_loaiding == false"
                  type="button"
                  name=""
                  class="btn btn_small btn_secondary"
                  @click="deleteItem"
                >
                  선택 상품 삭제
                </button>
                <div v-else class="btn btn_small btn_secondary">
                  잠시 기다려주세요
                  <img :src="loading_image" class="loadingImage" />
                </div>
              </div>

              <div class="search ml5 notranslate">
                <i
                  translate="no"
                  class="
                    material-icons-outlined
                    fa fa-search
                    md-small
                    notranslate
                  "
                  @click="getProductList('search')"
                >
                  search
                </i>
                <input
                  v-model="search_product_name"
                  type="text"
                  placeholder="상품명 검색"
                  @keydown.enter.prevent="getProductList('search')"
                />
              </div>

              <div class="fl_r">
                <button
                  type="button"
                  @click="syncProduct"
                  class="btn btn_small btn_purple"
                >
                  <span>카페24 상품정보 반영</span>
                </button>
                <button
                  type="button"
                  @click="showPopup('option')"
                  class="btn btn_small btn_dark ml5"
                >
                  <span>결과화면 노출항목 설정</span>
                </button>
                <button
                  type="button"
                  @click="showPopup('add')"
                  class="btn btn_small btn_primary ml5"
                >
                  <span>+ 상품 조회하여 추가</span>
                </button>

                <select
                  v-model="per_pageing"
                  class="search ml5 perPageing"
                  @change="perPageChange()"
                >
                  <option
                    v-for="option in options"
                    :value="option.value"
                    :key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <table class="tbl_list">
              <caption>
                추천 상품 목록
              </caption>
              <colgroup>
                <col style="width: 4%" />
                <col style="width: 10%" />
                <col style="width: auto" />
                <col style="width: 12%" />
                <col style="width: 10%" />
                <col style="width: 10%" />
                <col style="width: 14%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      name=""
                      @click="allChecked($event)"
                      v-model="allcheck"
                    />
                  </th>
                  <th scope="col">상품이미지</th>
                  <th scope="col">상품정보</th>
                  <th scope="col">판매가</th>
                  <th scope="col">진열상태</th>
                  <th scope="col">판매상태</th>
                  <th scope="col">결과화면 노출항목</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="
                    Array.isArray(this.product_list) !== true ||
                    this.product_list.length === 0
                  "
                >
                  <td colspan="7" class="nodata">설정된 상품이 없습니다.</td>
                </tr>
                <tr v-for="(item, key) in this.product_list" :key="key">
                  <td>
                    <input
                      type="checkbox"
                      name=""
                      v-model="item.checked"
                      :value="item.product_no"
                      @click="checkBoxClick(item.checked, key)"
                    />
                  </td>
                  <td>
                    <p
                      class="thumb new_thumb"
                      v-if="
                        item.mall_product_image !== null &&
                        item.mall_product_image.image_url
                      "
                    >
                      <img :src="item.mall_product_image.image_url" alt="" />
                    </p>
                    <p class="thumb" v-else></p>
                  </td>
                  <td class="ta_l customTextStart">
                    <strong class="goods_name">{{ item.product_name }}</strong>
                    <span class="goods_code"
                      >상품코드 {{ item.mall_product.product_code }}</span
                    >
                  </td>
                  <td>
                    <strong>{{ numberFormat(item.mall_product.price) }}</strong>
                  </td>
                  <td>
                    <strong v-if="item.mall_product.display == 'T'"
                      >진열함</strong
                    >
                    <strong v-else class="purpleFont">진열안함</strong>
                  </td>
                  <td>
                    <strong v-if="item.mall_product.selling == 'T'"
                      >판매함</strong
                    >
                    <strong v-else class="purpleFont">판매안함</strong>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn_small btn_dark"
                      @click="showPopup('result_option', item)"
                    >
                      <span>등록하기</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <Pagination
              v-if="this.product_list.length > 0 && this.paging.total_count > 0"
              :per_page="this.paging.per_page"
              :total_count="this.paging.total_count"
              @page-change="pageChange"
              ref="Pagination"
            />
          </div>
        </form>

        <div class="btn_wrap right"></div>
      </div>
      <!-- //content -->
    </section>
    <!-- //container -->

    <ModalQnaProductOption
      v-if="this.is_product_option_popup"
      @hide-popup="hidePopup"
      :survey_config_info="this.qna_config"
      :is_result_option_popup="this.is_product_result_option_popup"
    />
    <ModalQnaProductAdd
      v-if="this.is_product_add_popup"
      @hide-popup="hidePopup"
      :product_id_list="this.product_id_list"
    />
    <ModalQnaProductResultOption
      ref="resultOption"
      v-if="this.is_product_result_option_popup"
      @show-popup="showPopup"
      @hide-popup="hidePopup"
      :survey_config_info="this.qna_config"
      :product="this.product"
    />

    <ModalsContainer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ModalsContainer } from 'vue-final-modal';

import common from '@/components/mixin/index.js';
import ModalQnaProductAdd from '@/components/qna/modal/ModalQnaProductAdd.vue';
import ModalQnaProductOption from '@/components/qna/modal/ModalQnaProductOption.vue';
import ModalQnaProductResultOption from '@/components/qna/modal/ModalQnaProductResultOption.vue';

import Pagination from '@/components/layout/Pagination.vue';
import dayjs from 'dayjs';
import loading_image from '@/assets/loading.gif';

export default {
  mixins: [common],
  data: () => ({
    obj: {
      is_open: false,
    },
    allcheck: false,
    is_popup_open: false,
    is_product_add_popup: false,
    is_product_option_popup: false,
    is_product_result_option_popup: false,
    question_type: 'none',
    is_show: false,
    paging: {
      offset: 0,
      per_page: 10,
      total_count: 0,
    },
    product_list: [],
    all_product_list: [],
    product_id_list: [],
    product: {},
    is_update_product_list: null,
    search_product_name: null,
    befor_search_product_name: null,
    params: {},
    per_pageing: 10,
    options: [
      { text: '10개씩 보기', value: 10 },
      { text: '20개씩 보기', value: 20 },
      { text: '30개씩 보기', value: 30 },
      { text: '40개씩 보기', value: 40 },
      { text: '50개씩 보기', value: 50 },
      { text: '100개씩 보기', value: 100 },
      { text: '200개씩 보기', value: 200 },
      { text: '300개씩 보기', value: 300 },
      { text: '400개씩 보기', value: 400 },
      { text: '500개씩 보기', value: 500 },
    ],
    loading_image,
    is_loaiding: false,
  }),
  components: {
    ModalsContainer,
    ModalQnaProductAdd,
    ModalQnaProductOption,
    ModalQnaProductResultOption,
    Pagination,
  },
  created: async function () {
    await this.getProductList();

    this.is_show = true;
  },
  computed: {
    ...mapState('qna_config', ['qna_config']),
    ...mapState('qna_product', ['products']),
    ...mapState('payment', ['expire']),
  },
  methods: {
    pageChange: async function (offset) {
      this.paging.offset = offset;
      await this.getProductList();
    },
    getProductList: async function (type = null) {
      await this.$store.dispatch('qna_config/getConfig');
      // 설정된 설정이 없고
      if (!this.qna_config) {
        let today = dayjs();
        let expired_at = dayjs(this.expire.expired_at);
        let result = expired_at.diff(today, 'day', true);
        let days = Math.ceil(result);
        // 이용 가능일이 있으면
        if (days > 0) {
          await this.setConfig();
          await this.$store.dispatch('qna_config/getConfig');
        }
      }
      if (
        this.search_product_name &&
        this.search_product_name != this.befor_search_product_name
      ) {
        this.befor_search_product_name = this.search_product_name;
        this.paging.offset = 0;
      }

      if (type == 'search') {
        this.paging.offset = 0;
        this.paging.per_page = this.per_pageing;
      }

      // 등록된 상품 전부를 가져온다.
      // 5000개 까지만..
      await this.$store.dispatch('qna_product/getProducts', {
        offset: 0,
        limit: 5000,
        product_name: this.search_product_name,
      });

      this.all_product_list = this.products.list;

      await this.$store.dispatch('qna_product/getProducts', {
        offset: this.paging.offset,
        limit: this.paging.per_page,
        product_name: this.search_product_name,
      });

      this.paging.total_count = this.products.total_count;

      // this.product_list = this.products.list;
      this.product_list = [];
      for (const product of this.products.list) {
        // 카페24 이미지를 <img> 불러올때 오류 발생으로 수정 ( 도메인명 변경 )
        if (
          product.mall_product_image &&
          product.mall_product_image.image_url
        ) {
          let _image_href_array =
            product.mall_product_image.image_url.split('/');
          if (
            _image_href_array[3] == 'web' &&
            _image_href_array[4] == 'product'
          ) {
            _image_href_array[2] = `${this.$store.state.common.mall_id}.cafe24.com`;
          }
          product.mall_product_image.image_url = _image_href_array.join('/');
        }
        this.product_list.push(product);
      }

      if (this.qna_config && this.qna_config.question_type) {
        this.question_type = this.qna_config.question_type;
      }

      this.product_id_list = [];

      for (const item of this.all_product_list) {
        this.product_id_list.push(item.product_no);
      }
    },
    setConfigQuestionType: async function () {
      let params = this.qna_config;
      params.question_type = this.question_type;

      const result = await this.$store.dispatch('qna_config/postData', params);

      if (result === true) {
        alert('저장되었습니다.');
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
    showPopup(name, item = null) {
      this.is_popup_open = true;

      if (name === 'option') this.is_product_option_popup = true;
      else if (name === 'add') this.is_product_add_popup = true;
      else if (name === 'result_option')
        this.is_product_result_option_popup = true;

      if (item !== null) {
        this.product = item;
      }
    },
    hidePopup: async function (name) {
      if (name === 'add') {
        if (this.is_update_product_list === true) {
          this.paging.current_page = 1;
          this.paging.offset = 0;
          this.paging.per_page = this.per_pageing;
        }
      }
      await this.getProductList(this.offset, this.limit);

      if (
        this.$refs.resultOption !== undefined &&
        this.$refs.resultOption !== null
      ) {
        this.$refs.resultOption.init('option');
      }

      if (name === 'option') {
        this.is_product_option_popup = false;
      } else if (name === 'add') {
        this.is_update_product_list = null;
        this.is_product_add_popup = false;
      } else if (name === 'result_option') {
        this.is_product_result_option_popup = false;
      } else {
        this.is_product_option_popup = false;
        this.is_product_add_popup = false;
        this.is_product_result_option_popup = false;
      }
      this.is_popup_open = false;
    },

    deleteItem: async function () {
      let isChecked = false;
      for (let i in this.product_list) {
        if (this.product_list[i].checked === true) {
          isChecked = true;
          break;
        }
      }

      if (isChecked === false) {
        alert('삭제하실 상품을 선택해주세요.');
        return false;
      } else if (
        !confirm(`삭제 후 복구는 불가능합니다.\r\n삭제 하시겠습니까?`)
      ) {
        alert('취소하였습니다.');
        return false;
      }
      this.is_loaiding = true;
      for (const item of this.product_list) {
        if (item.checked !== true) {
          continue;
        }

        await this.$store.dispatch('qna_product/deleteProduct', {
          product_id: item.product_no,
        });
      }

      this.allcheck = false;

      this.paging.current_page = 1;
      this.paging.offset = 0;
      this.paging.per_page = this.per_pageing;

      await this.pageChange(0);

      alert('삭제되었습니다.');
      this.is_loaiding = false;
    },
    allChecked(e) {
      if (this.product_list.length == 0) {
        e.target.checked = false;
        alert('선택된 상품이 없습니다.');
        return false;
      }

      this.all_selected = e.target.checked;

      for (let i in this.product_list) {
        this.product_list[i].checked = this.all_selected;
      }
    },
    syncProduct: async function () {
      let product_no_array = [];
      if (this.product_list) {
        for (const product of this.product_id_list) {
          product_no_array.push(product);
        }
      }

      if (product_no_array.length == 0) {
        alert('추가된 상품이 없습니다.');
        return;
      }

      await this.$store.dispatch('qna_product/syncProducts', {
        offset: 0,
        limit: 100,
        product_no_array: product_no_array.join(','),
      });

      await this.getProductList();
      alert('반영되었습니다.');
    },
    checkBoxClick: function (param, index) {
      let total_count = this.product_list.length;
      let check_count = 0;
      for (let i in this.product_list) {
        if (Number(i) == Number(index)) {
          this.product_list[i].checked = !param;
        }
        if (this.product_list[i].checked) {
          check_count += 1;
        }
      }
      if (this.allcheck === true && check_count != total_count) {
        this.allcheck = false;
      }
      if (this.allcheck === false && check_count == total_count) {
        this.allcheck = true;
      }
    },
    questionTypeChange: function () {
      let is_change_question_type = false;
      let is_change_show_items_type = false;
      // 점수형 or 선택형에서 추천형으로 변경 시
      if (this.qna_config) {
        if (
          (this.qna_config.question_type == 'choice' ||
            this.qna_config.question_type == 'score') &&
          this.question_type == 'none'
        ) {
          const result_desktop_config = JSON.parse(
            this.qna_config.result_desktop_config,
          );
          for (const parma_desktop of result_desktop_config) {
            if (parma_desktop && parma_desktop.type == 'recommend_goods') {
              for (const list of parma_desktop.info.recommend_goods) {
                // 추천 상품에 점수형 or 선택형이 있으면
                if (list.type == 'choice' || list.type == 'score') {
                  is_change_question_type = true;
                }
              }
            }
            if (parma_desktop && parma_desktop.type == 'show_items') {
              if (parma_desktop.select_product_type == 'choice') {
                is_change_show_items_type = true;
              }
            }
          }
          const result_mobile_config = JSON.parse(
            this.qna_config.result_mobile_config,
          );
          for (const parma_mobile of result_mobile_config) {
            if (parma_mobile && parma_mobile.type == 'recommend_goods') {
              for (const list of parma_mobile.info.recommend_goods) {
                // 추천 상품에 점수형 or 선택형이 있으면
                if (list.type == 'choice' || list.type == 'score') {
                  is_change_question_type = true;
                }
              }
            }
            if (parma_mobile && parma_mobile.type == 'show_items') {
              if (parma_mobile.select_product_type == 'choice') {
                is_change_show_items_type = true;
              }
            }
          }
        }
        // 추천 상품에 점수형 or 선택형이 있으면 alert
        if (is_change_question_type) {
          alert(
            '결과 화면 설정에서 설정하신 추천 상품을 확인하세요.\n개별 상품 추천형으로 변경시 순위별로 설정한 상품은 미노출됩니다.\n결과 화면에서 상품을 개별 지정하여 노출해주세요.',
          );
          return;
        }
        if (is_change_show_items_type) {
          alert(
            "결과 화면 설정에서 설정하신\n'+추천 상품과 연결된 노출항목'을 확인하세요.\n\n개별 상품 추천형으로 변경시 결과화면에서\n순위별로 설정한 상품 관련 정보가 미노출됩니다.\n결과 화면에서 노출항목을 수정하시길 권장합니다.",
          );
          return;
        }
      }
    },
    getDefaultSetting: async function () {
      // 사용자 접근 권한
      this.params.permission = 'anonymous';
      // 화면 배경 SELECT
      this.params.question_bg_type = 'color';
      // 화면 배경 > 색상
      this.params.survey_bg = '#fafafa';
      // 질문 박스 배경 색상
      this.params.question_bg_color = '#ffffff';
      // 화면 닫기 버튼
      this.params.close_button = 'first';
      // 화면 닫기 버튼
      this.params.back_button = 'first';
      // 닫기 및 뒤로가기 버튼 색상
      this.params.close_button_color = '#343434';
      // 질문 진행 프로그레스 색상
      this.params.progress_bar_color = '#ff5c35';
      // 질문 단계명 텍스트 색상
      this.params.question_step_color = '#f0bf4d';
      // 질문 내역 텍스트 색상
      this.params.question_text_color = '#333333';
      // 답변 색상 (텍스트필드, 드롭다운)
      this.params.answer_color = '#d7d7d7';
      // 답변 색상 (텍스트필드, 드롭다운) 가로사이즈
      this.params.answer_button_width = 740;
      // 답변 색상 (체크박스, 라디오버튼)
      this.params.answer_checkbox_color = '#333333';
      // 답변 색상 (버튼, 파일첨부) > 기본 버튼색상
      this.params.answer_button_color = '#d7d7d7';
      // 답변 색상 (버튼, 파일첨부) > 기본 텍스트색상
      this.params.answer_text_color = '#333333';
      // 답변 색상 (버튼, 파일첨부) > 선택 버튼색상
      this.params.answer_select_button_color = '#ff5c35';
      // 답변 색상 (버튼, 파일첨부) > 선택 텍스트색상
      this.params.answer_select_text_color = '#ffffff';
      // 하단 버튼 색상 (다음단계 클릭) > 버튼색상
      this.params.next_button_color = '#333333';
      // 하단 버튼 색상 (다음단계 클릭) > 텍스트색상
      this.params.next_button_text_color = '#ffffff';
      // 하단 버튼 색상 (다음단계 클릭) > 가로사이즈
      this.params.next_button_width = 740;
      // 인트로 화면 사용 > 배경이미지 > PC
      this.params.intro_desktop_bg_path =
        'https://survey.cafe24.shoplus.store/image/pc_intro_image.png';

      let pc_intro_image_files = await this.urlImageFile(
        this.params.intro_desktop_bg_path,
      );
      const pc_intro_image_result = await this.uploadImage(
        pc_intro_image_files,
      );
      this.params.intro_desktop_bg = pc_intro_image_result;

      // 인트로 화면 사용 > 배경이미지 > 모바일
      this.params.intro_mobile_bg_path =
        'https://survey.cafe24.shoplus.store/image/mobile_intro_image.png';
      let mobile_intro_image_files = await this.urlImageFile(
        this.params.intro_mobile_bg_path,
      );
      const mobile_intro_image_result = await this.uploadImage(
        mobile_intro_image_files,
      );
      this.params.intro_mobile_bg = mobile_intro_image_result;
      // 총 사용자 수 노출 > 3번째 선택
      this.params.intro_count_display_status = 'enabled_type2';
      // 총 사용자 수 노출 > 3번째 텍스트
      this.params.intro_type2_text1 = '명이 참여하였습니다.';

      // 총 사용자 수 노출 > 2번째 텍스트 빈값 세팅
      this.params.intro_type1_text1 = null;
      this.params.intro_type1_text2 = null;

      // 인트로 화면 사용 > 사용함
      this.params.intro_status = 'enabled';
      // 인트로 화면 사용 > 총 사용자 수 색상
      this.params.intro_count_color = '#f0bf4d';
      // 인트로 화면 사용 > 시작하기 버튼 색상
      this.params.intro_start_color = '#333333';
      // 인트로 화면 사용 > 시작하기 버튼명
      this.params.intro_start_text = '시작하기';
    },
    urlImageFile: async function (url) {
      const response = await fetch(url);
      const data = await response.blob();
      const ext = url.split('.').pop();
      const filename = url.split('/').pop();
      const metadata = { type: `image/${ext}` };
      return new File([data], filename, metadata);
    },
    setConfig: async function () {
      await this.getDefaultSetting();
      // 인트로 화면 사용 > 총 사용자 수 노출
      this.params.intro_count_display = {
        status: this.params.intro_count_display_status,
        text1: this.params.intro_type2_text1,
        text2: '',
      };
      this.params.intro_count_display = JSON.stringify(
        this.params.intro_count_display,
      );
      // PC 인트로 이미지
      let intro_desktop_bg_image = {
        image: this.params.intro_desktop_bg,
      };
      this.params.intro_desktop_bg = await this.$store.dispatch(
        'common/postImage',
        intro_desktop_bg_image,
      );
      this.params.intro_desktop_bg_path = this.params.intro_desktop_bg;

      // Mobile 인트로 이미지
      let intro_mobile_bg_image = {
        image: this.params.intro_mobile_bg,
      };
      this.params.intro_mobile_bg = await this.$store.dispatch(
        'common/postImage',
        intro_mobile_bg_image,
      );
      this.params.intro_mobile_bg_path = this.params.intro_mobile_bg;

      await this.$store.dispatch('qna_config/postData', this.params);
    },
    perPageChange: async function () {
      this.paging.per_page = this.per_pageing;
      this.paging.total_count = 0;
      await this.pageChange(0);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layer_dialog {
  display: none;
}
.new_thumb::before,
.new_thumb::after {
  background-color: #fff !important;
}

.search {
  display: inline;
  position: relative;
  color: #aaa;
  font-size: 16px;
}

.search input {
  width: 250px;
}

.search input {
  height: 26px;
}
.search .fa-search {
  position: absolute;
  left: 10px;
  right: 10px;
}

.search .fa-search {
  left: 220px;
  cursor: pointer;
  padding-top: 1px;
}

.custom_width150 {
  width: 150px !important;
}

.perPageing {
  width: 130px;
  height: 26px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  color: black;
  border-radius: 4px;
  background-color: #fff;
  border: 1px black solid;
  box-shadow: unset;
}

.purpleFont {
  color: #8041d9;
}

.loadingImage {
  width: 20px;
  margin-left: 10px;
}
/*.modals-container { position: fixed; top: 0; left: 0; width: 100%; height: 100vh; overflow-y: hidden; }*/
</style>
