<template>
  <div v-show="is_show">
    <!-- container -->
    <section id="container">
      <div class="content">
        <h2 class="title">맞춤 질문 설정</h2>
        <p class="dsc">
          쇼핑몰 고객들에게 제공할 맞춤 질문 설정 및 관리를 합니다.<br />
          수집한 데이터를 기반으로 해당 고객에게 적합한 상품 추천이
          가능합니다.<br />
          아래 1~4 스텝별 메뉴를 클릭하여 순서대로 설정해주세요.
        </p>

        <div class="step">
          <ul>
            <li>
              <em><a v-bind:href="`/qna/product`">1</a></em>
              <router-link to="/qna/product">추천 상품 설정</router-link>
            </li>
            <li class="on">
              <em>2</em>
              질문 설정
            </li>
            <li>
              <em><a v-bind:href="`/qna/config`">3</a></em>
              <router-link to="/qna/config">화면 관련 설정</router-link>
            </li>

            <li>
              <em><a v-bind:href="`/qna/result`">4</a></em>
              <router-link to="/qna/result">결과 화면 설정</router-link>
            </li>
          </ul>
        </div>

        <div class="conbox">
          <h3 class="tit">
            맞춤 질문 및 답변을 설정하시려면 아래의 버튼을 클릭해주세요.
          </h3>
          <p class="mb20">
            단계 - 질문 - 답변순으로 설정되며, 최대 40개 질문 생성이 가능합니다.
          </p>
        </div>

        <div class="btn_wrap">
          <button
            @click="showModal('step')"
            class="btn btn_large btn_primary btn_wide"
          >
            + 맞춤 질문 단계 입력
          </button>
        </div>

        <div class="conbox" v-if="this.params.length > 0">
          <div class="accordion">
            <ul>
              <!-- li -->
              <li
                class="step_setting"
                v-for="(folder, forder_index) in this.params"
                v-bind:key="forder_index"
              >
                <div class="title_area stage">
                  <button
                    v-if="folder.children.length > 0"
                    @click="
                      this.toggleDepth1[forder_index] =
                        !this.toggleDepth1[forder_index]
                    "
                    class="btn_acc"
                  >
                    <span class="material-icons notranslate icon">{{
                      this.toggleDepth1[forder_index] === true
                        ? 'arrow_drop_down'
                        : 'arrow_drop_up'
                    }}</span>
                    <span class="material-icons notranslate">folder</span>
                  </button>

                  <button v-else class="btn_acc">
                    <span class="material-icons notranslate ml34">folder</span>
                  </button>
                  <button class="tit_sub" @click="showModal('step', folder)">
                    {{ folder.subject }}
                  </button>
                  <div class="control">
                    <button
                      @click="
                        showModal('add', {
                          parent_id: folder.hierarchy_id,
                          folder_subject: folder.subject,
                        })
                      "
                      class="btn btn_small btn_primary"
                    >
                      <span>질문/답변 추가</span>
                    </button>
                    <button
                      @click="delSetting(folder)"
                      class="btn btn_small btn_light ml5"
                    >
                      <span>삭제</span>
                    </button>
                  </div>
                </div>
                <!-- depth1 -->
                <div
                  v-if="
                    folder.children.length > 0 &&
                    this.toggleDepth1[forder_index]
                  "
                  class="depth1 acc_cont"
                >
                  <ul>
                    <li
                      v-for="(depth1, depth1_index) in folder.children"
                      v-bind:key="depth1_index"
                    >
                      <div class="title_area">
                        <button
                          v-if="depth1.children.length > 0"
                          @click="
                            this.toggleDepth2[depth1_index] =
                              !this.toggleDepth2[depth1_index]
                          "
                          class="btn_acc"
                        >
                          <span class="material-icons notranslate icon">{{
                            this.toggleDepth2[depth1_index] === true
                              ? 'arrow_drop_down'
                              : 'arrow_drop_up'
                          }}</span>
                          <span class="material-icons notranslate">folder</span>
                        </button>
                        <button v-else class="btn_acc">
                          <span class="material-icons notranslate ml34"
                            >folder</span
                          >
                        </button>
                        <!-- 이미지 있는 경우 <span class="material-icons notranslate ">image</span> -->
                        <button
                          @click="
                            showModal('add', {
                              hierarchy_id: depth1.hierarchy_id,
                              parent_id: depth1.parent_id,
                              params: depth1,
                              folder_subject: folder.subject,
                            })
                          "
                          class="tit_sub text-left"
                        >
                          <span class="d-inline">
                            [{{ this.subjectType[depth1.subject_type] }}]</span
                          >
                          <span
                            class="inline-flex pl8"
                            v-html="textEnter(depth1.subject)"
                          ></span>
                        </button>
                        <div class="control">
                          <button
                            type="button"
                            class="ico up"
                            @click="
                              sequenceChange(
                                depth1_index,
                                'up',
                                folder.children,
                                2,
                              )
                            "
                          >
                            <span class="material-icons notranslate"
                              >keyboard_arrow_up</span
                            >
                          </button>
                          <button
                            type="button"
                            class="ico down ml5"
                            @click="
                              sequenceChange(
                                depth1_index,
                                'down',
                                folder.children,
                                2,
                              )
                            "
                          >
                            <span class="material-icons notranslate"
                              >keyboard_arrow_down</span
                            >
                          </button>
                          <button
                            @click="
                              this.toggleMenu1[depth1.hierarchy_id] =
                                !this.toggleMenu1[depth1.hierarchy_id]
                            "
                          >
                            <span class="material-icons notranslate ml10"
                              >more_vert</span
                            >
                          </button>
                          <transition name="fade">
                            <div
                              v-if="toggleMenu1[depth1.hierarchy_id]"
                              class="layer_qnaset"
                            >
                              <ul>
                                <li>
                                  <button
                                    @click="
                                      showModal('add', {
                                        parent_id: depth1.hierarchy_id,
                                        folder_subject: folder.subject,
                                      })
                                    "
                                    class="ml5"
                                  >
                                    해당 질문의 하위 질문 추가
                                  </button>
                                </li>
                                <li>
                                  <button
                                    @click="
                                      showModal('add', {
                                        hierarchy_id: depth1.hierarchy_id,
                                        parent_id: depth1.parent_id,
                                        params: depth1,
                                        folder_subject: folder.subject,
                                      })
                                    "
                                    class="ml5"
                                  >
                                    수정하기
                                  </button>
                                </li>
                                <li>
                                  <button
                                    @click="delSetting(depth1)"
                                    class="ml5"
                                  >
                                    삭제하기
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </transition>
                        </div>
                      </div>

                      <div class="q_cont notranslate">
                        <ul>
                          <li
                            v-for="(
                              depth1_answer, depth1_answer_index
                            ) in depth1.answer"
                            v-bind:key="depth1_answer_index"
                            class="notranslate"
                          >
                            <span class="material-icons notranslate">list</span
                            >[{{
                              this.subjectType[depth1_answer.subject_type]
                            }}]
                            <div
                              class="ml33"
                              v-html="textEnter(depth1_answer.subject)"
                            ></div>

                            <div class="fl_r mr20">
                              <button
                                v-if="this.qna_config.question_type !== 'none'"
                                @click="
                                  showModal('recommend', {
                                    question: depth1,
                                    answer: depth1_answer,
                                    subject_type: subjectType,
                                  })
                                "
                                class="btn btn_small btn_secondary"
                                @mouseover="
                                  previewTip(
                                    1,
                                    `preview_tip_${depth1_answer.hierarchy_id}`,
                                  )
                                "
                                @mouseleave="
                                  previewTip(
                                    0,
                                    `preview_tip_${depth1_answer.hierarchy_id}`,
                                  )
                                "
                              >
                                추천 상품 설정
                              </button>
                              <div
                                v-if="
                                  depth1_answer.product_list.length > 0 &&
                                  productListCheck(
                                    depth1_answer.product_list,
                                  ) === true
                                "
                                :id="`preview_tip_${depth1_answer.hierarchy_id}`"
                                :ref="`preview_tip_${depth1_answer.hierarchy_id}`"
                                class="productsPreview"
                              >
                                <p class="previewTitle">
                                  📄 설정되어 있는 추천 상품 (
                                  {{ depth1_answer.product_list.length }}개 )
                                </p>
                                <div
                                  v-for="product of depth1_answer.product_list"
                                  :key="product.product_id"
                                  class="previewProductList"
                                >
                                  <div
                                    v-if="
                                      this.qna_config.question_type == 'score'
                                    "
                                    class="previewProductList"
                                  >
                                    <span v-if="product.score > 0"
                                      >✔ {{ product.product_name }} ({{
                                        product.score
                                      }})
                                    </span>
                                  </div>
                                  <div v-else class="previewProductList">
                                    <span>✔ {{ product.product_name }}</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else
                                :id="`preview_tip_${depth1_answer.hierarchy_id}`"
                                :ref="`preview_tip_${depth1_answer.hierarchy_id}`"
                                class="productsPreview"
                              >
                                {{ preview_no_product_message }}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <!-- // q_cont -->

                      <!-- depth2 -->
                      <div
                        v-if="
                          depth1.children.length > 0 &&
                          this.toggleDepth2[depth1_index]
                        "
                        class="depth2 acc_cont"
                      >
                        <ul>
                          <li
                            v-for="(depth2, depth2_index) in depth1.children"
                            v-bind:key="depth2"
                          >
                            <div class="title_area">
                              <button
                                v-if="depth2.children.length > 0"
                                @click="
                                  this.toggleDepth3[depth2_index] =
                                    !this.toggleDepth3[depth2_index]
                                "
                                class="btn_acc"
                              >
                                <span class="material-icons notranslate icon">{{
                                  this.toggleDepth3[depth2_index] === true
                                    ? 'arrow_drop_down'
                                    : 'arrow_drop_up'
                                }}</span
                                ><span class="material-icons notranslate"
                                  >folder</span
                                >
                              </button>
                              <button v-else class="btn_acc">
                                <span class="material-icons notranslate ml34"
                                  >folder</span
                                >
                              </button>
                              <!-- 이미지 있는 경우 <span class="material-icons notranslate ">image</span> -->
                              <button
                                @click="
                                  showModal('add', {
                                    hierarchy_id: depth2.hierarchy_id,
                                    parent_id: depth2.parent_id,
                                    params: depth2,
                                    folder_subject: folder.subject,
                                  })
                                "
                                class="tit_sub text-left"
                              >
                                <span class="d-inline">
                                  [{{
                                    this.subjectType[depth2.subject_type]
                                  }}]</span
                                >
                                <span
                                  class="inline-flex pl8"
                                  v-html="textEnter(depth2.subject)"
                                ></span>
                                <!--
                                <span
                                  >[{{ this.subjectType[depth2.subject_type] }}]
                                  {{ depth2.subject }}</span
                                >
                          -->
                              </button>
                              <div class="control">
                                <button
                                  type="button"
                                  class="ico up"
                                  @click="
                                    sequenceChange(
                                      depth2_index,
                                      'up',
                                      depth1.children,
                                      3,
                                    )
                                  "
                                >
                                  <span class="material-icons notranslate"
                                    >keyboard_arrow_up</span
                                  >
                                </button>
                                <button
                                  type="button"
                                  class="ico down ml5"
                                  @click="
                                    sequenceChange(
                                      depth2_index,
                                      'down',
                                      depth1.children,
                                      3,
                                    )
                                  "
                                >
                                  <span class="material-icons notranslate"
                                    >keyboard_arrow_down</span
                                  >
                                </button>
                                <button
                                  @click="
                                    this.toggleMenu2[depth2.hierarchy_id] =
                                      !this.toggleMenu2[depth2.hierarchy_id]
                                  "
                                >
                                  <span class="material-icons notranslate ml10"
                                    >more_vert</span
                                  >
                                </button>
                                <transition name="fade">
                                  <div
                                    v-if="this.toggleMenu2[depth2.hierarchy_id]"
                                    class="layer_qnaset"
                                  >
                                    <ul>
                                      <li>
                                        <button
                                          @click="
                                            showModal('add', {
                                              parent_id: depth2.hierarchy_id,
                                              folder_subject: folder.subject,
                                            })
                                          "
                                          class="ml5"
                                        >
                                          해당 질문의 하위 질문 추가
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          @click="
                                            showModal('add', {
                                              hierarchy_id: depth2.hierarchy_id,
                                              parent_id: depth2.parent_id,
                                              params: depth2,
                                              folder_subject: folder.subject,
                                            })
                                          "
                                          class="ml5"
                                        >
                                          수정하기
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          @click="delSetting(depth2)"
                                          class="ml5"
                                        >
                                          삭제하기
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </transition>
                              </div>
                            </div>

                            <div class="q_cont notranslate">
                              <ul>
                                <li
                                  v-for="(
                                    depth2_answer, depth2_answer_index
                                  ) in depth2.answer"
                                  v-bind:key="depth2_answer_index"
                                  class="notranslate"
                                >
                                  <!--
                                  <span class="material-icons notranslate ">list</span>[{{
                                    this.subjectType[
                                      depth2_answer.subject_type
                                    ]
                                  }}]
                                  {{ depth2_answer.subject }}
                                -->

                                  <span class="material-icons notranslate"
                                    >list</span
                                  >[{{
                                    this.subjectType[
                                      depth2_answer.subject_type
                                    ]
                                  }}]
                                  <div
                                    class="ml33"
                                    v-html="textEnter(depth2_answer.subject)"
                                  ></div>

                                  <div class="fl_r mr20">
                                    <button
                                      v-if="
                                        this.qna_config.question_type !== 'none'
                                      "
                                      @click="
                                        showModal('recommend', {
                                          question: depth2,
                                          answer: depth2_answer,
                                          subject_type: subjectType,
                                        })
                                      "
                                      @mouseover="
                                        previewTip(
                                          1,
                                          `preview_tip_${depth2_answer.hierarchy_id}`,
                                        )
                                      "
                                      @mouseleave="
                                        previewTip(
                                          0,
                                          `preview_tip_${depth2_answer.hierarchy_id}`,
                                        )
                                      "
                                      class="btn btn_small btn_secondary"
                                    >
                                      추천 상품 설정
                                    </button>
                                    <div
                                      v-if="
                                        depth2_answer.product_list.length > 0 &&
                                        productListCheck(
                                          depth2_answer.product_list,
                                        ) === true
                                      "
                                      :id="`preview_tip_${depth2_answer.hierarchy_id}`"
                                      :ref="`preview_tip_${depth2_answer.hierarchy_id}`"
                                      class="productsPreview"
                                    >
                                      <p class="previewTitle">
                                        📄 설정되어 있는 추천 상품 (
                                        {{
                                          depth2_answer.product_list.length
                                        }}개 )
                                      </p>
                                      <div
                                        v-for="product of depth2_answer.product_list"
                                        :key="product.product_id"
                                        class="previewProductList"
                                      >
                                        <div
                                          v-if="
                                            this.qna_config.question_type ==
                                            'score'
                                          "
                                          class="previewProductList"
                                        >
                                          <span v-if="product.score > 0"
                                            >✔ {{ product.product_name }} ({{
                                              product.score
                                            }})
                                          </span>
                                        </div>
                                        <div v-else class="previewProductList">
                                          <span
                                            >✔ {{ product.product_name }}</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-else
                                      :id="`preview_tip_${depth2_answer.hierarchy_id}`"
                                      :ref="`preview_tip_${depth2_answer.hierarchy_id}`"
                                      class="productsPreview"
                                    >
                                      {{ preview_no_product_message }}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <!-- // q_cont -->
                            <!-- depth3 -->
                            <div
                              v-if="
                                depth2.children.length > 0 &&
                                this.toggleDepth3[depth2_index]
                              "
                              class="depth3 acc_cont"
                            >
                              <ul>
                                <li
                                  v-for="(
                                    depth3, depth3_index
                                  ) in depth2.children"
                                  v-bind:key="depth3"
                                >
                                  <div class="title_area">
                                    <button
                                      v-if="depth3.children.length > 0"
                                      @click="
                                        this.toggleDepth4[depth3_index] =
                                          !this.toggleDepth4[depth3_index]
                                      "
                                      class="btn_acc"
                                    >
                                      <span
                                        class="material-icons notranslate icon"
                                        >{{
                                          this.toggleDepth4[depth3_index] ===
                                          true
                                            ? 'arrow_drop_down'
                                            : 'arrow_drop_up'
                                        }}</span
                                      ><span class="material-icons notranslate"
                                        >folder</span
                                      >
                                    </button>
                                    <button v-else class="btn_acc">
                                      <span
                                        class="material-icons notranslate ml34"
                                        >folder</span
                                      >
                                    </button>
                                    <!-- 이미지 있는 경우 <span class="material-icons notranslate ">image</span> -->
                                    <button
                                      @click="
                                        showModal('add', {
                                          hierarchy_id: depth3.hierarchy_id,
                                          parent_id: depth3.parent_id,
                                          params: depth3,
                                          folder_subject: folder.subject,
                                        })
                                      "
                                      class="tit_sub text-left"
                                    >
                                      <!--
                                      <span
                                        >[{{
                                          this.subjectType[depth3.subject_type]
                                        }}] {{ depth3.subject }}</span
                                      >
                                    -->
                                      <span class="d-inline">
                                        [{{
                                          this.subjectType[depth3.subject_type]
                                        }}]</span
                                      >
                                      <span
                                        class="inline-flex pl8"
                                        v-html="textEnter(depth3.subject)"
                                      ></span>
                                    </button>
                                    <div class="control">
                                      <button
                                        type="button"
                                        class="ico up"
                                        @click="
                                          sequenceChange(
                                            depth3_index,
                                            'up',
                                            depth2.children,
                                            4,
                                          )
                                        "
                                      >
                                        <span class="material-icons notranslate"
                                          >keyboard_arrow_up</span
                                        >
                                      </button>
                                      <button
                                        type="button"
                                        class="ico down ml5"
                                        @click="
                                          sequenceChange(
                                            depth3_index,
                                            'down',
                                            depth2.children,
                                            4,
                                          )
                                        "
                                      >
                                        <span class="material-icons notranslate"
                                          >keyboard_arrow_down</span
                                        >
                                      </button>
                                      <button
                                        @click="
                                          this.toggleMenu3[
                                            depth3.hierarchy_id
                                          ] =
                                            !this.toggleMenu3[
                                              depth3.hierarchy_id
                                            ]
                                        "
                                      >
                                        <span
                                          class="
                                            material-icons
                                            notranslate
                                            ml10
                                          "
                                          >more_vert</span
                                        >
                                      </button>
                                      <transition name="fade">
                                        <div
                                          v-if="
                                            this.toggleMenu3[
                                              depth3.hierarchy_id
                                            ]
                                          "
                                          class="layer_qnaset"
                                        >
                                          <ul>
                                            <li>
                                              <button
                                                @click="
                                                  showModal('add', {
                                                    parent_id:
                                                      depth3.hierarchy_id,
                                                    folder_subject:
                                                      folder.subject,
                                                  })
                                                "
                                                class="ml5"
                                              >
                                                해당 질문의 하위 질문 추가
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                @click="
                                                  showModal('add', {
                                                    hierarchy_id:
                                                      depth3.hierarchy_id,
                                                    parent_id: depth3.parent_id,
                                                    params: depth3,
                                                    folder_subject:
                                                      folder.subject,
                                                  })
                                                "
                                                class="ml5"
                                              >
                                                수정하기
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                @click="delSetting(depth3)"
                                                class="ml5"
                                              >
                                                삭제하기
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </transition>
                                    </div>
                                  </div>

                                  <div class="q_cont notranslate">
                                    <ul>
                                      <li
                                        v-for="(
                                          depth3_answer, depth3_answer_index
                                        ) in depth3.answer"
                                        v-bind:key="depth3_answer_index"
                                        class="notranslate"
                                      >
                                        <!--
                                        <span class="material-icons notranslate ">list</span
                                        >[{{
                                          this.subjectType[
                                            depth3_answer.subject_type
                                          ]
                                        }}]
                                        {{ depth3_answer.subject }}
                                        -->
                                        <span class="material-icons notranslate"
                                          >list</span
                                        >[{{
                                          this.subjectType[
                                            depth3_answer.subject_type
                                          ]
                                        }}]
                                        <div
                                          class="ml33"
                                          v-html="
                                            textEnter(depth3_answer.subject)
                                          "
                                        ></div>
                                        <div class="fl_r mr20">
                                          <button
                                            v-if="
                                              this.qna_config.question_type !==
                                              'none'
                                            "
                                            @click="
                                              showModal('recommend', {
                                                question: depth3,
                                                answer: depth3_answer,
                                                subject_type: subjectType,
                                              })
                                            "
                                            @mouseover="
                                              previewTip(
                                                1,
                                                `preview_tip_${depth3_answer.hierarchy_id}`,
                                              )
                                            "
                                            @mouseleave="
                                              previewTip(
                                                0,
                                                `preview_tip_${depth3_answer.hierarchy_id}`,
                                              )
                                            "
                                            class="btn btn_small btn_secondary"
                                          >
                                            추천 상품 설정
                                          </button>
                                          <div
                                            v-if="
                                              depth3_answer.product_list
                                                .length > 0 &&
                                              productListCheck(
                                                depth3_answer.product_list,
                                              ) === true
                                            "
                                            :id="`preview_tip_${depth3_answer.hierarchy_id}`"
                                            :ref="`preview_tip_${depth3_answer.hierarchy_id}`"
                                            class="productsPreview"
                                          >
                                            <p class="previewTitle">
                                              📄 설정되어 있는 추천 상품 (
                                              {{
                                                depth3_answer.product_list
                                                  .length
                                              }}개 )
                                            </p>
                                            <div
                                              v-for="product of depth3_answer.product_list"
                                              :key="product.product_id"
                                              class="previewProductList"
                                            >
                                              <div
                                                v-if="
                                                  this.qna_config
                                                    .question_type == 'score'
                                                "
                                                class="previewProductList"
                                              >
                                                <span v-if="product.score > 0"
                                                  >✔
                                                  {{ product.product_name }} ({{
                                                    product.score
                                                  }})
                                                </span>
                                              </div>
                                              <div
                                                v-else
                                                class="previewProductList"
                                              >
                                                <span
                                                  >✔
                                                  {{
                                                    product.product_name
                                                  }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            v-else
                                            :id="`preview_tip_${depth3_answer.hierarchy_id}`"
                                            :ref="`preview_tip_${depth3_answer.hierarchy_id}`"
                                            class="productsPreview"
                                          >
                                            {{ preview_no_product_message }}
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <!-- // q_cont -->
                                  <!-- depth4 -->
                                  <div
                                    v-if="
                                      depth3.children.length > 0 &&
                                      this.toggleDepth4[depth3_index]
                                    "
                                    class="depth4 acc_cont"
                                  >
                                    <ul>
                                      <li
                                        v-for="(
                                          depth4, depth4_index
                                        ) in depth3.children"
                                        v-bind:key="depth4"
                                      >
                                        <div class="title_area">
                                          <!-- 이미지 있는 경우 <span class="material-icons notranslate ">image</span> -->
                                          <button
                                            @click="
                                              showModal('add', {
                                                hierarchy_id:
                                                  depth4.hierarchy_id,
                                                parent_id: depth4.parent_id,
                                                params: depth4,
                                                folder_subject: folder.subject,
                                              })
                                            "
                                            class="tit_sub text-left"
                                          >
                                            <!--
                                            <span
                                              >[{{
                                                this.subjectType[
                                                  depth4.subject_type
                                                ]
                                              }}] {{ depth4.subject }}</span
                                            >
                                          -->

                                            <span class="d-inline">
                                              [{{
                                                this.subjectType[
                                                  depth4.subject_type
                                                ]
                                              }}]</span
                                            >
                                            <span
                                              class="inline-flex pl8"
                                              v-html="textEnter(depth4.subject)"
                                            ></span>
                                          </button>
                                          <div class="control">
                                            <button
                                              type="button"
                                              class="ico up"
                                              @click="
                                                sequenceChange(
                                                  depth4_index,
                                                  'up',
                                                  depth3.children,
                                                )
                                              "
                                            >
                                              <span
                                                class="
                                                  material-icons
                                                  notranslate
                                                "
                                                >keyboard_arrow_up</span
                                              >
                                            </button>
                                            <button
                                              type="button"
                                              class="ico down ml5"
                                              @click="
                                                sequenceChange(
                                                  depth4_index,
                                                  'down',
                                                  depth3.children,
                                                )
                                              "
                                            >
                                              <span
                                                class="
                                                  material-icons
                                                  notranslate
                                                "
                                                >keyboard_arrow_down</span
                                              >
                                            </button>
                                            <button
                                              @click="
                                                this.toggleMenu4[
                                                  depth4.hierarchy_id
                                                ] =
                                                  !this.toggleMenu4[
                                                    depth4.hierarchy_id
                                                  ]
                                              "
                                            >
                                              <span
                                                class="
                                                  material-icons
                                                  notranslate
                                                  ml10
                                                "
                                                >more_vert</span
                                              >
                                            </button>
                                            <transition name="fade">
                                              <div
                                                v-if="
                                                  this.toggleMenu4[
                                                    depth4.hierarchy_id
                                                  ]
                                                "
                                                class="layer_qnaset"
                                              >
                                                <ul>
                                                  <li>
                                                    <button
                                                      @click="
                                                        showModal('add', {
                                                          hierarchy_id:
                                                            depth4.hierarchy_id,
                                                          parent_id:
                                                            depth4.parent_id,
                                                          params: depth4,
                                                          folder_subject:
                                                            folder.subject,
                                                        })
                                                      "
                                                      class="ml5"
                                                    >
                                                      수정하기
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      @click="
                                                        delSetting(depth4)
                                                      "
                                                      class="ml5"
                                                    >
                                                      삭제하기
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </transition>
                                          </div>
                                        </div>

                                        <div class="q_cont notranslate">
                                          <ul>
                                            <li
                                              class="notranslate"
                                              v-for="(
                                                depth4_answer,
                                                depth4_answer_index
                                              ) in depth4.answer"
                                              v-bind:key="depth4_answer_index"
                                            >
                                              <!--
                                              <span class="material-icons notranslate "
                                                >list</span
                                              >[{{
                                                this.subjectType[
                                                  depth4_answer.subject_type
                                                ]
                                              }}]
                                              {{ depth4_answer.subject }}
                                              -->
                                              <span
                                                class="
                                                  material-icons
                                                  notranslate
                                                "
                                                >list</span
                                              >[{{
                                                this.subjectType[
                                                  depth4_answer.subject_type
                                                ]
                                              }}]
                                              <div
                                                class="ml33"
                                                v-html="
                                                  textEnter(
                                                    depth4_answer.subject,
                                                  )
                                                "
                                              ></div>
                                              <div class="fl_r mr20">
                                                <button
                                                  v-if="
                                                    this.qna_config
                                                      .question_type !== 'none'
                                                  "
                                                  @click="
                                                    showModal('recommend', {
                                                      question: depth4,
                                                      answer: depth4_answer,
                                                      subject_type: subjectType,
                                                    })
                                                  "
                                                  @mouseover="
                                                    previewTip(
                                                      1,
                                                      `preview_tip_${depth4_answer.hierarchy_id}`,
                                                    )
                                                  "
                                                  @mouseleave="
                                                    previewTip(
                                                      0,
                                                      `preview_tip_${depth4_answer.hierarchy_id}`,
                                                    )
                                                  "
                                                  class="
                                                    btn btn_small btn_secondary
                                                  "
                                                >
                                                  추천 상품 설정
                                                </button>
                                                <div
                                                  v-if="
                                                    depth4_answer.product_list
                                                      .length > 0 &&
                                                    productListCheck(
                                                      depth4_answer.product_list,
                                                    ) === true
                                                  "
                                                  :id="`preview_tip_${depth4_answer.hierarchy_id}`"
                                                  :ref="`preview_tip_${depth4_answer.hierarchy_id}`"
                                                  class="productsPreview"
                                                >
                                                  <p class="previewTitle">
                                                    📄 설정되어 있는 추천 상품 (
                                                    {{
                                                      depth4_answer.product_list
                                                        .length
                                                    }}개 )
                                                  </p>
                                                  <div
                                                    v-for="product of depth4_answer.product_list"
                                                    :key="product.product_id"
                                                    class="previewProductList"
                                                  >
                                                    <div
                                                      v-if="
                                                        this.qna_config
                                                          .question_type ==
                                                        'score'
                                                      "
                                                      class="previewProductList"
                                                    >
                                                      <span
                                                        v-if="product.score > 0"
                                                        >✔
                                                        {{
                                                          product.product_name
                                                        }}
                                                        ({{ product.score }})
                                                      </span>
                                                    </div>
                                                    <div
                                                      v-else
                                                      class="previewProductList"
                                                    >
                                                      <span
                                                        >✔
                                                        {{
                                                          product.product_name
                                                        }}</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  v-else
                                                  :id="`preview_tip_${depth4_answer.hierarchy_id}`"
                                                  :ref="`preview_tip_${depth4_answer.hierarchy_id}`"
                                                  class="productsPreview"
                                                >
                                                  {{
                                                    preview_no_product_message
                                                  }}
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                        <!-- // q_cont -->
                                      </li>
                                    </ul>
                                  </div>
                                  <!-- //depth4 -->
                                </li>
                              </ul>
                            </div>
                            <!-- //depth3 -->
                          </li>
                        </ul>
                      </div>
                      <!-- //depth2 -->
                    </li>
                  </ul>
                </div>
                <!-- //depth1 -->
              </li>
              <!-- //li -->
            </ul>
          </div>
        </div>
      </div>
      <!-- //content -->
    </section>
    <!-- //container -->

    <ModalsContainer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ModalsContainer } from 'vue-final-modal';

import common from '@/components/mixin/index.js';
import ModalQnaAdd from '@/components/qna/modal/ModalQnaAdd.vue';
import ModalQnaProductGoods from '@/components/qna/modal/ModalQnaProduct.vue';
import ModalQnaStep from '@/components/qna/modal/ModalQnaStep.vue';

export default {
  mixins: [common],
  data: () => ({
    toggleMenu1: [],
    toggleMenu2: [],
    toggleMenu3: [],
    toggleMenu4: [],
    toggleDepth1: [],
    toggleDepth2: [],
    toggleDepth3: [],
    toggleDepth4: [],
    toggleDepth5: [],
    params: Array,
    subjectType: {},
    is_show: false,
    setting_count: 0,
    max_setting_count: 100,
    preview_no_product_message: '설정된 상품이 없습니다.',
    previewAnswerList: [],
  }),
  components: {
    ModalsContainer,
    // eslint-disable-next-line vue/no-unused-components
    ModalQnaAdd,
    // eslint-disable-next-line vue/no-unused-components
    ModalQnaProductGoods,
    // eslint-disable-next-line vue/no-unused-components
    ModalQnaStep,
  },
  created: async function () {
    await this.getConfigInfo();
    await this.getSettingList();

    this.initToggleMenu();

    this.subjectType = {
      free: '자유',
      name: '이름',
      age: '나이',
      sex: '성별',
      check: '체크박스',
      radio: '라디오버튼',
      dropdown: '드롭다운',
      text: '텍스트',
      button1_1: '버튼',
      button1_2: '버튼',
      button1_3: '버튼',
      file: '파일',
    };

    this.is_show = true;
  },

  computed: {
    ...mapState('qna_config', ['qna_config']),
    ...mapState('qna_setting', ['setting_list']),
  },
  methods: {
    getSettingCount: async function (params) {
      if (params.children && params.children.length > 0) {
        this.setting_count += params.children.length;
        for (let i = 0; i < params.children.length; i++) {
          await this.getSettingCount(params.children[i]);
        }
      }
    },
    getConfigInfo: async function () {
      await this.$store.dispatch('qna_config/getConfig');
    },
    getSettingList: async function () {
      await this.$store.dispatch('qna_setting/getSettingList');
      if (this.setting_list == null || this.setting_list == undefined) {
        return;
      }
      this.params = this.setting_list;

      this.setting_count = 0;
      for (let i = 0; i < this.params.length; i++) {
        await this.getSettingCount(this.params[i]);
      }
    },
    delSetting: async function (params) {
      let answer_next_hierarchy_subject_array = [];
      for (const depth1 of this.params) {
        for (const depth2 of depth1.children) {
          for (const depth2_answer of depth2.answer) {
            if (depth2_answer.next_hierarchy_id == params.hierarchy_id) {
              answer_next_hierarchy_subject_array.push(depth2.subject);
            }
          }
          for (const depth3 of depth2.children) {
            for (const depth3_answer of depth3.answer) {
              if (depth3_answer.next_hierarchy_id == params.hierarchy_id) {
                answer_next_hierarchy_subject_array.push(depth3.subject);
              }
            }

            for (const depth4 of depth3.children) {
              for (const depth4_answer of depth4.answer) {
                if (depth4_answer.next_hierarchy_id == params.hierarchy_id) {
                  answer_next_hierarchy_subject_array.push(depth4.subject);
                }
              }

              for (const depth5 of depth4.children) {
                for (const depth5_answer of depth5.answer) {
                  if (depth5_answer.next_hierarchy_id == params.hierarchy_id) {
                    answer_next_hierarchy_subject_array.push(depth5.subject);
                  }
                }
              }
            }
          }
        }
      }

      let unique_answer_subject_array = [];
      answer_next_hierarchy_subject_array.forEach(element => {
        if (!unique_answer_subject_array.includes(element)) {
          unique_answer_subject_array.push(element);
        }
      });

      if (unique_answer_subject_array.length > 0) {
        let alert_msg =
          '[ ' +
          unique_answer_subject_array.join(', ') +
          ' ] 질문의 답변에 설정되어 있습니다.\n해당 질문을 수정 후 삭제해주세요.';
        alert(alert_msg);
        return;
      }

      if (params.hierarchy_id === undefined || params.hierarchy_id === null) {
        alert('오류가 발생하였습니다.');
        return false;
      } else if (
        !confirm(
          '정말 삭제하시겠습니까?\r\n삭제 하시면 설정하신 질문과 답변이\r\n모두 삭제 됩니다.',
        )
      ) {
        alert('취소하였습니다.');
        return false;
      }

      const result = await this.$store.dispatch('qna_setting/deleteData', {
        hierarchy_id: params.hierarchy_id,
        parameters: params,
      });

      if (result === true) {
        alert('삭제되었습니다.');
        this.getSettingList();
      } else {
        alert('삭제를 실패하였습니다.');
      }
    },
    hideAllToggle() {
      this.initToggleMenu();
    },
    showModal(id = String | null, obj = {}) {
      this.hideAllToggle();

      if (id === 'step') {
        this.$vfm.show({
          component: ModalQnaStep,
          bind: {
            params: obj,
          },
          on: {
            closed: _this => {
              this.getSettingList();
            },
          },
        });
      } else if (id === 'add') {
        if (this.setting_count >= this.max_setting_count && !obj.params) {
          alert('질문은 최대 ' + this.max_setting_count + '개까지 등록됩니다.');
          return;
        }
        this.$vfm.show({
          component: ModalQnaAdd,
          bind: obj,
          on: {
            closed: () => {
              this.getSettingList();
            },
          },
        });
      } else if (id === 'recommend') {
        this.$vfm.show({
          component: ModalQnaProductGoods,
          bind: {
            params: obj,
            survey_config: this.qna_config,
          },
          on: {
            closed: () => {
              this.getSettingList();
            },
          },
        });
      }
    },
    initToggleMenu() {
      this.toggleMenu1 = Array.from(Array(40).fill(false));
      this.toggleMenu2 = Array.from(Array(40).fill(false));
      this.toggleMenu3 = Array.from(Array(40).fill(false));
      this.toggleMenu4 = Array.from(Array(40).fill(false));

      this.toggleDepth1 = Array.from(Array(40).fill(true));
      this.toggleDepth2 = Array.from(Array(40).fill(true));
      this.toggleDepth3 = Array.from(Array(40).fill(true));
      this.toggleDepth4 = Array.from(Array(40).fill(true));
      this.toggleDepth5 = Array.from(Array(40).fill(true));
    },
    sequenceChange: async function (i, type, obj, depth = 0) {
      let toggleTarget = '';

      switch (depth) {
        case 2:
          toggleTarget = this.toggleDepth2;
          break;
        case 3:
          toggleTarget = this.toggleDepth3;
          break;
        case 4:
          toggleTarget = this.toggleDepth4;
          break;
        case 5:
          toggleTarget = this.toggleDepth5;
          break;
        default:
          break;
      }

      if (type === 'up') {
        if (i == 0) {
          return false;
        }

        const copy = obj.splice(i, 1);
        obj.splice(i - 1, 0, copy[0]);

        obj[i].sequence = obj[i].sequence + 1;
        obj[i - 1].sequence = obj[i - 1].sequence - 1;

        const copy_depth = toggleTarget.splice(i, 1);
        toggleTarget.splice(i - 1, 0, copy_depth[0]);
      } else if (type === 'down') {
        if (i == obj.length - 1) {
          return false;
        }

        const copy = obj.splice(i, 1);
        obj.splice(i + 1, 0, copy[0]);

        obj[i].sequence = obj[i].sequence - 1;
        obj[i + 1].sequence = obj[i + 1].sequence + 1;

        const copy_depth = toggleTarget.splice(i, 1);
        toggleTarget.splice(i + 1, 0, copy_depth[0]);
      }

      let params = {
        parent_hierarchy_id: 0,
        hierarchy: Array(),
      };

      for (const [key, item] of Object.entries(obj)) {
        if (params.parent_hierarchy_id === 0) {
          params.parent_hierarchy_id = item.parent_id;
        }
        params.hierarchy.push({
          hierarchy_id: item.hierarchy_id,
        });
      }

      const result = await this.$store.dispatch(
        'qna_setting/postChangeSequence',
        {
          parent_hierarchy_id: params.parent_hierarchy_id,
          hierarchy: params,
        },
      );
    },
    refresh: function () {
      const rand = Math.random();
    },
    subjectNewline: function (type, title) {
      return '[' + type + '] ' + title.replace(/(?:\r\n|\r|\n)/g, '<br />');
    },
    textEnter: function (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    },

    // eslint-disable-next-line vue/no-dupe-keys
    subjectType: function (type, title) {},
    productListCheck: function (product_list) {
      let _product_list = [];
      for (const product of product_list) {
        if (product.score > 0) {
          _product_list.push(product);
        }
      }

      if (_product_list.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    previewTip: function (type, preview_tip) {
      if (type === 1) {
        if (this.$refs[preview_tip].style) {
          this.$refs[preview_tip].style.display = 'block';
          const preview_bottom = this.rectBottom(preview_tip);
          const container_bottom = this.rectBottom('container');
          if (preview_bottom > container_bottom) {
            this.$refs[preview_tip].style.top = 'unset';
            this.$refs[preview_tip].style.bottom = '0px';
          } else {
            this.$refs[preview_tip].style.bottom = 'unset';
            this.$refs[preview_tip].style.top = '0px';
          }
        }
      } else {
        if (this.$refs[preview_tip].style) {
          this.$refs[preview_tip].style.display = 'none';
          const preview_bottom = this.rectBottom(preview_tip);
          const container_bottom = this.rectBottom('container');
          if (preview_bottom > container_bottom) {
            this.$refs[preview_tip].style.top = 'unset';
            this.$refs[preview_tip].style.bottom = '0px';
          } else {
            this.$refs[preview_tip].style.bottom = 'unset';
            this.$refs[preview_tip].style.top = '0px';
          }
        }
      }
    },
    rectBottom: function (id) {
      const target = document.getElementById(id);
      const clientRect = target.getBoundingClientRect();

      const relativeBottom = clientRect.bottom;
      const scrolledTopLength = window.pageYOffset;
      const absoluteBottom = scrolledTopLength + relativeBottom;
      return absoluteBottom;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layer_qnaset {
  display: block;
}

.text-left {
  text-align: left;
}

.ml33 {
  margin-left: 33px;
}

.title_area {
  padding: 20px 130px 20px 50px;
  display: flex;
}

.accordion .btn_acc {
  display: contents;
}

.d-inline {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}
.pl8 {
  padding-left: 8px;
}

.stage {
  align-items: center;
}

.ml34 {
  margin-left: 34px;
}

.productsPreview {
  width: 400px;
  display: none;
  position: absolute;
  padding: 14px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  text-align: start;
  right: 120px;
  top: 0px;
  z-index: 9999;
}

.productsPreviewUp {
  width: 400px;
  display: none;
  position: absolute;
  padding: 14px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  text-align: start;
  right: 120px;
  bottom: 0px;
  z-index: 9999;
}

.previewTitle {
  font-size: 16px;
  margin-bottom: 10px;
}

.previewProductList {
  color: lightblue;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  margin-bottom: 20px;
}
</style>
