<template>
  <StatsRecommend />
</template>

<script>
// @ is an alias to /src
import StatsRecommend from '@/components/stats/StatsRecommend.vue';

export default {
  components: {
    StatsRecommend,
  },
};
</script>
